import React, { useEffect, useState } from "react";
import Filter from "./filter";
import ArrowIcon from "assets/images/crm/Vector.png";
import CsRequestAddModel from "../Models/csRequestAdd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
	CSRequestsList,
	addRequest,
	getProductTypeLookups,
	getServicesLookups,
} from "services/CRM";
import Pagination from "components/Pagination";
import NoDataIcon from "assets/images/crm/no-data.png";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { store } from "react-notifications-component";
import Locale from 'translations';

export default function CSRequests() {
	const history = useHistory();
	const { CRM } = Locale;

	const [data, setData] = useState([]);
	const [filter, setFilter] = useState({
		q: "",
		page: 1,
	});
	const [meta, setMeta] = useState();
	const [lookups, setLookups] = useState({
		services: [],
		product_type: [],
		months: [
			{ name: "January", id: 1 },
			{ name: "February", id: 2 },
			{ name: "March", id: 3 },
			{ name: "April", id: 4 },
			{ name: "May", id: 5 },
			{ name: "June", id: 6 },
			{ name: "July", id: 7 },
			{ name: "August", id: 8 },
			{ name: "September", id: 9 },
			{ name: "October", id: 10 },
			{ name: "November", id: 11 },
			{ name: "December", id: 12 },
		],
	});
	const saudiFlagUUID = "d68c87b9-9a70-4032-8226-aaab6687442a";
	const [requestState, setRequestState] = useState({
		client_name: "",
		client_email: "",
		phones1: {
			flag: saudiFlagUUID,
			label: "+966",
			value: "+966",
			num: "",
		},
		phones2: {
			flag: saudiFlagUUID,
			label: "+966",
			value: "+966",
			num: "",
		},
		whats_phone: {
			flag: saudiFlagUUID,
			label: "+966",
			value: "+966",
			num: "",
		},
		type: "",
		destination: "",
		class: "",
		service: [],
		month: "",
		duration_from: undefined,
		duration_to: undefined,
		nights: "",
		pax: "",
		assigned_to: { name: "Sales Team", id: "Sales_Team" },
		cname: "",
		notes: "",
		travel_type: "travel_date",
	});
	const [reload, setReload] = useState(false);

	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => {
		setIsOpen((prev) => !prev);
	};

	const statusStyle = (status) => {
		switch (status) {
			case 1:
				return "new";
			case 2:
				return "accepted";
			case "pending":
				return "pending";
			case 3:
				return "rejected";

			default:
				return status;
		}
	};
	useEffect(() => {
		async function fetchLookups() {
			const [services, types] = await Promise.all([
				getServicesLookups(),
				getProductTypeLookups(),
			]);
			setLookups({
				...lookups,
				services: services?.data?.data,
				product_type: types?.data?.data,
			});
		}

		fetchLookups();
	}, []);
	useEffect(() => {
		async function fetchTransaction() {
			const res = await CSRequestsList({
				...filter,
			});
			setData(res?.data?.data);
			setMeta(res?.data?.meta);
		}
		fetchTransaction();
	}, [filter, reload]);
	const AllRequests =
		data.length > 0 ? (
			data.map((item, index) => {
				return (
					<tr key={index}>
						<td>{item.client_name}</td>
						<td>{item.client_email}</td>
						<td>{`+${item.phones?.[0]?.code}${item.phones?.[0]?.num}`}</td>
						<td>{item.type}</td>
						<td>{item.destination_name}</td>
						<td>{item.nights}</td>
						<td>{item.cname}</td>
						<td>{"Sales Team"}</td>
						<td>
							<div className={statusStyle(item.status)}>
								{CRM[item.status_label]}
							</div>
						</td>
						<td>
							<span
								className="view"
								role="button"
								onClick={() => history.push(`/crm/cs-requests/${item?.id}`)}
							>
								<img src={ArrowIcon} alt="view request" />
							</span>
						</td>
					</tr>
				);
			})
		) : (
			<tr>
				<td colSpan="10">
					<div className="product-build__product-no-data fullHeight no-result">
						<img src={NoDataIcon} alt=" No Results" />
						<h4>{"No Results Yet!"}</h4>
						<p>{CRM.fillSearch}</p>
					</div>
				</td>
			</tr>
		);
	const goTo = (page) => {
		setFilter({ ...filter, page: page });
	};
	/*********** Start Add Request ************ */
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [errors, setErrors] = useState();
	const checkFormErrors = () => {
		let submitError = {};
		Object.keys(requestState).forEach((key) => {
			submitError = {
				...submitError,
				...validate(
					{ name: key, value:key!=="phones1" ? requestState[key]:requestState[key]?.num },
					{
						required:
							key === "notes" ||
							key === "whats_phone" ||
							key === "phones2" ||
							key === "travel_type" ||
							key ===
								(requestState.travel_type === "travel_date"
									? "month"
									: "duration_from") ||
							key ===
								(requestState.travel_type === "travel_date"
									? "month"
									: "duration_to")
								? false
								: true,
						email: key === "client_email",
					}
				),
			};
		});
		setErrors(() => submitError);
		
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	const AddEditRequest = async () => {
		let obj = {};

		if (requestState.travel_type === "travel_date") {
			obj = {
				travel_date_from: moment(requestState.duration_from).format("YYYY-MM-DD"),
				travel_date_to: moment(requestState.duration_to).format("YYYY-MM-DD"),
				duration:"0"

			};
		} else {
			obj = { duration: requestState?.month?.value?.toString() };
		}
		let data = {
			client_name: requestState.client_name,
			client_email: requestState.client_email,
			phones: [
				{
					code: requestState.phones1?.value.slice(1),
					num: requestState.phones1?.num,
				},
				requestState.phones2?.num
					? {
							code: requestState.phones2?.value.slice(1),
							num: requestState.phones2?.num,
					  }
					: {
						code: null,
						num: null,
					},
				requestState.whats_phone?.num
					? {
							code: requestState.whats_phone?.value.slice(1),
							num: requestState.whats_phone?.num,
					  }
					: {
						code: null,
						num: null,
					},
			],
			type: requestState.type?.name,
			destination: requestState.destination?.id.toString(),
			class: requestState.class?.name.toString(),
			service: requestState.service?.map((item) => item.id),
			nights: requestState.nights,
			pax: requestState.pax,
			assigned_to: [null],
			notes: requestState.notes,
			cname: requestState.cname,

			//duration:"1",
			...obj,
		};

		const res = await addRequest(data);
		if (res?.status >= 200 && res?.status < 300) {
			store.addNotification({
				title: "info!",
				message: res?.data?.message ?? "Added Request successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			toggle();
			setReload((prev) => !prev);
		}
	};
	useEffect(() => {
		if (isFormValid(errors)) {
			AddEditRequest();
		}
	}, [isErrorLoaded]);

	/*********** End Add Request ************* */

	return (
		<div className="cs-requests">
			<div className="title">
				<h4>{CRM.csRequests}</h4>
				<hr />
			</div>
			<Filter filter={filter} setFilter={setFilter} toggle={toggle} />
			<div className="table-cs-request">
				<table className="p-2 bg-white table ">
					<thead>
						<tr>
							<th>{CRM.OwnerName}</th>
							<th>{CRM.Email}</th>
							<th>{CRM.phonenumber}</th>
							<th>{CRM.type}</th>
							<th>{CRM.destination}</th>
							<th>{CRM.night}</th>
							<th>{CRM.csname}</th>
							<th>{CRM.Assighnedto}</th>
							<th>{CRM.Status}</th>
							<th width="5%">{CRM.Action}</th>
						</tr>
					</thead>
					<tbody> {AllRequests}</tbody>
				</table>
			</div>
			{data?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}

			<CsRequestAddModel
				isOpen={isOpen}
				toggle={toggle}
				data={requestState}
				setData={setRequestState}
				send={checkFormErrors}
				setErrors={setErrors}
				errors={errors}
				lookups={lookups}
			/>
		</div>
	);
}
