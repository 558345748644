import React, { useEffect, useState } from "react";
import dateExpireImage from "assets/images/icons/visa-calender-icon.svg";
import walletImage from "assets/images/newWallat.svg";
import hourGlassSrc from "assets/images/icons/hour-glass.png";
import visaPaxSrc from "assets/images/icons/visa-pax-icon.svg";
import visaTypeSrc from "assets/images/icons/visa-type.png";
import visaPaymentSrc from "assets/images/icons/visa-payment-method.png";
import visaApplySrc from "assets/images/market-place/visa-apply.png";
import visaPaySrc from "assets/images/market-place/visa-pay.png";
import visaUploadSrc from "assets/images/market-place/visa-upload-docs.png";
import visaGetSrc from "assets/images/market-place/visa-get.png";
import visaArrowSrc from "assets/images/market-place/visa-arrow.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import SaudiArabiaImg from "assets/images/SaudiArabia.jpg";
import UAEImg from "assets/images/UAE.jpg";
import Locale from "translations";
import NumberField from "components/Form/NumberField/NumberField";
import {
	useMarketplaceDispatch,
	useMarketplaceState,
} from "context/marketplace";
import moment from "moment";
import validate, { isFormValid } from "helpers/validate";
import { useHistory } from "react-router-dom";
import NewPaymentAction from "components/NewPaymentAction";
import useShowPermission from "hooks/useShowPermission";
import { createOnlineVisaRequest } from "services/VisaOnline";
import { store } from "react-notifications-component";

export default function VisaSearchDetails() {
	const {
		onlineVisa,
		marketPlace,
		inventory,
		// payment,
		commons,
		// productsBuilder,
	} = Locale;
	const { onlineVisaSearch } = useMarketplaceState();
	const dispatch = useMarketplaceDispatch();
	const history = useHistory();
	const inventoryPermission = useShowPermission({
		permission: ["View-Inventory-Visa", "Manage-Inventory-Visa"],
	});
	const OTP_CHECK = process.env.REACT_APP_OTP_CHECK
		? JSON.parse(process.env.REACT_APP_OTP_CHECK)
		: false;

	const [policyModalOpen, setPolicyModalOpen] = useState(false);
	const [agreementModal, setAgreementModal] = useState(false);
	const [errors, setErrors] = useState({});
	const [isReadyForPay, setIsReadyForPay] = useState(false);
	const [ModalPayment, setModalPayment] = useState(false);
	const [actionType, setActionType] = useState("");
	const [formData, setFormData] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [someNationalities, setSomeNationalities] = useState(false);

	function toggleSomeNationalities() {
		setSomeNationalities((prev) => !prev);
	}
	function togglePolicyModal() {
		setPolicyModalOpen(!policyModalOpen);
	}
	function toggleAgreementModal() {
		setAgreementModal(!agreementModal);
	}
	function toggleModelPayment() {
		setActionType("");
		setFormData({});
		setModalPayment(!ModalPayment);
	}

	const [visaData, setVisaData] = useState({
		destination: onlineVisaSearch?.destination,
		visa_type: onlineVisaSearch?.visa_type,
		residence: onlineVisaSearch?.residence,
		pax: "",
		expiration_days: moment(new Date()).add(
			onlineVisaSearch?.residence?.expiration_days,
			"d"
		),
		price_per_one: +onlineVisaSearch?.package_plan?.package_price,
		package_plan: onlineVisaSearch?.package_plan,
		visaNotRefundableCheck: false,
		visaAgreement: false,
	});

	const package_plan = visaData?.package_plan;

	function checkFormErrors() {
		let SubmitError = {};
		Object.keys(visaData).forEach((key) => {
			if (key === "visaAgreement" && !package_plan?.entry_stamp) return;
			SubmitError = {
				...SubmitError,
				...validate(
					{ name: key, value: visaData[key] },
					{
						required:
							key === "visaNotRefundableCheck" || key === "visaAgreement"
								? isReadyForPay
								: true,
						minNumber: key === "pax" && package_plan?.range_from,
						maxNumber: key === "pax" && package_plan?.range_to,
					}
				),
			};
		});
		setErrors(() => SubmitError);
	}

	const sumbit = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
		if (!isReadyForPay) {
			setTimeout(() => {
				
				window.scrollTo({
					top: document.querySelector("body").scrollHeight,
					behavior: "smooth",
				});
			}, 200);
		}
	};
	useEffect(() => {
		
		if (isFormValid(errors)) {
			function handleSearch() {
				if (!isReadyForPay) {
					setVisaData({
						...visaData,
						expireDate: moment(new Date()).add(
							visaData?.residence?.expiration_days,
							"d"
						),
						totalPrice: visaData?.pax * visaData?.price_per_one,
						currency: visaData?.residence?.currency,
					});
					setIsReadyForPay(true);
				} else {
					setModalPayment(true);
				}
			}
			handleSearch();
		}
	}, [isErrorLoaded]);

	async function payOnlineVisa(paymentType, currency, otp, price) {
		const data = {
			destination_id: visaData?.destination?.country_code,
			// residence_id: visaData?.residence?.id,
			configuration_uuid: visaData?.residence?.uuid,
			package_uuid: package_plan?.package_uuid,
			visa_type_id: visaData?.visa_type?.id,
			pax: visaData?.pax,
			currency: currency,
			expiration_days: visaData?.residence?.expiration_days,
			payment_method: paymentType,
			otp: otp,
		};
		const response = await createOnlineVisaRequest(data);
		if (response.status === 200) {
			store.addNotification({
				title: inventory.messages.bookingSuccessfully,
				message: commons.successfullyCompleted,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					pauseOnHover: true,
				},
			});
			history.push({
				pathname: inventoryPermission
					? `/inventory/visa-requests/edit/${response?.data?.data?.id}`
					: "/statistics",
			});
			dispatch({
				type: "onlineVisaSearch",
				payload: [],
			});
			toggleModelPayment();
		} else if (response?.status === 202) {
			setActionType(response?.data?.actionType);
			setFormData(response?.data?.data?.formData);
		}
	}

	const nationalities = visaData?.residence?.nationalities;
	return (
		<>
			<div
				className="visa-details-container"
				style={{
					background: `linear-gradient(180deg, #00000050, rgb(0 0 4 / 60%)),url(${
						visaData?.destination?.country == "Saudi Arabia"
							? SaudiArabiaImg
							: visaData?.destination?.country == "UAE"
							? UAEImg
							: visaData.destination?.country_photo
					}) no-repeat center/cover`,
				}}
			>
				<div className="container d-flex align-items-center">
					<h2 className="font-weight-bold h3 m-0">
						{visaData?.destination?.country}
					</h2>
					{/* <p className='mx-3'>
            ({visaData?.residence?.nationalities?.length > 0 ?
              visaData?.residence?.nationalities?.map((nationality, index) => (
                <span key={nationality?.country_id}>
                  {nationality?.name}
                  {index !== visaData?.residence?.nationalities?.length - 1 ? ", " : ""}
                </span>
              ))
              :
              `${inventory.messages.all}`
            })
          </p> */}
				</div>
			</div>
			<div className="container overFlowHide">
				{/* visa information */}
				<div className="visa-info-container row mt-3">
					<div className="col-md-3 my-1">
						<div className="bg-gray d-flex align-items-center w-100 p-3">
							<img
								src={visaPaxSrc}
								alt="visa pax icon"
								width="20px"
								height="22px"
							/>
							<p className="d-flex flex-column mx-2">
								<span className="font-weight-bold">{onlineVisa.Pax}</span>
								<span>
									{package_plan?.range_from} - {package_plan?.range_to}
								</span>
							</p>
						</div>
					</div>
					<div className="col-md-3 my-1">
						<div className="bg-gray d-flex align-items-center w-100 p-3">
							<img
								src={hourGlassSrc}
								alt="hour glass icon"
								width="20px"
								height="22px"
							/>
							<p className="d-flex flex-column mx-2">
								<span className="font-weight-bold">
									{marketPlace.processingTime}
								</span>
								<span>{visaData.residence?.processing_time}</span>
							</p>
						</div>
					</div>

					<div className="col-md-3 my-1">
						<div className="bg-gray d-flex align-items-center w-100 p-3">
							<img
								src={visaTypeSrc}
								alt="visa type icon"
								width="18px"
								height="27px"
							/>
							<p className="d-flex flex-column mx-2">
								<span className="font-weight-bold">
									{marketPlace.messages.type}{" "}
								</span>
								<span>{visaData?.visa_type?.name}</span>
							</p>
						</div>
					</div>

					<div className="col-md-3 my-1">
						<div className="bg-gray d-flex align-items-center w-100 p-3">
							<img
								src={visaPaymentSrc}
								alt="visa type icon"
								width="24px"
								height="21px"
							/>
							<p className="d-flex flex-column mx-2">
								<span className="font-weight-bold">
									{marketPlace.messages.paymentOptions}{" "}
								</span>
								<span>{marketPlace.onlinePaymentOrWallet}</span>
							</p>
						</div>
					</div>
				</div>

				{/* apply visa steps */}
				<div className="visa-info-container row mt-3">
					<div className="col-12">
						<div className="bg-gray w-100 p-3">
							<h3 className="font-weight-bold">{`${marketPlace.HowToApplyFor} ${inventory.messages.visa} ${visaData?.visa_type?.name} ?`}</h3>

							<div className="d-flex flex-wrap justify-content-between align-items-center my-4 mx-auto col-md-10 col-12">
								{/* <div className="d-flex flex-column align-items-center my-2 col-md-2 col-6 text-center">
									<img
										src={visaApplySrc}
										alt="apply visa"
										width="80px"
										height="70px"
									/>
									<span className="font-weight-bold text-brown mt-2">
										{payment.messages.apply}
									</span>
								</div> */}

								{/* <div className="d-none d-md-block">
									<img
										src={visaArrowSrc}
										alt="arrow"
										width="12px"
										height="12px"
									/>
								</div> */}

								<div className="d-flex flex-column align-items-center my-2 col-md-2 col-6 text-center">
									<img
										src={visaPaySrc}
										alt="pay visa"
										width="80px"
										height="70px"
									/>
									<span className="font-weight-bold text-brown mt-2">
										{inventory.messages.pay}
									</span>
								</div>

								<div className="d-none d-md-block arrow-icon">
									<img
										src={visaArrowSrc}
										alt="arrow"
										width="12px"
										height="12px"
									/>
								</div>

								<div className="d-flex flex-column align-items-center my-2 col-md-2 col-6 text-center">
									<img
										src={visaUploadSrc}
										alt="upload visa Documents"
										width="80px"
										height="70px"
									/>
									<span className="font-weight-bold text-brown mt-2">
										{marketPlace.uploadDocuments}
									</span>
								</div>

								<div className="d-none d-md-block arrow-icon">
									<img
										src={visaArrowSrc}
										alt="arrow"
										width="12px"
										height="12px"
									/>
								</div>

								<div className="d-flex flex-column align-items-center my-2 col-md-2 col-6 text-center">
									<img
										src={visaGetSrc}
										alt="get visa"
										width="80px"
										height="70px"
									/>
									<span className="font-weight-bold text-brown mt-2">
										{marketPlace.getVisa}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* visa include */}
				<div className="visa-include-container row mt-3">
					<div className="col-12">
						<div className="bg-gray w-100 p-3">
							<h4 className="font-weight-bold mb-2">
								{marketPlace.VisaInclusive}
							</h4>
							{package_plan?.visa_include?.map((item, index) => (
								<p
									className="d-flex align-items-center my-2"
									key={`${item}-${index}`}
								>
									<i class="fas fa-check fa-xs"></i>
									<span className="mx-2">{item}</span>
								</p>
							))}
							{/* <p className='d-flex align-items-center my-2'>
                <i class="fas fa-check"></i>
                <span className='mx-2'>Insurance</span>
                <button className='btn shadow-none p-0 m-0 text-primary' onClick={togglePolicyModal}>{marketPlace.viewPolicyDetails}</button>
              </p> */}
						</div>
					</div>
				</div>

				{/* visa travelers nationality */}
				<div className="visa-include-container row mt-3">
					<div className="col-12">
						<div className="bg-gray w-100 p-3">
							<h4 className="font-weight-bold mb-2">
								{marketPlace.messages.travelersNationality}
							</h4>
							{visaData?.residence?.all_nationalities ? (
								<p>
									<i class="fas fa-check fa-xs"></i>{" "}
									<span className="mx-2">{marketPlace.allNationalities}</span>
								</p>
							) : nationalities?.length&&nationalities?.length > 5 ? (
								<p className="d-flex align-items-center my-2 align-items-center">
									<i class="fas fa-check fa-xs"></i>
									<span className="mx-2">{marketPlace.someNationalities}</span>
									<i
										class="fas fa-eye "
										onClick={toggleSomeNationalities}
										role="button"
									></i>
								</p>
							) : (
								nationalities?.map((item) => {
									return (
										<p
											className="d-flex align-items-center my-2"
											key={item.country_id}
										>
											<i class="fas fa-check fa-xs"></i>
											<span className="mx-2">{item.name}</span>
										</p>
									);
								})
							)}
						</div>
					</div>
				</div>

				{/* visa Notes */}
				{package_plan?.notes ? (
					<div className="visa-include-container row mt-3">
						<div className="col-12">
							<div className="bg-gray w-100 p-3">
								<h4 className="font-weight-bold mb-2">
									{inventory.viewReservation.Notes}
								</h4>
								{package_plan?.notes}
							</div>
						</div>
					</div>
				) : null}

				{/* apply visa */}
				<div className="visa-apply-container row mt-3 mb-5">
					<div className="col-12">
						<div className="bg-gray w-100 p-3">
							<h4 className="font-weight-bold mb-2">
								{marketPlace.applyOnline}
							</h4>
							{/* visa apply form */}
							<div className="col-md-6 col-12 mx-auto">
								<div className="row">
									{/* pax */}
									<div className="col-md-6 cal-12 ">
										<div className="mybooking-input-wrapper marketplace-date">
											<NumberField
												type={"number"}
												hasLabel={true}
												removeArrow={true}
												placeholder={onlineVisa.Pax}
												label={`${onlineVisa.Pax} 
												(${marketPlace?.From} 
													${visaData?.package_plan?.range_from}
													${marketPlace?.To}
													${visaData?.package_plan?.range_to})`}
												value={visaData.pax}
												name="pax"
												id="pax"
												min={+package_plan?.range_from}
												max={+package_plan?.range_to}
												onChange={(e) => {
													setVisaData({
														...visaData,
														totalPrice:
															+e.target.value * visaData?.price_per_one,
														pax: e.target.value > 0 ? +e.target.value : "",
													});
													setErrors({
														...errors,
														...validate(e.target, {
															required: true,
															minNumber: +package_plan?.range_from,
															maxNumber: +package_plan?.range_to,
														}),
													});
													if (
														+e.target.value < package_plan?.range_from ||
														+e.target.value > package_plan?.range_to
													) {
														setIsReadyForPay(false);
													}
												}}
												errors={errors?.pax}
												color={errors?.pax?.required ? "danger" : ""}
											/>
										</div>
									</div>

									{isReadyForPay ? (
										<>
											<div className="col-md-12 d-flex justify-content-between align-items-center m-0 visa_search_result">
												<div className="d-flex align-items-center">
													<img
														src={dateExpireImage}
														alt=""
														width="28px"
														className="mr-3"
													/>
													<p className="d-flex flex-column font-weight-normal txt-blue">
														{marketPlace.messages.requestExpireDate}
														<span>
															{moment(visaData?.expireDate).format(
																"DD-MM-YYYY"
															)}
														</span>
													</p>
												</div>
												<div className="d-flex align-items-center">
													<img
														src={walletImage}
														alt=""
														width="28px"
														className="mx-3"
													/>
													<p className="d-flex flex-column font-weight-normal txt-blue">
														{marketPlace.messages.totalPrice}
														<span>
															{visaData?.residence?.currency}{" "}
															{visaData?.totalPrice?.toFixed(2)}
														</span>
													</p>
												</div>
											</div>
											<div className="col-12 d-flex align-items-center my-1">
												<input
													type="checkbox"
													name="visa-not-refundable"
													id="visa-not-refundable"
													onChange={() =>
														setVisaData({
															...visaData,
															visaNotRefundableCheck:
																!visaData?.visaNotRefundableCheck,
														})
													}
													checked={visaData?.visaNotRefundableCheck}
												/>
												<label
													className="mx-2 my-0 pointer user-select-none"
													htmlFor="visa-not-refundable"
												>
													{marketPlace.messages.visaNotRefundable}
												</label>
											</div>
											{package_plan?.entry_stamp ? (
												<div className="col-12 d-flex align-items-center my-2">
													<input
														type="checkbox"
														name="visa-not-agreement"
														id="visa-not-agreement"
														onChange={() =>
															setVisaData({
																...visaData,
																visaAgreement: !visaData?.visaAgreement,
															})
														}
														checked={visaData?.visaAgreement}
													/>
													<label
														className="mx-2 my-0 pointer user-select-none"
														htmlFor="visa-not-agreement"
													>
														<span>{marketPlace.messages.confirmRead}</span>{" "}
														<span
															className="text-primary border-bottom"
															style={{ textDecoration: "underline" }}
															onClick={toggleAgreementModal}
														>
															{marketPlace.messages.visaAgreement}
														</span>
													</label>
												</div>
											) : null}
										</>
									) : null}

									<div
										className={`${
											isReadyForPay ? "" : "col-md-6"
										} col-12  mb-2`}
									>
										<button
											className={`btn w-100 bg-nxt px-0 shadow-none text-uppercase ${
												isReadyForPay ? "" : "marginTop34"
											}  `}
											type="button"
											disabled={
												isReadyForPay
													? !visaData.visaNotRefundableCheck ||
													  (package_plan?.entry_stamp &&
															!visaData.visaAgreement)
													: false
											}
											onClick={sumbit}
										>
											{isReadyForPay
												? onlineVisa.PROCEEDTOCHECKOUT
												: marketPlace.calculatePrice}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal isOpen={someNationalities} centered size="lg">
				<ModalHeader toggle={toggleSomeNationalities}>
					{marketPlace.availableNationalities}
				</ModalHeader>
				<ModalBody className="row m-0">
					{nationalities?.map((item, index) => {
						return (
							<p
								className="d-flex align-items-center my-2 col-4"
								key={item.country_id}
							>
								<i class="fas fa-check fa-xs"></i>
								<span className="mx-2">{item.name}</span>
							</p>
						);
					})}
				</ModalBody>
			</Modal>

			<Modal isOpen={policyModalOpen} centered>
				<ModalHeader toggle={togglePolicyModal}>Policy</ModalHeader>
				<ModalBody>Policies</ModalBody>
			</Modal>

			<Modal isOpen={agreementModal} centered>
				<ModalHeader toggle={toggleAgreementModal}>Agreement</ModalHeader>
				<ModalBody>
					Agreement Agreement Agreement Agreement Agreement Agreement Agreement
					Agreement Agreement{" "}
				</ModalBody>
			</Modal>

			{/* payment modal */}
			{ModalPayment && (
				<NewPaymentAction
					isOpen={ModalPayment}
					toggleModal={toggleModelPayment}
					OTP={OTP_CHECK}
					canPayLater={false}
					formData={formData}
					actionType={actionType}
					Price={visaData?.price_per_one * +visaData?.pax}
					currency={visaData?.residence?.currency}
					secondCurrency={visaData?.residence?.provider_currency}
					onPayment={payOnlineVisa}
					setActionType={setActionType}
					setFormData={setFormData}
				/>
			)}
		</>
	);
}
