import newWallat from 'assets/images/newWallat.svg'
import noHotel from 'assets/images/placeHoleder.png'
import { useSBSState } from 'context/global'
import InviteModel from 'modules/products-builder/components/InviteModel/InviteModel'
import { useEffect, useRef, useState } from 'react'
import { store } from 'react-notifications-component'
import { Link, useHistory, useParams } from 'react-router-dom'
import { inviteOffers } from 'services/marketplace'
import Locale from 'translations'
// import { useSnackbar } from 'react-simple-snackbar'
import { ReactComponent as LeftArrowIcon } from 'assets/images/market-place/leftArrow.svg'
import { ReactComponent as ArrowDownIcon } from 'assets/images/market-place/Path.svg'
import { ReactComponent as ChevronUpIcon } from 'assets/images/market-place/up-icon.svg'

const regex = /(<([^>]+)>)/gi;

function ListItem({ item, searchBy, Night }) {
	// const [openSnackbar] = useSnackbar()
	const history = useHistory();
	const { status } = useParams()
	const { marketPlace } = Locale
	const imageRef = useRef(null)
	const { token_data } = useSBSState()
	const [isOpenInviteData, setisOpenInviteData] = useState({
		isOpenInviteModel: false,
		name: '',
		email: '',
		phone_number: ''
	})
	const [showMorePrices, setShowMorePrices] = useState(false)
	const toggleInviteModel = () => {
		setisOpenInviteData({
			isOpenInviteModel: !isOpenInviteData.isOpenInviteModel,
			name: '',
			email: '',
			phone_number: ''
		})
	}
	const search = window.location.search
	const name = new URLSearchParams(search)
	const [params, setParams] = useState({})

	const handleToggle = () => {
		if (showMorePrices) {
			setShowMorePrices(!showMorePrices)
		} else {
			setShowMorePrices(!showMorePrices)
		}
	}

	async function submitInvite() {
		const res = await inviteOffers({ ...isOpenInviteData })
		if (res?.status === 200) {
			toggleInviteModel()
			store.addNotification({
				title: 'info!',
				message: res.data.message,
				type: 'success',
				insert: 'top',
				container: 'top-right',
				animationIn: ['animated', 'fadeIn'],
				animationOut: ['animated', 'fadeOut'],
				dismiss: {
					duration: 2000,
					onScreen: true,
					pauseOnHover: true
				}
			})
		}
	}

	useEffect(() => {
		let x = {}
		for (const [key, value] of name) {
			x = { ...x, [key]: value }
		}
		setParams(x)
	}, [])
	const hotelRating = +item?.rating > 5 ? 5 : Math.ceil(+item?.rating);

	const description = item.description.replace(regex, "");

	return (
		<div>
				{/* GDS {item?.cachedHotels?.[0]?.gds}
				<br />
				GDS {item?.cachedHotels?.[1]?.gds} */}
			<div className='hotel-detail-section'>
				<div className='img-box'>
					<img
						src={item.image ? `${item.image}` : noHotel}
						ref={imageRef}
						onError={(e) => {
							imageRef.current.src = noHotel
						}}
						alt='MarketImg'
					/>
				</div>

				<div className='hotel-detail-item'>
					<div className='hotel-header px-1'>
						<h6 className='hotel-titleHotel d-flex justify-content-between align-items-center'>
							{searchBy === 'hotel' ? item.name : item.name}
							{token_data && token_data.discount != null ? (
								<div className='d-flex'>
									<button
										className='mx-1 btn btn-outline-primary'
										onClick={(e) => {
											navigator.clipboard.writeText(
												window.location.href.replace(
													'market-view',
													`market-details/${item?.cachedHotels[0].search_token}`
												) +
													`&gds=${item.cachedHotels[0].gds}&hotel_code=${item.cachedHotels[0].hotel_code}&search_token=${item.cachedHotels[0].search_token}&date_from=${token_data?.search_payload?.date_from}&date_to=${token_data?.search_payload?.date_to}`
											)
											// openSnackbar('This is the content of the Snackbar.')										// openSnackbar('This is the content of the Snackbar.')
											store.addNotification({
												title: '',
												message: 'Copy successfully',
												type: 'success',
												insert: 'top',
												container: 'top-right',
												animationIn: ['animate__animated', 'animate__fadeIn'],
												animationOut: ['animate__animated', 'animate__fadeOut'],
												dismiss: {
													duration: 5000,
													onScreen: true
												}
											})
										}}
									>
										<i class='fas fa-share-alt mx-1'></i>
										Share
									</button>
									<button
										onClick={() => {
											let url =
												window.location.href.replace(
													'market-view',
													`market-details/${item?.cachedHotels[0].search_token}`
												) +
												`&gds=${item.cachedHotels[0].gds}&search_token=${item.cachedHotels[0].search_token}&hotel_code=${item.cachedHotels[0].hotel_code}&date_from=${token_data?.search_payload?.date_from}&date_to=${token_data?.search_payload?.date_to}`

											setisOpenInviteData({
												...isOpenInviteData,
												isOpenInviteModel: true,
												gds: item.cachedHotels[0].gds,
												hotel_code: item.cachedHotels[0].hotel_code,
												url: url
											})
										}}
										className='btn btn-outline-secondary'
									>
										<i class='fas fa-envelope-open-text mx-1'></i>
										Invite
									</button>
								</div>
							) : (
								''
							)}
						</h6>

						<>
							<p className='hotel-title2'>
								<span>
									{item.address !== '' ? item.address + ' |' : item.address}
								</span>
							</p>
							{+hotelRating > 0? 
								<div className='hotel-star-rate m-0'>
									{[...Array(+hotelRating).keys()].map((i) => (
										<i key={i} className='fa fa-star' aria-hidden='true'></i>
									))}
								</div>
								:
								null
							}
							<div>
								<p className='titleHotelDescription'>
									{description
										? description.length > 200
											? description.slice(0, 200) + '...'
											: description
										: ''}
								</p>
							</div>
						</>
					</div>
				</div>
			</div>

			<div className='new-price-number'>
				<>
					{/* <Link
								onClick={() =>
									localStorage.setItem(
										"selectedHotelSearch",
										JSON.stringify({
											...item,
											search_token_selected: res.search_token,
										})
									)
								}
								to={{
									pathname: params.hasOwnProperty("token")
										? `/market-details/${res.search_token}?token=${params.token}`
										: `/market-details/${res.search_token}`,
									state: {
										code: res.hotel_code,
									},
								}}
							> */}
					<div
						className='total-price-box py-2 w-100'
						style={{ borderBottom: '1px solid #cecece' }}
					>
						{token_data && token_data.discount != null ? (
							<Link
								onClick={() =>
									localStorage.setItem(
										'selectedHotelSearch',
										JSON.stringify({
											...item,
											search_token_selected: item?.cachedHotels[0].search_token
										})
									)
								}
								to={{
									pathname: params.hasOwnProperty('token')
										? `/market-details/${item?.cachedHotels[0].search_token}?token=${params.token}`
										: `/market-details/${item?.cachedHotels[0].search_token}`,
									state: {
										code: item?.cachedHotels[0].hotel_code
									}
								}}
							>
								<div className='d-flex justify-content-between'>
									<p
										className='d-flex align-items-center px-4 '
										style={{ color: 'red' }}
									>
										<span className='font-weight-bold'>
											{token_data.discount}
										</span>{' '}
										%{' OFF'}
									</p>

									<div className='d-flex pr-3'>
										<img src={newWallat} className='pr-2' alt='' srcset='' />
										<p
											className='price-number price-text'
											style={{
												fontWeight: '700',
												lineHeight: '.9',
												fontSize: '17px',
												marginBottom: '5px',
												marginTop: '5px'
											}}
										>
											<p className='old-price'>
												{' '}
												{(
													(Number(
														item?.cachedHotels[0]?.total_price
													) /
														Math.ceil(100 - +token_data?.discount)) *
														token_data?.discount +
													Number(
														item?.cachedHotels[0].total_price
													)
												).toFixed(2)}{' '}
												{item?.cachedHotels[0].currency}
											</p>
											{item?.cachedHotels[0].total_price}{' '}
											<span className='text-currency pr-1'>
												{item?.cachedHotels[0].currency}
											</span>
											<span className='text-Nights'>
												/ {marketPlace.Nights} {Night}{' '}
											</span>
										</p>
									</div>
								</div>
							</Link>
						) : (
							<div className='d-flex flex-column'>
								<div className='d-flex justify-content-between p-3 align-items-center'>
									<div className='d-flex align-items-center'>
										<img src={newWallat} style={{marginRight:'10px'}} alt='' srcset='' />
										<div>
											<h6 className='new-color text-left pr-4 mb-2'>
												{marketPlace.messages.startFrom}
											</h6>

											<p
												className='price-number price-text pr-4'
												style={{
													fontWeight: '700',
													lineHeight: '.9',
													fontSize: '12px',
													marginBottom: '5px'
												}}
											>
												{item?.cachedHotels[0].total_price}{' '}
												<span className='text-currency pr-1'>
													{item?.cachedHotels[0].currency}
												</span>
												<span className='text-Nights'>
													/ {marketPlace.Nights} {Night}{' '}
												</span>
											</p>
										</div>
									</div>

									<div
										onClick={() => {
											localStorage.setItem(
												'selectedHotelSearch',
												JSON.stringify({
													...item,
													search_token_selected:
														item?.cachedHotels[0].search_token
												})
											);
											history.push({
												pathname: params.hasOwnProperty('token')
													? `/market-details/${item?.cachedHotels[0].search_token}?token=${params.token}`
													: `/${status}/market-details/${item?.cachedHotels[0].search_token}`,
												state: {
													code: item?.cachedHotels[0].hotel_code
												}
											})
										}}
										// to={{
										// 	pathname: params.hasOwnProperty('token')
										// 		? `/market-details/${item?.cachedHotels[0].search_token}?token=${params.token}`
										// 		: `/market-details/${item?.cachedHotels[0].search_token}`,
										// 	state: {
										// 		code: item?.cachedHotels[0].hotel_code
										// 	}
										// }}
									>
										<button className='btn btn-viewMore'>
											<span className='me-2'>{marketPlace.messages.view}</span>
											<LeftArrowIcon />
										</button>
									</div>
								</div>
								{item?.cachedHotels?.length > 1 ? (
									!showMorePrices ? (
										<button
											className='btn view-more-prices'
											onClick={handleToggle}
										>
											<span className='mr-2'>{marketPlace.viewMorePrices}</span>
											<ArrowDownIcon />
										</button>
									) : (
										<button
											className='btn view-more-prices'
											onClick={handleToggle}
										>
											<span className='mr-2'>{marketPlace.viewLessPrices}</span>
											<ChevronUpIcon />
										</button>
									)
								) : null}

								{item?.cachedHotels?.length > 1
									? showMorePrices
										? item?.cachedHotels
												?.slice(1, item?.cachedHotels?.length)
												?.map((res) => (
													<div className='d-flex justify-content-between p-3 align-items-center toggle-more-prices'>
														<div className='d-flex align-items-center'>
															<img
																src={newWallat}
																style={{marginRight:'10px '}}
																alt=''
																srcset=''
															/>
															<div>
																<h6 className='new-color text-left pr-4 mb-2'>
																	{marketPlace.messages.startFrom}
																</h6>

																<p
																	className='price-number price-text pr-4'
																	style={{
																		fontWeight: '700',
																		lineHeight: '.9',
																		fontSize: '12px',
																		marginBottom: '5px'
																	}}
																>
																	{res.total_price}{' '}
																	<span className='text-currency pr-1'>
																		{res.currency}
																	</span>
																	<span className='text-Nights'>
																		/ {marketPlace.Nights} {Night}{' '}
																	</span>
																</p>
															</div>
														</div>

														<div
															onClick={() => {
																localStorage.setItem(
																	'selectedHotelSearch',
																	JSON.stringify({
																		...item,
																		search_token_selected: res.search_token
																	})
																);
																history.push({
																	pathname: params.hasOwnProperty('token')
																		? `/market-details/${res.search_token}?token=${params.token}`
																		: `/${status}/market-details/${res.search_token}`,
																	state: {
																		code: res.hotel_code
																	}
																})
															}}
															// to={{
															// 	pathname: params.hasOwnProperty('token')
															// 		? `/market-details/${res.search_token}?token=${params.token}`
															// 		: `/market-details/${res.search_token}`,
															// 	state: {
															// 		code: res.hotel_code
															// 	}
															// }}
														>
															<button className='btn btn-viewMore'>
																<span className='mr-2'>
																	{marketPlace.messages.view}
																</span>
																<LeftArrowIcon />
															</button>
														</div>
													</div>
												))
										: null
									: null}
							</div>
						)}
					</div>
					{/* </Link> */}
				</>
			</div>
			{isOpenInviteData.isOpenInviteModel ? (
				<InviteModel
					submitInvite={submitInvite}
					toggle={toggleInviteModel}
					isOpen={isOpenInviteData.isOpenInviteModel}
					isOpenInviteData={isOpenInviteData}
					setisOpenInviteData={setisOpenInviteData}
				/>
			) : null}
		</div>
	)
}

export default ListItem
