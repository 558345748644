import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import fromIcom from "assets/images/market-place/servicesIcon/fromIcom.svg";
import toIcon from "assets/images/market-place/servicesIcon/toIcon.svg";
import { useSBSState } from "context/global";
import {
	useMarketplaceDispatch,
	useMarketplaceState,
} from "context/marketplace";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useParams } from "react-router-dom";
import { fetchAirPorts, fetchFlights } from "services/marketplace";
import Locale from "translations";
import SearchIcon from "../../../icons/searchIcon";
import airlineIcon from "assets/images/market-place/servicesIcon/airlineIcon.svg";
import { useDetectClickOutside } from "react-detect-click-outside";

export default function MultiDestination({
	Options,
	setListAirlines,
	listAirlines,
	getListAirlines,
	modify,
	closeModal,
	setOptionsChildren,
}) {
	const { marketPlace, inventory, messages, commons } = Locale;
	const history = useHistory();
	const { status } = useParams();
	const { locale } = useSBSState();
	const [flights, setFlights] = useState({ children: 0, airLegs: [{}, {}] });
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const dispatch = useMarketplaceDispatch();
	const { flightsSearch } = useMarketplaceState();
	const [paxIsOpen, setPaxIsOpen] = useState(false);
	const paxToggle = () => setPaxIsOpen((prev) => !prev);
	const [adultState, setAdultState] = useState(0);
	const [childState, setChildState] = useState(0);
	const [matches, setMatches] = useState(
		window.matchMedia("(max-width: 600px)").matches
	);

	useEffect(() => {
		window
			.matchMedia("(max-width: 600px)")
			.addEventListener("change", (e) => setMatches(e.matches));
	}, []);

	const maxAdultsCount = 20;

	const DetectClickOutside = useDetectClickOutside({
		onTriggered: () => setPaxIsOpen(false),
	});

	const checkFormErrors = () => {
		let submitError = {};
		const validationKeys = ["from", "to", "data"];
		flights.airLegs?.forEach((guest, guestIndex) => {
			validationKeys.forEach((key) => {
				submitError = {
					...submitError,
					...validate(
						{ name: [key + guestIndex], value: guest[key] },
						{ required: true }
					),
				};
			});
		});
		setErrors({
			...submitError,
			...errors,
			...validate(
				{ name: "adults", value: flights.adults },
				{ required: true, minNumber: +flights?.adults === 0 }
			),
			...validate(
				{ name: "cabinClass", value: flights.cabinClass },
				{ required: true }
			),
		});
	};

	const handleSearch = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	useEffect(() => {
		if (modify && flightsSearch) {
			// debugger
			if (flightsSearch.type === "3") {
				flightsSearch.airLegs.forEach((i) => {
					i.data = moment(i.data);
				});
			} else {
				flightsSearch.airLegs = [{}, {}];
			}

			setOptionsChildren(+flightsSearch?.adults);

			setFlights(flightsSearch);
		}

		return () => {};
	}, []);

	useEffect(() => {
		async function handleBranches() {
			if (isFormValid(errors)) {
				let data = {
					adults: flights.adults,
					children: flights.children,
					airline: flights.airline?.id,
					airLegs: flights.airLegs.map((res) => {
						return {
							cabinClass: flights.cabinClass?.value,
							departureDate: moment(res.data).format("YYYY-MM-DD"),
							origin: res?.from?.code,
							destination: res?.to?.code,
						};
					}),
				};

				const response = await fetchFlights(data);
				if (response.status === 200) {
					const data = response.data;

					if (response.data.data.length > 0) {
						dispatch({
							type: "flightsSearchResults",
							payload: data,
						});
						dispatch({
							type: "saveFlightsSearch",
							payload: { ...flights, cacheKey: data?.cacheKey, type: "3" },
						});
						dispatch({
							type: "outboundFlight",
							payload: null,
						});
						dispatch({
							type: "inboundFlight",
							payload: null,
						});
						if (modify) {
							closeModal();
							history.location.pathname === `/${status}/inbound-view` &&
								history.push(`/${status}/outbound-view`);
						} else {
							history.push(`/${status}/outbound-view`);
						}
					} else {
						store.addNotification({
							title: messages.noResults,
							message: messages.noSearchResults,
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
						});
					}
				}
			}
		}
		handleBranches();
	}, [isErrorLoaded]);

	let goingToPrevValue = useRef(null);

	function clearSearchText(e, key) {
		goingToPrevValue.current = flights[key];
		const value = e.target.value;
		if (value.length > 0) {
			setFlights({ ...flights, [key]: null });
		}
	}

	function setAirLegs(value, index, name) {
		let x = [...flights.airLegs];
		x[index] = { ...x[index], [name]: value };
		setFlights({ ...flights, airLegs: x });
	}

	// AutoCompleteField
	const [listAuto, setListAuto] = useState({});
	const getListAuto = async (inputValue, index, key) => {
		if (inputValue.length > 2) {
			const Airlines = await fetchAirPorts({ search: inputValue });
			let result = [];

			Airlines.map((item) => {
				result.push({
					...item,
					name: item !== "" ? item.names[locale] : item.names[locale],
					value: item.id,
					label: item !== "" ? item.names[locale] : item.names[locale],
				});
			});
			setListAuto({ ...result, [index + "AirPort" + key]: result });
		}
	};

	return (
		<>
			<div className="flight-search">
				{flights.airLegs && flights.airLegs.length > 0
					? flights.airLegs.map((res, index) => {
							return (
								<div className="col-md-12 d-flex m-auto flex-wrap">
									<div className="col-md-4 fromflight">
										<div className="form-group">
											{matches && (
												<label className="label-m-color">
													{marketPlace.From}
												</label>
											)}
											<AutoCompleteField
												hasLabel={false}
												labelInner={true}
												label={!matches && marketPlace.From}
												isImage={true}
												image={fromIcom}
												isSearchable={true}
												placeholder={marketPlace.origin}
												listAuto={listAuto[index + "AirPortForm"]}
												setListAuto={setListAuto}
												getListAuto={(e) => getListAuto(e, index, "Form")}
												value={res?.from?.name || ""}
												// flag={flights.from?.country?.flag}
												onFocus={(e) => clearSearchText(e, "from")}
												onBlur={() =>
													setFlights({
														...flights,
														from: goingToPrevValue.current,
													})
												}
												onChange={(e) => {
													setAirLegs({ name: e }, index, "from");
												}}
												onSelectValue={(e) => {
													setAirLegs(e, index, "from");

													setErrors({
														...errors,
														...validate(
															{ name: [`from${index}`], value: e },
															{ required: true }
														),
													});
												}}
												color={errors[`from${index}`]?.required ? "danger" : ""}
												errors={errors[`from${index}`]}
											/>
											<i class="fas fa-exchange-alt d-none"></i>
										</div>
									</div>

									<div className="col-md-4 toflight">
										<div className="form-group">
											{matches && (
												<label className="label-m-color">
													{marketPlace.To}
												</label>
											)}
											<AutoCompleteField
												hasLabel={false}
												labelInner={true}
												label={!matches && marketPlace.To}
												isSearchable={true}
												isImage={true}
												image={toIcon}
												placeholder={marketPlace.messages.Destination}
												listAuto={listAuto[index + "AirPortTO"]}
												setListAuto={setListAuto}
												getListAuto={(e) => getListAuto(e, index, "TO")}
												value={res?.to?.name || ""}
												// flag={flights.from?.country?.flag}
												onFocus={(e) => clearSearchText(e, "to")}
												onBlur={() =>
													setFlights({
														...flights,
														to: goingToPrevValue.current,
													})
												}
												onChange={(e) => {
													setAirLegs({ name: e }, index, "to");
												}}
												onSelectValue={(e) => {
													setAirLegs(e, index, "to");
													setErrors({
														...errors,
														...validate(
															{ name: [`to${index}`], value: e },
															{ required: true }
														),
													});
												}}
												color={errors[`to${index}`]?.required ? "danger" : ""}
												errors={errors[`to${index}`]}
											/>
										</div>
									</div>

									<div className="col-md-4 ">
										<div className="form-group">
											{matches && (
												<label className="label-m-color">
													{marketPlace.Depart}
												</label>
											)}
											<DatePickerField
												hasLabel={false}
												labelInner={true}
												label={!matches && marketPlace.Depart}
												placeholder={"DD/MM/YYYY"}
												onChangeDate={(date) => {
													setAirLegs(date, index, "data");
													setErrors({
														...errors,
														...validate(
															{ name: [`data${index}`], value: date },
															{ required: true }
														),
													});
												}}
												isOutsideRange={(day) => {
													return !day.isAfter(moment().add(-1, "d"), "day");
												}}
												date={res.data}
												color={errors[`data${index}`]?.required ? "danger" : ""}
												errors={errors[`data${index}`]}
											/>
										</div>
									</div>
								</div>
							);
					  })
					: ""}

				<div className="col-md-12 d-flex m-auto flex-wrap">
					{/**Adults and children */}
					<div className="col-md-6 col-12" ref={DetectClickOutside}>
						<div className="button-collapse mt-1">
							{matches && (
								<label className="label-m-color">
									{marketPlace.messages.pax}
								</label>
							)}
							<div
								className={`title-style-container d-flex bg-light p-2 ${
									errors?.adults?.required || errors?.adults?.minNumber
										? "control-field--danger"
										: ""
								}`}
								style={{ borderRadius: "5px" }}
								onClick={() => paxToggle()}
							>
								<div className="w-100">
									<div className="w-100">
										<div
											className="global-input font-weight-bold border-0  d-flex justify-content-between"
											style={{ padding: matches ? "15px 0px 14px 15px" : 0 }}
										>
											<div className="d-flex flex-column">
												{!matches && (
													<span style={{ fontWeight: 400, color: "#000" }}>
														{marketPlace.messages.pax}
													</span>
												)}
												<span
													className="roomsNumber1"
													style={{
														lineHeight: 1,
														fontWeight: 700,
														color: "#000",
													}}
												>
													{flights?.adults ?? 0} {marketPlace.messages.adult}
													{" , "}
													{flights?.children} {marketPlace.messages.child}
												</span>
											</div>

											<div></div>
										</div>
									</div>
								</div>

								<div className="icon-items-section-services d-flex align-items-center bg-light">
									<i
										className={`fas fa-fw text-gray px-4 fa-lg ${
											paxIsOpen ? "fa-chevron-up " : "fa-chevron-down"
										}`}
										// onClick={() => paxToggle()}
									></i>
								</div>
							</div>
							{paxIsOpen ? (
								<div className="row pax-container">
									<div className="adult-pax">
										<p>
											{inventory.messages.adults}{" "}
											<span>{locale === "en" ? "18+ yrs" : "18+ سنة"}</span>
										</p>
										<div className="action">
											{/* decrease adults */}
											<button
												onClick={() => {
													setAdultState((prev) => prev - 1);
													console.log("adultState", adultState);
													setFlights({
														...flights,
														adults: adultState - 1,
														children: 0,
													});
													setErrors({
														...errors,
														...validate(
															{
																name: "adults",
																value: adultState - 1,
															},
															{
																required: true,
																minNumber: adultState - 1 === 0,
															}
														),
													});
													setOptionsChildren(adultState - 1);
												}}
												disabled={adultState <= 0}
											>
												<span>-</span>
											</button>
											<p className="adultNum">{adultState}</p>
											{/* increase adults */}
											<button
												onClick={() => {
													setAdultState((prev) => prev + 1);

													setFlights({
														...flights,
														adults: adultState + 1,
														children: 0,
													});
													setErrors({
														...errors,
														...validate(
															{
																name: "adults",
																value: adultState + 1,
															},
															{
																required: true,
																minNumber: adultState + 1 === 0,
															}
														),
													});
													setOptionsChildren(adultState + 1);
												}}
												disabled={maxAdultsCount === adultState + 1}
											>
												<span>+</span>
											</button>
										</div>
									</div>

									<div className="adult-pax child-pax">
										<p>
											{inventory.messages.children}{" "}
											<span>{locale === "en" ? "0-7 yrs" : "0-7 سنة"}</span>
										</p>
										<div className="action">
											<button
												onClick={() => {
													setChildState((prev) => prev - 1);
													setFlights({
														...flights,
														children: childState - 1,
													});
												}}
												disabled={childState <= 0}
											>
												<span>-</span>
											</button>
											<p className="adultNum">{childState}</p>
											<button
												onClick={() => {
													setChildState((prev) => prev + 1);
													setFlights({
														...flights,
														children: childState + 1,
													});
												}}
											>
												<span>+</span>
											</button>
										</div>
									</div>
								</div>
							) : null}
							{errors?.adults?.required || errors?.adults?.minNumber ? (
								<small className="control-field__feedback control-field__feedback--danger d-block error-message">
									{inventory.messages.pax} {commons.isRequired}
								</small>
							) : null}
						</div>
					</div>

					<div className="col-md-3 ">
						<div className="form-group">
							{matches && (
								<label className="label-m-color">{marketPlace.class}</label>
							)}
							<SelectField
								hasLabel={false}
								labelInner={true}
								label={!matches && marketPlace.class}
								placeholder={marketPlace.select}
								isPlaceholderBold={true}
								isPlaceholderDark={true}
								options={Options.classes}
								value={flights?.cabinClass?.name}
								onChange={(e) => {
									setFlights({
										...flights,
										cabinClass: e,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "cabinClass", value: e },
											{ required: true }
										),
									});
								}}
								onBlur={() =>
									setErrors({
										...errors,
										...validate(
											{
												name: "cabinClass",
												value: flights.cabinClass,
											},
											{ required: true }
										),
									})
								}
								color={errors.cabinClass?.required ? "danger" : ""}
								errors={errors.cabinClass}
							/>
						</div>
					</div>

					<div className="col-md-3 ">
						<div className="form-group">
							{matches && (
								<label className="label-m-color">{marketPlace.airlines}</label>
							)}
							<AutoCompleteField
								hasLabel={false}
								labelInner={true}
								label={!matches && marketPlace.airlines}
								isImage={true}
								image={airlineIcon}
								placeholder={marketPlace.enterAirlines}
								listAuto={listAirlines}
								setListAuto={setListAirlines}
								getListAuto={getListAirlines}
								value={flights?.airline?.name || ""}
								// flag={flights.airline?.country?.flag}
								onFocus={(e) => clearSearchText(e, "airline")}
								onBlur={() =>
									setFlights({
										...flights,
										airline: goingToPrevValue.current,
									})
								}
								onChange={(e) => {
									setFlights({
										...flights,
										airline: { name: e },
									});
								}}
								onSelectValue={(e) => {
									setFlights({
										...flights,
										airline: e,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "airline", value: e },
											{ required: false }
										),
									});
								}}
								color={errors?.airline?.required ? "danger" : ""}
								errors={errors?.airline}
							/>
						</div>
					</div>
				</div>
				<div className="col-md-6"></div>
				<div className="col-md-6">
					<div className="full-input search-button">
						<div className="full-input search-button">
							<button
								onClick={() => {
									// history.push("/outbound-view");
									handleSearch();
								}}
								className="btn w-100 bg-nxt mb-2 mt-1 text-17"
								type="button"
								style={{
									paddingBlock: "12px",
									margin: matches && "1rem 0.25rem",
								}}
							>
								<SearchIcon /> {marketPlace.messages.search}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
