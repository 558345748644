import React, { useState } from 'react';
import { ReactComponent as EditIcon } from "assets/images/umrah-operations/edit-icon.svg";
import { ReactComponent as CarIcon } from "assets/images/umrah-operations/car-icon.svg";
import { ReactComponent as CheckMarkIcon } from "assets/images/umrah-operations/check.svg";
import { ArrowDownIcon } from 'modules/UmrahOperations/shared/Icons';
import Locale from 'translations';
import moment from 'moment';
import { useDetectClickOutside } from 'react-detect-click-outside';
import ShowForPermission from 'helpers/showForPermission';


function OperatingCenterTableRow({
  movmentItem,
  toggleSupervisorModal,
  toggleOfficerModal,
  toggleDriversModal,
  toggleExecuteModal,
  handleExecuteMovement
}) {
  const { operationStatement, productsBuilder } = Locale;
  const filesUrl = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
  const shownCount = 2;
  const driversShownArr =
    movmentItem?.drivers?.length > shownCount
      ? movmentItem?.drivers?.slice(0, shownCount)
      : movmentItem?.drivers;
  const driversHiddenArr =
    movmentItem?.drivers?.length > shownCount
      ? movmentItem?.drivers?.slice(shownCount)
      : [];

  const [showMoreDrivers, setShowMoreDrivers] = useState(false);
  const driversList = showMoreDrivers ? [...driversShownArr, ...driversHiddenArr] : [...driversShownArr];

  const detectClickOutsideRef = useDetectClickOutside({
    onTriggered: () => setShowMoreDrivers(false),
  });
  function movementTypesText(type) {
    switch (type) {
      case "arrival":
        return operationStatement.Arrival
      case "departure":
        return operationStatement.Departure
      case "attraction":
        return operationStatement.mazar
      case "internal_movement":
        return operationStatement.transfer
      default:
        break;
    }
  }

  return (
    <tr>
      {/* type */}
      <td className={movmentItem?.type}>
        <div className='d-flex align-items-center'>
          <span className='indecator'></span>
          <div className='d-flex flex-column mx-2'>
            <span className='text-capitalize'>{movementTypesText(movmentItem?.type)}  </span>
            <span># {movmentItem?.company_statement_reference}</span>
          </div>
        </div>
      </td>

      {/* agent */}
      <td width="115px">
        <p>{movmentItem?.agent?.name}</p>
        <div className="d-flex align-items-center">
          <img
            src={`${filesUrl}/${movmentItem?.agent?.country?.flag}`}
            alt={movmentItem?.agent?.country?.name}
            className="rounded-circle"
            width={"16px"}
            height={"16px"}
            style={{ flex: "0 0 16px" }}
          />
          <span className="font-light mx-1">
            {movmentItem?.agent?.country?.name}
          </span>
        </div>
      </td>

      {/* arrival */}
      <td>
        <p>{movmentItem?.arrival?.hall || "-"}</p>
        <p className='font-light'>
          {movmentItem?.arrival?.date ? moment(movmentItem?.arrival?.date).format("DD/MM/YYY  hh:mmA") : ""}
        </p>
      </td>

      {/* Supervisor */}
      <td>
        {movmentItem?.supervisor?.name ?
          <>
            <p className='d-flex flex-nowrap'>
              <span>{movmentItem?.supervisor?.name}</span>
              <ShowForPermission permission="Manage-operation-center">
                <button className='btn p-0 mx-1'
                  onClick={() => toggleSupervisorModal("edit", movmentItem?.supervisor, movmentItem?.id)}
                >
                  <EditIcon width="16px" height="16px" />
                </button>
              </ShowForPermission>
            </p>
            <p className='font-light direction-initial'>
              {movmentItem?.supervisor?.phone_code}{movmentItem?.supervisor?.phone}
            </p>

          </>
          :
          <ShowForPermission permission="Manage-operation-center">
            <button className='btn add-item-btn'
              onClick={() => toggleSupervisorModal("add", null, movmentItem?.id)}
            >
              <i className="fas fa-plus-circle"></i>
              <span >{productsBuilder.add}</span>
            </button>
          </ShowForPermission>
        }

        {movmentItem?.nusuk ?
          <p>
            <span className='font-light' style={{ color: "#FF9F43" }}>{operationStatement.nusuk}</span>
            <span className='font-light'>: {movmentItem?.nusuk}</span>
          </p>
          :
          "-"
        }
      </td>

      {/* From */}
      <td className='from-color'>
        {movmentItem?.pickupPoints && movmentItem?.pickupPoints?.length > 0 ?
          movmentItem?.pickupPoints?.map(point => {
            return (
              <div key={`${point?.id}-${point?.item_type}`}>
                <p className='font-light text-capitalize'>{point?.city?.name || point?.port_type}</p>
                <p>{point?.title}</p>
              </div>
            )
          })
          :
          "-"
        }

      </td>
      {/* to */}
      <td className='to-color'>
        {movmentItem?.destinations && movmentItem?.destinations?.length > 0 ?
          movmentItem?.destinations?.map(point => {
            return (
              <div key={`${point?.id}-${point?.item_type}`}>
                <p className='font-light'>{point?.city?.name || point?.port_type}</p>
                <p>{point?.title}</p>
              </div>
            )
          })
          :
          "-"
        }
      </td>
      {/* pax */}
      <td>{movmentItem?.pax}</td>
      {/* Operator */}
      <td>
        <p>{movmentItem?.operator?.name}</p>
        <p className='font-light'>Credential No.:{movmentItem?.operator?.credential_number || "-"}</p>
        <p className='font-light'>Operating ID.: {movmentItem?.operator?.operation_id || "-"}</p>
      </td>

      {/* Vehicle's drivers */}
      <td>
        <div className='d-flex justify-content-between align-items-center gap-24'>
          {/* add new Vehicle */}
          <div className='d-flex flex-column align-items-center vehicles'>
            <p className='d-flex align-items-center'>
              <CarIcon width="14px" height="14px" />
              <span className='mx-1'> x{movmentItem?.vehicle_count}</span>
            </p>
            {/* add driver */}
            {movmentItem?.vehicle_count > movmentItem?.drivers?.length ?
              <ShowForPermission permission="Manage-operation-center">
                <button className='btn add-item-btn'
                  onClick={() => toggleDriversModal("add", movmentItem?.drivers, movmentItem?.id, movmentItem?.vehicle_count)}
                >
                  <i className="fas fa-plus-circle"></i>
                  <span >{productsBuilder.add}</span>
                </button>
              </ShowForPermission>

              : null}
          </div>
          {/* drivers list */}
          <div className='d-flex flex-column mx-2' ref={detectClickOutsideRef}>
            {/* driver item */}

            {driversList?.length > 0 ?
              driversList?.map((driver, index) => {
                return (
                  <div className='d-flex align-items-baseline justify-content-between mb-2' key={`${driver?.id}-${index}`} >
                    <p className='d-flex flex-column mx-1'>
                      <span>{driver?.name}</span>
                      <span className='font-light direction-initial'>{driver?.phone_code}{driver?.phone}</span>
                    </p>
                    <ShowForPermission permission="Manage-operation-center">
                      <button className='btn p-0' onClick={() => toggleDriversModal("edit", movmentItem?.drivers, movmentItem?.id)}>
                        <EditIcon width="16px" height="16px" />
                      </button>
                    </ShowForPermission>
                  </div>
                )
              })
              :
              <span className='no-drivers'>{operationStatement.noDriversAdded}</span>
            }

            {/* show more drivers button */}
            {movmentItem?.drivers?.length > shownCount ?
              <button className='btn show-more-drivers-btn shown'
                onClick={() => setShowMoreDrivers(!showMoreDrivers)}
              >
                <ArrowDownIcon color='#1E85FF' />
                <span className='mx-1'>
                  {showMoreDrivers ? operationStatement.less : operationStatement.more}
                  {" "}{showMoreDrivers ? "-" : "+"}{movmentItem?.drivers?.length - shownCount}
                </span>
              </button>
              :
              null
            }
          </div>

        </div>
      </td>


      {/* Start date & time */}
      <td className='from-color'>
        <p>
          {movmentItem?.start_date ? moment(movmentItem?.start_date).format("DD/MM/YYYY  hh:mmA") : "-"}
        </p>
      </td>
      {/* end date & time */}
      <td className='to-color'>
        <p>
          {movmentItem?.end_date ? moment(movmentItem?.end_date).format("DD/MM/YYYY  hh:mmA") : "-"}
        </p>
      </td>
      {/* Movement official */}
      <td>
        {movmentItem?.officer ?
          <div className='d-flex align-items-baseline mb-2'>
            <p className='d-flex flex-column mx-1'>
              <span>{movmentItem?.officer?.name}</span>
              <span className='font-light  direction-initial'>
                {movmentItem?.officer?.phone_code}{movmentItem?.officer?.phone}
              </span>
            </p>
            <ShowForPermission permission="Manage-operation-center">
              <button className='btn p-0' onClick={() => toggleOfficerModal("edit", movmentItem, movmentItem?.id, movmentItem?.type)}>
                <EditIcon width="16px" height="16px" />
              </button>
            </ShowForPermission>
          </div>
          :
          movmentItem.delegation_company_name && !movmentItem?.officer ?
            <div className='d-flex align-items-baseline mb-2'>
              <p className='mx-1'>{movmentItem.delegation_company_name}</p>
              <ShowForPermission permission="Manage-operation-center">
                <button className='btn p-0' onClick={() => toggleOfficerModal("edit", movmentItem, movmentItem?.id, movmentItem?.type)}>
                  <EditIcon width="16px" height="16px" />
                </button>
              </ShowForPermission>
            </div>
            :
            <ShowForPermission permission="Manage-operation-center">
              <button className='btn add-item-btn' onClick={() => toggleOfficerModal("add", movmentItem?.officer, movmentItem?.id, movmentItem?.type)}>
                <i className="fas fa-plus-circle"></i>
                <span >{productsBuilder.add}</span>
              </button>
            </ShowForPermission>
        }
      </td>

      {/* Status */}
      <td>
        <span className={`status ${movmentItem?.is_executed ? "executed" : "unexecuted"}`}>
          {movmentItem?.is_executed ? operationStatement.executed : operationStatement.unexecuted}
        </span>
      </td>

      {/* Action */}
      <td width="140px">
        {!movmentItem?.is_executed ?
          <ShowForPermission permission="Manage-operation-center">
            <button className='btn make-execute-btn'
              onClick={() => {
                if (movmentItem?.type === "arrival" || movmentItem?.type === "departure") {
                  toggleExecuteModal({ movementId: movmentItem?.id })
                } else {
                  handleExecuteMovement(null, movmentItem?.id);
                }
              }}
            >
              <CheckMarkIcon />
              {operationStatement.makeExecute}
            </button>
          </ShowForPermission>
          :
          null
        }
      </td>
    </tr>
  )
}

export default React.memo(OperatingCenterTableRow)