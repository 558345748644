import React, { useEffect, useState } from 'react'
import { ReactComponent as Users } from "assets/images/icons/users.svg";
import Locale from 'translations';
import UAE from "assets/images/uae.png";
import SA from "assets/images/sa.png";
import hourGlassSrc from "assets/images/icons/hour-glass.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import passportICon from "assets/images/PassportICon.svg";
import { useHistory } from 'react-router-dom';
import { TDSListVisaConfigurations } from 'services/TDSVisa';

export default function TDSPackagesList() {
  const { marketPlace } = Locale;
  const history = useHistory();
  const [destinations, setDestinations] = useState(null);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [filter, setFilter] = useState([]);
  const [noteModal, setNoteModal] = useState({
    isOpen: false,
    note: "",
  });

  const [someNationalities, setSomeNationalities] = useState({
    isOpen: false,
    nationality: [],
  });

  const visaTypes = selectedDestination?.visa_types;


  function toggleSomeNationalities(data) {
    setSomeNationalities((prev) => ({
      isOpen: !prev.isOpen,
      nationality: data,
    }));
  }

  function toggleNote(note) {
    setNoteModal((prev) => ({
      isOpen: !prev.isOpen,
      note,
    }));
  };

  function onChangeFilter(e, checked) {
    const value = e.target.value;
    let newFilter = [...filter];

    if (checked) {
      if (newFilter.length > 1) {
        newFilter = newFilter.filter((types) => types !== value);
      }
    } else {
      newFilter = [...newFilter, value];
    }
    setFilter(newFilter);
  };

  function selectVisa({ visaType, packagePlan, residence }) {
    const payload = {
      destination: selectedDestination,
      visa_type: visaType,
      package_plan: packagePlan,
      residence
    }
    window.localStorage.setItem("tdsVisa", JSON.stringify(payload));
    window.sessionStorage.clear();
    history.push('/tds-visa/details');
  }

  async function fetchDestinations() {
    const response = await TDSListVisaConfigurations();
    if (response.status === 200) {
      setDestinations(response?.data?.data);
      if (response?.data?.data?.length > 0) {
        setSelectedDestination(response?.data?.data?.[0])
      }
    }
  };

  useEffect(() => {
    fetchDestinations();
    return ()=> {}
  }, []);

  useEffect(() => {
    if (visaTypes && filter && filter.length === 0) {
      let filterData = []

      visaTypes?.forEach((visa) => {
        filterData.push(visa.name)
      });

      setFilter(filterData);
    }
    return ()=> {}
  }, [filter, visaTypes]);

  return (
    <>
      {destinations ?
        <div className='visa-container py-5 tds-visa-container'>
          {/* visa countries */}
          <div className="p-3">
            <div className="visa-selected-container row mx-0 justify-content-center">
              {destinations?.map((destination) => {
                const countryImageURL = `${process.env.REACT_APP_FILEUPLOAD_URL}/fetch/${destination?.country_photo}`;
                return (
                  <div
                    className="col-md-2 col-12 mb-2 mb-md-0"
                    key={`destination-${destination?.country_code}`}
                  >
                    <div
                      className={`visa-destination-card borderRid visa-destination-services pointer ${destination?.country_code ===
                        selectedDestination?.country_code
                        ? "active"
                        : ""
                        } `}
                      onClick={() => {
                        setSelectedDestination({
                          ...destination,
                          country_photo: countryImageURL,
                        });
                        setFilter([]);
                      }}
                    >
                      <div className="d-flex justify-content-between align-content-center align-items-center">
                        <h5 className={`visa-card-header `}>
                          <img
                            width={"44px"}
                            style={{ marginInlineEnd: "5px" }}
                            src={destination?.country === "UAE" ? UAE : SA}
                            alt=""
                          />
                          {destination?.country}
                        </h5>

                        <div
                          className={`visa-radio-input ${destination?.country_code ===
                            selectedDestination?.country_code
                            ? "active"
                            : ""
                            }`}
                        ></div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {visaTypes && visaTypes.length > 0 ?
            <div className='col-md-10 col-12 mx-auto mt-3'>
              {/* filter bar */}
              <div className="bg-light-darker w-100 d-flex flex-wrap p-3 px-4 align-items-center">
                <i className="fas fa-filter text-filter me-1"></i>
                <h5 className="text-body text-filter font-weight-bold me-3">
                  {marketPlace.messages.filter}
                </h5>

                <div className="custom-control custom-checkbox filter-check-box">
                  <img src={passportICon} className="mx-1" alt="" />
                  <input
                    className="custom-control-input visa-checkbox-input-yellow"
                    id={`All-id`}
                    type="checkbox"
                    value={"All"}
                    checked={filter.length === 0 || visaTypes.length === filter.length}
                    onChange={(e) => setFilter([])}
                  />
                  <label
                    className="custom-control-label custom-control-labeler pointer visa-checkbox-label-yellow"
                    htmlFor={`All-id`}
                  >
                    {marketPlace.AllPackages}
                  </label>
                </div>

                {visaTypes?.map((i, index) => {
                  const visaType = i.name;
                  const checked = filter.includes(visaType);

                  return (
                    <div className="custom-control custom-checkbox filter-check-box mt-md-0 mt-2" key={`${visaType}-${index}`}>
                      <img src={passportICon} className="mx-1" alt="passport icon" />
                      <input
                        className="custom-control-input visa-checkbox-input-yellow"
                        id={`${visaType}-id`}
                        type="checkbox"
                        value={visaType}
                        checked={checked}
                        onChange={(e) => onChangeFilter(e, checked)}
                      />
                      <label
                        className="custom-control-label custom-control-labeler pointer visa-checkbox-label-yellow"
                        htmlFor={`${visaType}-id`}
                      >
                        {visaType}
                      </label>
                    </div>
                  );
                })}
              </div>
              {/* packges cards */}
              <div className="pt-3 bg-white">
                {/* visa types list */}
                <div className="visa-types-container">
                  {visaTypes?.map((visa, index) =>
                    visa?.residences.map((residence, ind) =>
                      residence?.package_plans?.map((plan, IN) => {
                        const show = filter.length === 0 || filter.includes(visa.name);
                        return show ? (
                          <VisaTypeCard
                            key={`visa-${visa?.id}-${index}${ind}${IN}`}
                            currency={residence?.provider_currency}
                            visa={visa}
                            residence={residence}
                            packagePlan={plan}
                            index={index}
                            selectVisa={selectVisa}
                            destination={selectedDestination}
                            toggleNote={toggleNote}
                            toggleSomeNationalities={toggleSomeNationalities}
                          />
                        ) : null;
                      })
                    )
                  )}
                </div>
              </div>
            </div>
            :
            <div className='h4 w-100 text-center terms-color font-weight-bold'>
              <span className='text-warning d-block h2 mt-3' >
                <i className="fas fa-exclamation-triangle"></i>
              </span>
              {marketPlace.noPackagesAvailable}
            </div>
          }

          <PackageNote
            isOpen={noteModal.isOpen}
            toggle={() => toggleNote("")}
            note={noteModal.note}
          />
          <PackageNationalitiesModal
            isOpen={someNationalities.isOpen}
            toggle={() => toggleSomeNationalities([])}
            nationalities={someNationalities.nationality}
          />
        </div>
        :
        null
      }

    </>
  )
}


export function VisaTypeCard({
  currency,
  visa,
  index,
  selectVisa,
  packagePlan,
  toggleNote,
  residence,
  toggleSomeNationalities,
}) {
  const { marketPlace, inventory } = Locale;

  return (
    <div className="visa-type-card border" key={`visa-${visa?.id}-${index}`}>
      <div className="visa-type-content">
        <h6 className="font-weight-bold orange-color">{packagePlan?.plan_name}</h6>
        {/* processing time */}
        <div className="w-100 my-1  d-flex align-items-center justify-content-center">
          <img
            src={hourGlassSrc}
            alt="hour glass icon"
            width="14px"
            height="16px"
          />
          <span className="mx-1">{marketPlace.processingTime}</span>
          <span>{residence?.processing_time}</span>
        </div>

        {/* package plan */}
        <div className="visa-type-price">
          <div className="visa-type-pax">
            <Users />
            <span className="mx-1">
              {`${marketPlace.package} (${packagePlan.range_from} - ${packagePlan.range_to}) ${marketPlace.packagePax}`}
            </span>
          </div>
          <span className="text-white mt-2">{inventory.messages.Price}</span>
          <p className="text-white lineHeight1	">
            <span className="h5 font-weight-bold lineHeight1">
              {packagePlan?.package_provider_price}{" "}
            </span>
            <span className="lineHeight1">
              {currency} / {inventory.messages.visa}
            </span>
            {packagePlan?.notes ? (
              <i className="fas fa-info-circle orange-color mx-1"
                role="button"
                onClick={() => toggleNote(packagePlan?.notes)}
              />
            ) : null}
          </p>
        </div>

        <ul className="w-100 px-2 my-2">
          <li
            className="w-100  d-flex align-items-center"
            style={{ marginBottom: "0.25em", lineHeight: 1 }}
          >
            <i className="fas fa-check fa-xs"></i>
            {residence?.all_nationalities ? (
              <span className="mx-1">{marketPlace.allNationalities}</span>
            ) : residence?.nationalities.length > 1 ? (
              <p className="d-flex align-items-center align-items-center">
                <span className="mx-1">{marketPlace.someNationalities}</span>
                <i
                  className="fas fa-eye orange-color"
                  onClick={() =>
                    toggleSomeNationalities(residence?.nationalities)
                  }
                  role="button"
                ></i>
              </p>
            ) : (
              <span className="mx-2">{`${marketPlace.nationality} (${residence?.nationalities?.[0]?.name})`}</span>
            )}
          </li>
          {packagePlan?.visa_include?.map((i) => (
            <li key={i}>
              <i className="fas fa-check fa-xs "></i>
              <span className="mx-1">{i}</span>
            </li>
          ))}
        </ul>

        {/* visa price */}
        <div className="w-100 px-2 mt-auto">
          <button
            className="btn select-visa-btn shadow-none"
            onClick={() => {
              selectVisa({
                visaType: { id: visa?.id, name: visa?.name },
                packagePlan: packagePlan,
                residence: residence,
              });
            }}
          >
            {marketPlace.start}
          </button>
        </div>
      </div>
    </div>
  );
}


const PackageNote = ({ isOpen, toggle, note }) => {
  const { inventory } = Locale;
  return (
    <Modal isOpen={isOpen} centered>
      <ModalHeader toggle={toggle}>
        {inventory.viewReservation.Notes}
      </ModalHeader>
      <ModalBody>{note}</ModalBody>
    </Modal>
  );
};

const PackageNationalitiesModal = ({ isOpen, toggle, nationalities }) => {
  const { marketPlace } = Locale;

  return (
    <Modal isOpen={isOpen} centered size="lg">
      <ModalHeader toggle={toggle}>
        {marketPlace.availableNationalities}
      </ModalHeader>
      <ModalBody className="row m-0">
        {nationalities?.map((item, index) => {
          return (
            <p
              className="d-flex align-items-center my-2 col-4"
              key={item.country_id}
            >
              <i className="fas fa-check fa-xs"></i>
              <span className="mx-2">{item.name}</span>
            </p>
          );
        })}
      </ModalBody>
    </Modal>
  );
};

