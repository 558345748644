import { useParams } from 'react-router-dom';
import TransportUmrahSearchForm from './TransportUmrahForm'; 
import TransferSearchForm from "./TransferSearchForm";

export default function TransferSearch() {
	const { status } = useParams();

	return (
		<>
			{status === "umrah" ?
				<TransportUmrahSearchForm />
				:
				<TransferSearchForm />
			}
		</>
	);
}
