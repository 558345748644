import NumberField from 'components/Form/NumberField/NumberField';
import Locale from 'translations';


export default function ServicesPricing({ pricingForText, servicesList, handleServicesInputsChanges }) {

  const { productsBuilder, packagePlanner ,marketPlace} = Locale;

  return (
    <div className="mb-3">
      <table className="table services-pricing-table">
        <thead>
          <tr>
            <td rowspan="2" colSpan="2" width="250px" className="t-head">
              <div className="t-head"> {marketPlace.ServiceType}</div>
            </td>
            <th colSpan="3" scope="colgroup">
              <div className="t-head">
                {packagePlanner.totalPackagePriceFor} <span className="text-uppercase">{pricingForText}</span>
              </div>
            </th>
          </tr>
          <tr>
            <th scope="col">
              <div className="t-sub">{packagePlanner.pricePerAdult}</div>
            </th>
            <th scope="col">
              <div className="t-sub">{packagePlanner.pricePerChild}</div>
            </th>
            <th scope="col">
              <div className="t-sub">{packagePlanner.pricePerInfant}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {servicesList?.map((service, index) => {
            return (
              <tr key={service?.id}>
                <td colSpan="2">
                  <div className="t-sub text-start text-capitalize">
                    {service.item.id === 1 ? productsBuilder.externalTransportations : service.item.id === 2?productsBuilder.internalTranspor:service.item.name}
                  </div>
                </td>
                <td>
                  <NumberField
                    hasLabel={false}
                    removeArrow={true}
                    value={service?.itemable?.price_adult || ""}
                    onChange={(e) => handleServicesInputsChanges({
                      key: "price_adult",
                      value: e.target.value,
                      roomIndex: null,
                      serviceId: service?.id
                    })}
                  />
                </td>
                <td>
                  <NumberField
                    hasLabel={false}
                    removeArrow={true}
                    value={service?.itemable?.price_child || ""}
                    onChange={(e) => handleServicesInputsChanges({
                      key: "price_child",
                      value: e.target.value,
                      roomIndex: null,
                      serviceId: service?.id
                    })}
                  />
                </td>
                <td>
                  <NumberField
                    hasLabel={false}
                    removeArrow={true}
                    value={service?.itemable?.price_infant || ""}
                    onChange={(e) => handleServicesInputsChanges({
                      key: "price_infant",
                      value: e.target.value,
                      roomIndex: null,
                      serviceId: service?.id
                    })}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
