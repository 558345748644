import { useState } from "react";
import { Link } from "react-router-dom";
import Locale from "translations";
import PromocodeModal from "./promocodeModal ";
import { ReactComponent as EyeIcon } from 'assets/images/crm/eye.svg'


export default function TableHotelContent({
	productItem,
	getReceipt,
	getVoucher,
	toggleModal,
	setBrn,
	togglePayModal,
	modalPay,
	setPayMethod,
	togglePromocodeModal,
	modalPromocode,
	cancel,
	setCancel,
	payMethod,
	setProductItemState,
}) {
	const { inventory } = Locale;
	const [id, setId] = useState(null);
	const [idPromo, setIdPromo] = useState(null);
	/************************** */
	const [promoOrVoucherState, setPromoOrVoucherState] = useState(null);
	const [promoState, setPromoState] = useState(null);

	// useEffect(() => {
	// 	const Test = async () => {
	// 		const res = await getPromoVoucher({
	// 			brn: productItem?.brn,
	// 		});

	// 		setPromoOrVoucherState(res?.data?.voucherCode?.files);
	// 		setPromoState(res?.data?.voucherCode?.code);
	// 	};
	// 	Test();
	// }, []);
	// const [tooltip, setTooltip] = useState(false);

	const copyCodeToClipboard = () => {
		const text = document.getElementById("text").innerText;
		var textField = document.createElement("textarea");
		textField.innerText = text;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand("copy");
		textField.remove();
	};
	return (
		<>
			<tr
				className="all-txt-table"
				key={productItem?.id}
				/* 			onClick={() => {
				if (+productItem?.gds != 4) {
					history.push({
						pathname: `/inventory/${productItem?.id}`,
						state: {
							tab: "hotel",
							hotel: productItem?.hotel_name,
							promo: promoState,
							voucher: promoOrVoucherState,
							productItems: productItem,
						},
					});
				} else {
					history.push({
						pathname: `/Hotels/inventory/${productItem?.id}`,
					});
				}
			}} */
			>
				<td className="text-break set-w-11">{productItem?.brn}</td>
				<td className="text-break set-w-11">{productItem?.reference_number}</td>
				<td className="set-w-11">
					<div className="fix-title">{productItem?.hotel_name}</div>
				</td>
				{/* 		<td>{productItem?.room_type}</td> */}
				<td>{productItem?.rooms_number}</td>
				{/* 			<td>
					<div class="progress">
						<div
							class="progress-bar bg-success"
							role="progressbar"
							style={{
								width:
									`${
										+productItem?.availablity / +productItem?.total_availablity
									}` *
										100 +
									`%`,
							}}
						>
							{productItem?.availablity}/{productItem?.total_availablity}
						</div>
					</div>
				</td> */}
				<td>{productItem?.created_at}</td>
				<td>{productItem?.start_date}</td>
				<td>{productItem?.nights_count}</td>
				<td>
					{productItem?.total_price} {productItem?.currency}
				</td>
				{/* 		<td>{productItem?.room_type}</td> */}
				<td>{productItem?.availablity}</td>
			
				<td>
					<div className="method-pay ">
						{productItem?.gds === 12 && productItem?.reservation_status === "pending" ? 
							<p className="btn-unpublished px-3 text-white bg-warning rounded text-center">
								{inventory.messages.pending}
							</p> 
						: 
						
						productItem?.payment_type === "deposit" &&
						productItem?.payment_status !== "confirmed" &&
						productItem?.reservation_status !== "canceled" &&
						productItem?.reservation_status !== "expired" &&
						productItem.reservation_status !== "rejected" ? (
							<button
								className="btn btn-primary btn-unpublished px-2 border-0 "
								onClick={(e) => {
									setId(productItem?.id);
									togglePayModal(!modalPay);
									setPayMethod("deposit");
									//setBrn(productItem.brn);
								}}
							>
								{inventory.messages.PayDeposit}
							</button>
						) : productItem?.payment_type === "full payment" &&
						  productItem?.payment_status !== "confirmed" &&
						  productItem?.reservation_status !== "canceled" &&
						  productItem?.reservation_status !== "expired" &&
						  productItem.reservation_status !== "rejected" ? (
							<button
								className="btn btn-primary btn-unpublished px-2 border-0 "
								onClick={(e) => {
									setId(productItem?.id);
									togglePayModal(!modalPay);
									setPayMethod("full payment");
									//setBrn(productItem.brn);
									setProductItemState(productItem);
								}}
							>
								{inventory.messages.PayFullAmount}
							</button>
						) : productItem?.payment_status === "confirmed" ? (
							<>
								{(productItem?.payment_type === "full payment" ||
									productItem?.payment_type === null) &&
								productItem.gds != 4 ? (
									// productItem?.voucher_code?.files != null &&
									// productItem?.reservation_status != "canceled" &&
									// productItem?.reservation_status != "expired" &&
									// productItem.reservation_status != "rejected" &&
									// productItem.reservation_status == "confirmed" &&
									productItem.reservation_type === "auto" &&
									productItem.reservation_status === "paid" ? (
										<>
											{/* <a
												className="bg-primary text-white rounded text-center p-1 font-weight-bold w-100 d-block "
												//href={productItem?.voucher_code?.files[0]}
												download
												target="_blank"
												onClick={() => {
													setIdPromo(productItem?.id);
													togglePromocodeModal(!modalPromocode);
												}}
											>
												{" "}
												{inventory.messages.download}
												<i class="fas fa-download mx-1"></i>
											</a> */}

											<p className="font-weight-bold text-white bg-success rounded  text-center btn-unpublished  px-3">
												{inventory.messages.Paid}
											</p>
										</>
									) : // productItem?.voucher_code?.files == null &&
									// productItem?.reservation_status != "canceled" &&
									// productItem?.reservation_status != "expired" &&
									// productItem.reservation_status != "rejected" &&
									productItem.reservation_type === "manual" &&
									  productItem.reservation_status === "paid" ? (
											productItem.gds != 6 && productItem.gds != 9 && productItem.gds != 11 ?	<p className="bg-primary text-white rounded text-center btn-unpublished px-3 ">
											{inventory.messages.waitVoucher}
										</p>:<p className="bg-success text-white rounded text-center btn-unpublished px-3 ">
											{productItem.reservation_status}
										</p>
									) : //productItem?.voucher_code != null &&
									productItem.reservation_type === "manual" &&
									  productItem.reservation_status === "confirmed" ? (
										<p className="bg-success text-white rounded text-center btn-unpublished px-3 ">
											{inventory.messages.Paid}
										</p>
									) : (
										<p className="bg-danger text-white rounded text-center btn-unpublished px-3 ">
											{productItem.gds != 6 && productItem.gds != 5
												? inventory.messages.Canceled
												: inventory.messages.Canceled}
										</p>
									)
								) : (
									<>
										{productItem?.voucher_code?.code != null ? (
											<button
												className="btn  bg-primary text-white rounded text-center btn-unpublished px-3  "
												onClick={() => {
													setIdPromo(productItem?.id);
													togglePromocodeModal(!modalPromocode);
												}}
											>
												{" "}
												<span id="text">
													{/* {promoState} */}
													{productItem?.voucher_code?.code}
												</span>
												<i
													class="far fa-clone mx-1"
													onClick={() => copyCodeToClipboard()}
													style={{ cursor: "pointer" }}
												></i>
											</button>
										) : (
											""
										)}
										{productItem?.voucher_code?.code != null ? (
											<>
												{/* <span id="text " className="text-primary">
													{productItem?.voucher_code?.code}
												</span> */}
												{/* <Tooltip
													placement="bottom"
													isOpen={tooltip}
													autohide={false}
													toggle={() => setTooltip(!tooltip)}
													target={`tooltip-${productItem?.brn}`}
													style={{ background: "white", color: "#83878b" }}
												>
													{"Copy.."}
												</Tooltip> */}
											</>
										) : productItem.gds == 4 ? (
											productItem.reservation_status === "paid" ? (
												<p className="btn-unpublished px-3 text-white bg-success rounded  text-center ">
													{inventory.messages.Paid}
												</p>
											) : (
												<p
													className={` text-center btn-unpublished px-3 ${
														productItem.reservation_status === "canceled"
															? "text-white bg-danger  rounded"
															: ""
													}`}
												>
													{productItem.reservation_status === "canceled"
														? inventory.messages.Canceled
														: productItem.reservation_status === "approved" &&
														  productItem.payment_status === "pending"
														? inventory.messages.waitVoucher
														: productItem.reservation_status}
												</p>
											)
										) : (
											<a
												className="bg-primary text-white rounded text-center btn-unpublished px-3 d-block "
												//href={productItem?.voucher_code?.files[0]}
												download
												target="_blank"
												onClick={() => {
													setIdPromo(productItem?.id);
													togglePromocodeModal(!modalPromocode);
												}}
											>
												{" "}
												{inventory.messages.download}
												<i className="fas fa-download mx-1"></i>
											</a>
										)}
									</>
								)}
							</>
						) : productItem?.payment_status === "paid" ? (
							<p className="btn-unpublished px-3 text-white bg-success rounded text-center">
								{inventory.messages.Paid}
							</p>
						) : productItem?.reservation_status === "canceled" ? (
							<p className="btn-unpublished px-3 text-white bg-danger  rounded">
								{inventory.messages.Canceled}
							</p>
						) : productItem?.reservation_status === "expired" ? (
							<p className="btn-unpublished px-3 text-white bg-danger  rounded">
								{inventory.messages.expiredResevation}
							</p>
						) : productItem.reservation_status === "rejected" ? (
							<p className="btn-unpublished px-3 text-white bg-danger  rounded">
								{inventory.messages.Rejected}
							</p>
						) : productItem.gds == 4 || productItem.gds == 3 || productItem?.gds === 9 || productItem?.gds === 11 &&
						  productItem.reservation_status === "approved" &&
						  productItem.payment_status === "pending" ||  productItem.gds == 6 &&
						  productItem.reservation_status === "approved" &&
						  productItem.payment_status === "pending" ? (
							<button
								className="btn btn-primary btn-unpublished px-2 border-0 "
								onClick={(e) => {
									setId(productItem?.id);
									togglePayModal(!modalPay);
									setPayMethod("full payment");
									// //setBrn(productItem.brn);
									setProductItemState(productItem);
								}}
							>
								{inventory.messages.waitForPayment}{" "}
							</button>
						) : (
							<p className="btn-unpublished px-3 text-white bg-warning rounded text-center">
								{inventory.messages.pending}
							</p>
						)}
					</div>
				</td>
						<td>{productItem?.source}</td>
				<td>
					{+productItem?.gds != 4 ? (
						<Link
							className="text-yellow d-flex align-items-center"
							to={{
								pathname: `/Hotels/inventory/${productItem?.id}`,
								state: {
									tab: "hotel",
									hotel: productItem?.hotel_name,
									promo: promoState,
									voucher: promoOrVoucherState,
									productItems: productItem,
								},
							}}
						>
							<EyeIcon />{inventory.messages.view}
							{/* 							<img src={openproduct} alt="" srcset="" />
							 */}{" "}
						</Link>
					) : (
						<Link
							className="text-yellow d-flex align-items-center"
							to={`/Hotels/inventory/${productItem?.id}`}
						>
							{/* <i class="fas fa-angle-right"></i> */}
								<EyeIcon /> {inventory.messages.view}
							{/* 							<img src={openproduct} alt="" srcset="" />
							 */}{" "}
						</Link>
					)}
				</td>

				{/* <td>
					{productItem.payment_status ? (
						<span className="btn-success">
							{inventory.messages[productItem.reservation_status]}
						</span>
					) : (
						<span className="btn-warning">Pending</span>
					)}
				</td> */}
			</tr>
			{/* {id == productItem?.id ? (
				<>
					<PayModal
						modalPay={modalPay}
						togglePayModal={togglePayModal}
						title={payMethod}
						setCancel={setCancel}
						cancel={cancel}
						productItem={productItem}
					/>
				</>
			) : (
				""
			)} */}
			{idPromo == productItem?.id ? (
				<PromocodeModal
					modalPromocode={modalPromocode}
					togglePromocodeModal={togglePromocodeModal}
					productItem={productItem}
					promoState={productItem?.voucher_code?.code}
					promoOrVoucherState={productItem?.voucher_code?.code}
				/>
			) : (
				""
			)}
		</>
	);
}
