import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import {
	useMarketplaceDispatch,
	useMarketplaceState,
} from "context/marketplace";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { fetchAirPorts, fetchFlights } from "services/marketplace";
import Locale from "translations";

export default function MultiDestination({
	Options,
	setListAirlines,
	listAirlines,
	getListAirlines,
	modify,
	closeModal,
	setOptionsChildren,
}) {
	const { marketPlace, inventory, messages } = Locale;
	const history = useHistory();
	const { locale } = useSBSState();
	const [flights, setFlights] = useState({ children:0,airLegs: [{}, {}] });
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const dispatch = useMarketplaceDispatch();
	const { flightsSearch } = useMarketplaceState();

	const checkFormErrors = () => {
		let submitError = {};
		const validationKeys = ["from", "to", "data"];
		flights.airLegs?.forEach((guest, guestIndex) => {
			validationKeys.forEach((key) => {
				submitError = {
					...submitError,
					...validate(
						{ name: [key + guestIndex], value: guest[key] },
						{ required: true }
					),
				};
			});
		});
		setErrors({
			...submitError,
			...errors,
			...validate(
				{ name: "adults", value: flights.adults },
				{ required: true }
			),
			...validate(
				{ name: "cabinClass", value: flights.cabinClass },
				{ required: true }
			)
		});
	};

	const handleSearch = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	useEffect(() => {
		if (modify && flightsSearch) {
			// debugger
			if(flightsSearch.type === "3"){

				flightsSearch.airLegs.forEach((i) => {
					i.data = moment(i.data);
				});
			}else{
				flightsSearch.airLegs = [{}, {}] 
			}
			
			setOptionsChildren(+flightsSearch?.adults)

			setFlights(flightsSearch);
		}

		return () => {};
	}, []);

	useEffect(() => {
		async function handleBranches() {
			if (isFormValid(errors)) {
				let data = {
					adults: flights.adults,
					children: flights.children,
					airline: flights.airline?.id,
					airLegs: flights.airLegs.map((res) => {
						return {
							cabinClass: flights.cabinClass?.value,
							departureDate: moment(res.data).format("YYYY-MM-DD"),
							origin: res?.from?.code,
							destination: res?.to?.code,
						};
					}),
				};

				const response = await fetchFlights(data);
				if (response.status === 200) {
					const data = response.data;

					if (response.data.data.length > 0) {
						dispatch({
							type: "flightsSearchResults",
							payload: data,
						});
						dispatch({
							type: "saveFlightsSearch",
							payload: { ...flights, cacheKey: data?.cacheKey, type: "3" },
						});
						dispatch({
							type: "outboundFlight",
							payload: null,
						});
						dispatch({
							type: "inboundFlight",
							payload: null,
						});
						if (modify) {
							closeModal();
							history.location.pathname === "/inbound-view" &&
								history.push("/outbound-view");
						} else {
							history.push("/outbound-view");
						}
					} else {
						store.addNotification({
							title: messages.noResults,
							message: messages.noSearchResults,
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
						});
					}
				}
			}
		}
		handleBranches();
	}, [isErrorLoaded]);

	let goingToPrevValue = useRef(null);

	function clearSearchText(e, key) {
		goingToPrevValue.current = flights[key];
		const value = e.target.value;
		if (value.length > 0) {
			setFlights({ ...flights, [key]: null });
		}
	}

	function setAirLegs(value, index, name) {
		let x = [...flights.airLegs];
		x[index] = { ...x[index], [name]: value };
		setFlights({ ...flights, airLegs: x });
	}
	
	// AutoCompleteField
	const [listAuto, setListAuto] = useState({});
	const getListAuto = async (inputValue, index, key) => {
		if (inputValue.length > 2) {
			const Airlines = await fetchAirPorts({ search: inputValue });
			let result =[]
			Object.keys(Airlines).map(res=>{
				Airlines[res].map((item) => {
					result.push(	 {
							...item,
							name: res!=="" ?  item.names[locale]:item.names[locale],
							value: item.id,
							label: res!=="" ?  item.names[locale]:item.names[locale],
						})
				});
			})
			setListAuto({ ...result, [index + "AirPort" + key]: result });
		}
	};

	return (
		<>
			<div className="flight-search">
				{flights.airLegs && flights.airLegs.length > 0
					? flights.airLegs.map((res, index) => {
							return (
								<div className="col-md-10 d-flex m-auto ">
									<div className="col-md-4 p-0 fromflight">
										<AutoCompleteField
											hasLabel={true}
											label={marketPlace.From}
											isSearchable={true}
											placeholder={marketPlace.origin}
											listAuto={listAuto[index + "AirPortForm"]}
											setListAuto={setListAuto}
											getListAuto={(e) => getListAuto(e, index, "Form")}
											value={res?.from?.name || ""}
											// flag={flights.from?.country?.flag}
											onFocus={(e) => clearSearchText(e, "from")}
											onBlur={() =>
												setFlights({
													...flights,
													from: goingToPrevValue.current,
												})
											}
											onChange={(e) => {
												setAirLegs({ name: e }, index, "from");
											}}
											onSelectValue={(e) => {
												setAirLegs(e, index, "from");

												setErrors({
													...errors,
													...validate(
														{ name: [`from${index}`], value: e },
														{ required: true }
													),
												});
											}}
											color={errors[`from${index}`]?.required ? "danger" : ""}
											errors={errors[`from${index}`]}
										/>
										<i class="fas fa-exchange-alt"></i>
									</div>
									<div className="col-md-4 p-0  toflight">
										<AutoCompleteField
											hasLabel={true}
											label={marketPlace.To}
											isSearchable={true}
											placeholder={marketPlace.messages.destination}
											listAuto={listAuto[index + "AirPortTO"]}
											setListAuto={setListAuto}
											getListAuto={(e) => getListAuto(e, index, "TO")}
											value={res?.to?.name || ""}
											// flag={flights.from?.country?.flag}
											onFocus={(e) => clearSearchText(e, "to")}
											onBlur={() =>
												setFlights({
													...flights,
													to: goingToPrevValue.current,
												})
											}
											onChange={(e) => {
												setAirLegs({ name: e }, index, "to");
											}}
											onSelectValue={(e) => {
												setAirLegs(e, index, "to");
												setErrors({
													...errors,
													...validate(
														{ name: [`to${index}`], value: e },
														{ required: true }
													),
												});
											}}
											color={errors[`to${index}`]?.required ? "danger" : ""}
											errors={errors[`to${index}`]}
										/>
									</div>
									<div className="col-md-3 ms-3">
										<DatePickerField
											label={marketPlace.Depart}
											placeholder={"DD/MM/YYYY"}
											onChangeDate={(date) => {
												setAirLegs(date, index, "data");
												setErrors({
													...errors,
													...validate(
														{ name: [`data${index}`], value: date },
														{ required: true }
													),
												});
											}}
											isOutsideRange={(day) => {
												return !day.isAfter(moment().add(-1, "d"), "day");
											}}
											date={res.data}
											color={errors[`data${index}`]?.required ? "danger" : ""}
											errors={errors[`data${index}`]}
										/>
									</div>
								</div>
							);
					  })
					: ""}

				<div className="col-md-10 d-flex m-auto">
					<div className="col-md-4">
						<SelectField
							label={inventory.messages.adults}
							placeholder={marketPlace.messages.select}
							options={Options.adults}
							value={flights.adults}
							onChange={(e) => {
								setFlights({
									...flights,
									adults: e.value,
									children:0
								});
								setOptionsChildren(e.value)

								setErrors({
									...errors,
									...validate(
										{ name: "adults", value: e.value },
										{ required: true }
									),
								});
							}}
							onBlur={() =>
								setErrors({
									...errors,
									...validate(
										{
											name: "adults",
											value: flights.adults,
										},
										{ required: true }
									),
								})
							}
							color={errors.adults?.required ? "danger" : ""}
							errors={errors.adults}
						/>
					</div>
					<div className="col-md-4">
						<SelectField
							label={inventory.messages.children}
							placeholder={marketPlace.messages.select}
							options={Options.Children}
							value={flights?.children==0?flights?.children.toString():flights?.children}
							onChange={(e) => {
								setFlights({
									...flights,
									children: e.value,
								});

							
							}}
							
							color={errors.children?.required ? "danger" : ""}
							errors={errors.children}
						/>
					</div>
					<div className="col-md-4">
						<SelectField
							label={marketPlace.class}
							placeholder={marketPlace.messages.select}
							options={Options.classes}
							value={flights?.cabinClass?.name}
							onChange={(e) => {
								setFlights({
									...flights,
									cabinClass: e,
								});
								setErrors({
									...errors,
									...validate(
										{ name: "cabinClass", value: e },
										{ required: true }
									),
								});
							}}
							onBlur={() =>
								setErrors({
									...errors,
									...validate(
										{
											name: "cabinClass",
											value: flights.cabinClass,
										},
										{ required: true }
									),
								})
							}
							color={errors.cabinClass?.required ? "danger" : ""}
							errors={errors.cabinClass}
						/>
					</div>
				</div>
				<div className="col-md-10 d-flex m-auto">
					<div className="col-md-6">
						<AutoCompleteField
							hasLabel={true}
							label={marketPlace.airlines}
							placeholder={marketPlace.airlines}
							listAuto={listAirlines}
							setListAuto={setListAirlines}
							getListAuto={getListAirlines}
							value={flights?.airline?.name || ""}
							// flag={flights.airline?.country?.flag}
							onFocus={(e) => clearSearchText(e, "airline")}
							onBlur={() =>
								setFlights({
									...flights,
									airline: goingToPrevValue.current,
								})
							}
							onChange={(e) => {
								setFlights({
									...flights,
									airline: { name: e },
								});
							}}
							onSelectValue={(e) => {
								setFlights({
									...flights,
									airline: e,
								});
								setErrors({
									...errors,
									...validate(
										{ name: "airline", value: e },
										{ required: false }
									),
								});
							}}
							color={errors?.airline?.required ? "danger" : ""}
							errors={errors?.airline}
						/>
					</div>
				</div>
				<div className="col-md-10 m-auto">
					<div className="full-input search-button">
						<div className="full-input search-button">
							<button
								onClick={() => {
									// history.push("/outbound-view");
									handleSearch();
								}}
								className="btn w-100 bg-nxt mb-3 mt-3"
								type="button"
							>
								{marketPlace.messages.search}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
