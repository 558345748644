import arrowRight from 'assets/images/icons/arrow-right-icons.svg';
import visaToken from "assets/images/printvisa/visa.png";
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import NumberField from 'components/Form/NumberField/NumberField';
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import {
	useMarketplaceDispatch,
	useMarketplaceState
} from "context/marketplace";
import moment from 'moment';
import { useEffect, useState } from "react";
import { store } from 'react-notifications-component';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { editTransportationRequest, sendRequestPassengers, splitRequestPassengers, viewTransportationRequest } from "services/transportation";
import Locale from "translations";
import AddPassengers from "./components/AddPassengers";
import ListHeader from "./components/ListHeader";
import CustomModal from "./components/Modal";
import MRZ from "./components/MRZ";
import useShowPermission from 'hooks/useShowPermission';
import AddNewPassengerModal from "./components/AddNewPassenger";

export default function TransportationListPassengers() {
	const { transportationSearch, transportationPassengers } = useMarketplaceState();
	let history = useHistory();
	const location = useLocation();
	const dispatch = useMarketplaceDispatch();
	const { onlineVisa, productsBuilder, success,inventory,landing } = Locale;

	const [ModalAddGroupMembers, setModalAddGroupMembers] = useState(false);
	// const tokenSafaVisa = localStorage.getItem("tokenSafaVisa");
	// const loginData = JSON.parse(localStorage.getItem("loginData"));
	const { status, id } = useParams();

	const [ModalNewMember, setModalNewMember] = useState(false);
	const [searchVal, setSearchVal] = useState(null);
	const [searching, setSearching] = useState(false);
	const { locale, userInfo  } = useSBSState();

	const [sendTravelersModal, setSendTravelersModal] = useState(false);
	const [checkedIds, setCheckedIds] = useState([]);
	const [passengersList, setPassengersList] = useState([]);
	const [serverPassengersList, setServerPassengersList] = useState([])
	const [transportationDates, setTransportationDates] = useState(
		{
			departure_date: null,
			return_date: null,
		}
	)
	const [splitCount, setSplitCount] = useState(0);
	const [modalSplit, setModalSplit] = useState(false);
	const [downloadList, setDownloadList] = useState([]);

	const inventoryPermission = useShowPermission({
		permission: [
			"View-Inventory-Transfer",
			"Manage-Inventory-Transfer",
		],
	});

	const search = (value) => {
		setSearching(true);
		if (transportationPassengers.length > 0 && value) {
			let filteredData = [];
			for (let index = 0; index < transportationPassengers.length; index++) {
				for (const key in transportationPassengers[index]) {
					if (
						transportationPassengers[index][key]
							?.toString()
							.includes(value?.toLowerCase()) ||
						transportationPassengers[index][key]
							?.toString()
							.includes(value?.toUpperCase())
					) {
						filteredData.push(transportationPassengers[index]);
						break;
					}
				}
			}
			setPassengersList(filteredData);
			setSearching(false);
		} else {
			setPassengersList(transportationPassengers);
			setSearching(false);
		}
	};

	const Clearsearch = () => {
		setPassengersList(transportationPassengers);
		setSearchVal("");
	};

	const toggleModelAddGroupMembers = () => {
		setModalAddGroupMembers(!ModalAddGroupMembers);
	};

	const toggleModelNewMember = () => {
		setModalNewMember(!ModalNewMember);
	};

	const toggleModelSplit = () => {
		setModalSplit(!modalSplit);
	}
	const toggleModelSendTravelers = () => {
		setSendTravelersModal(!sendTravelersModal);
	}

	const addSelectedPassangers = (data) => {
		setPassengersList([...passengersList, ...data]);
		dispatch({
			type: "transportationPassengers",
			payload: data.map(passenger => {
				return {
					...passenger,
					national_id: passenger?.nationality_id?.toString(),
					issue_country: passenger?.national_id?.toString() || passenger?.nationality_id?.toString(),
					first_name_ar: passenger?.full_name_ar?.split(" ")[0],
					father_name_ar: passenger?.full_name_ar?.split(" ")[1],
					grandfather_name_ar: passenger?.full_name_ar?.split(" ")[2],
					last_name_ar: passenger?.full_name_ar?.split(" ")[3],
					first_name_en: passenger?.full_name_en?.split(" ")[0],
					father_name_en: passenger?.full_name_en?.split(" ")[1],
					grandfather_name_en: passenger?.full_name_ar?.split(" ")[2],
					last_name_en: passenger?.full_name_en?.split(" ")[3],
				}
			}),
		});
		toggleModelAddGroupMembers();
	};

	const removePassanger = (id) => {
		let newFIlteredPassanger = transportationPassengers.filter(
			(item) => id !== item.id && id !== item.uniqueIdFrontend
		);

		setPassengersList(newFIlteredPassanger);
		dispatch({
			type: "transportationPassengersRemove",
			payload: newFIlteredPassanger,
		});
		let newCheckedIds = checkedIds.filter((item) => item != id);
		setCheckedIds(newCheckedIds);
		setSearchVal("");
	};

	function handelCheck(passenger, checked) {
		if (checked) {
			setDownloadList([...downloadList, passenger.id])
		} else {
			setDownloadList(downloadList.filter((item) => item != passenger.id));
		}
	}
	function handelCheckAll(checked) {
		if (checked) {
			setDownloadList(serverPassengersList.map(passenger => passenger.id));
		} else {
			setDownloadList([]);
		}
	}


	const allPassengers =
		passengersList?.length > 0 && status !== "edit" && status !== "view"
			? passengersList?.map((item) => {
				return (
					<tr>
						<td className="d-flex align-items-center">
							<input
								type="checkbox"
								checked={downloadList.includes(item.id)}
								className="mx-1 insert-group-checkbox "
								onChange={(e) => {
									handelCheck(item, e.target.checked);
								}}
							/>
							{item.passport_number}
						</td>
						<td>
							{locale === "en"
								? item.name_en
									? item.name_en
									: item.full_name_en
								: item.name_ar
									? item.name_ar
									: item.full_name_ar}
						</td>
						<td>{item.gender}</td>
						<td>{item.birth_date}</td>
						<td>{item.age}</td>
						<td>
							{item.relationship && item.relationship[locale]
								? item.relationship[locale]
								: item.relationship
									? item.relationship
									: ""}
						</td>
						<td>{item.national_id}</td>
						<td>{item.nationality}</td>
						{status !== "view" ? (
							<td>
								<i className="fas fa-trash-alt text-danger pointer"
									onClick={() => {
										removePassanger(
											item.id ? item.id : item.uniqueIdFrontend
										);
									}}
								></i>
							</td>
						) : null
						}
						<td>
							{status === "edit" &&
								<span className='pointer'>
									<img src={arrowRight} alt="" onClick={() => {
										dispatch({
											type: "transportationEditPassenger",
											payload: item,
										});
										history.push("/transportation/Edit/Passenger");
									}}
									/>
								</span>
							}
						</td>
					</tr >
				);
			})
			: [];

	const allPassengersEdit =
		(passengersList?.length > 0 && status === "edit") || status === "view"
			? passengersList?.map((item) => {
				return (
					<tr>
						<td className="d-flex align-items-center">
							<input
								type="checkbox"
								checked={downloadList.includes(item.id)}
								className="mx-1 insert-group-checkbox "
								onChange={(e) => {
									handelCheck(item, e.target.checked);
								}}
							/>
							{item.passport_number}
						</td>
						<td>{locale === "ar" ? item.full_name_ar : item.full_name_en}</td>
						<td>{item.gender}</td>
						<td>{item.date_of_birth}</td>
						<td>{item.age}</td>
						<td>{item.relationship ? item.relationship[locale] : ""}</td>
						<td>{item.national_id}</td>
						<td>{item.nationality}</td>
						{status !== "view" ?
							<td>
								<i className="fas fa-trash-alt text-danger pointer" onClick={() => {
									removePassanger(item.id ? item.id : item.uniqueIdFrontend);
								}}
								></i>
							</td>
							:
							<td></td>
						}
						<span className='pointer'>
							{status === "edit" &&
								<img src={arrowRight} alt="" onClick={() => {
									dispatch({
										type: "transportationEditPassenger",
										payload: item,
									});
									history.push("/inventory/transportation/Edit/Passenger");
								}}
								/>
							}
						</span>
					</tr>
				);
			})
			: [];


	const fetchTransportationRequest = async (saveNewTravelers) => {
		if (location.state !== 'editPassenger' || saveNewTravelers) {
			const response = await viewTransportationRequest(id);
			if (response.status === 200 || response.status === 201) {
				let searchKeys = response.data.data;
				dispatch({
					type: "transportationSearch",
					payload: {
						destination: searchKeys?.destination,
						destination_id: searchKeys?.destination_id,
						vehicle_type: searchKeys?.vehicle_type,
						seats_count: searchKeys?.seats_count,
						vehicle_model: searchKeys?.vehicle_model,
						vehicle_count: searchKeys?.vehicle_count,
						departure_date: searchKeys?.departure_date,
						return_date: searchKeys?.return_date,
						requestDate: searchKeys?.request_date,
						expiration_date: searchKeys?.expiration_date,
						status: searchKeys?.status || null,
						residence: {
							price: searchKeys?.price_per_one,
							currency_code: searchKeys?.currency,
							id: searchKeys?.residence?.id,
							value: searchKeys?.residence?.id,
							name: searchKeys?.residence?.name,
							label: searchKeys?.residence?.name,
						},
						cycle_type: searchKeys?.cycle_type,
					},
				});
				dispatch({
					type: "transportationPassengersRemove",
					payload: searchKeys?.passengers,
				});
				setPassengersList(searchKeys.passengers);
				setServerPassengersList(searchKeys.passengers)
			}
		} else {
			setPassengersList(transportationPassengers)
		}
	};
	// start edit
	useEffect(() => {
		if (status === "edit" || status === "view") {
			fetchTransportationRequest(false);
		}
	}, [status]);
	useEffect(() => {
		setCheckedIds(transportationPassengers.map((item) => item.id ? item.id : item.uniqueIdFrontend));
		if (status) {
			dispatch({ type: "redirectURL", payload: location.pathname })
		}
		return () => dispatch({ type: "redirectURL", payload: location.pathname })
	}, [transportationPassengers])


	function successfulRequest(response, redirect) {
		store.addNotification({
			title: "Success!",
			message: response?.data?.message || "Request success !",
			type: "success",
			insert: "top",
			container: "top-right",
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: {
				duration: 5000,
				onScreen: true,
				pauseOnHover: true,
			},
		});
		if (redirect) {
			history.push(inventoryPermission?"/inventory/transfer":"/statistics");
			dispatch({
				type: "transportationPassengersRemove",
				payload: [],
			});
		}
	}

	async function splitTravelers() {
		const response = await splitRequestPassengers(id);
		if (response.status === 200) {
			successfulRequest(response, true);
		}
	}

	async function saveTravalers() {
		const data = {
			passengers: passengersList.map(passenger => {
				return {
					...passenger,
					national_id: passenger?.nationality_id?.toString(),
					issue_country: passenger?.issue_country_label?.toString() || passenger?.nationality?.toString(),
					gender: passenger?.gender?.toLowerCase(),
					passport_number: passenger?.passport_number?.toString(),

					first_name_ar: passenger?.full_name_ar?.split(" ")[0],
					father_name_ar: passenger?.full_name_ar?.split(" ")[1],
					grandfather_name_ar: passenger?.full_name_ar?.split(" ")[2],
					last_name_ar: passenger?.full_name_ar?.split(" ")[3],

					first_name_en: passenger?.full_name_en?.split(" ")[0],
					father_name_en: passenger?.full_name_en?.split(" ")[1],
					grandfather_name_en: passenger?.full_name_ar?.split(" ")[2],
					last_name_en: passenger?.full_name_en?.split(" ")[3],
				}
			})
		}
		const response = await editTransportationRequest(id, data)
		if (response.status === 200) {
			successfulRequest(response, false);
			fetchTransportationRequest(true);
		}
	}

	async function sendTravelers() {
		const data = {
			request_id: id,
			departure_date: moment(transportationDates?.departure_date).format("YYYY-MM-DD"),
			return_date: moment(transportationDates?.return_date).format("YYYY-MM-DD"),
		}
		const response = await sendRequestPassengers(id, data)
		if (response.status === 200) {
			successfulRequest(response, true);
			setSendTravelersModal(false);
		}
	}
	const usedVehicleCount = Math.ceil(serverPassengersList.length / +transportationSearch?.seats_count);
	const remainingVehiclesCount = +transportationSearch?.vehicle_count - usedVehicleCount;

	return (
		<>
			<div className="list-container">
				<div>
					<ListHeader />
				</div>

				<div className="mt-3 bg-white border rounded ">
					<header className="p-3 border-bottom d-flex justify-content-between align-items-center">
						<p className="m-0 font-weight-bold">
							{onlineVisa.Travelers}
							({transportationPassengers?.length}/{" "}{transportationSearch?.vehicle_count * transportationSearch?.seats_count})
						</p>
						{status !== "view" ?
							<button type='button' className="btn bg-nxt m-1"
								disabled={
									serverPassengersList.length === 0
									||
									usedVehicleCount >= transportationSearch?.vehicle_count
								}
								onClick={() => {
									setModalSplit(true);
									setSplitCount(remainingVehiclesCount);
								}}
							>
								{onlineVisa.split}
							</button>
							:
							null
						}
					</header>
					<div className="d-flex border-top px-3 align-items-center justify-content-between List-passengers-header">
						{status !== "view" ?
							<div className="col-4 p-0">
								<button
									className="btn bg-nxt m-1"
									onClick={toggleModelAddGroupMembers}
									disabled={
										transportationPassengers.length === (+transportationSearch?.vehicle_count * +transportationSearch?.seats_count)
										||
										transportationPassengers.length > (+transportationSearch?.vehicle_count * +transportationSearch?.seats_count)
									}
								>
									{onlineVisa.InsertFromGroups}
								</button>
								<button
									className="btn bg-nxt m-1"
									onClick={toggleModelNewMember}
									disabled={
										transportationPassengers.length === (+transportationSearch?.vehicle_count * +transportationSearch?.seats_count)
										||
										transportationPassengers.length > (+transportationSearch?.vehicle_count * +transportationSearch?.seats_count)
									}
								>
									{onlineVisa.AddNew}
								</button>
							</div>
							:
							<div className="col-4"></div>
						}

						<div className="col-6 m-0  justify-content-end d-flex align-items-end py-2 ">
							<div className=" mx-2 w-50">
								<TextField
									label={onlineVisa.search}
									placeholder={onlineVisa.search}
									value={searchVal}
									onChange={(e) => {
										search(e.target.value);
										setSearchVal(e.target.value);
									}}
								/>
							</div>

							<div className="clear text-secondary pointer my-2">
								<button
									className="btn bg-white px-4  onlineVisa-clear-bottom text-gray"
									onClick={() => Clearsearch()}
								>
									<i class="fas fa-undo mx-1"></i>
									{onlineVisa.clear}
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="border">
					<table class="table-update p-2  m-0 bg-white  table table-striped">
						<thead>
							<tr>
								<th>
									<input
										type="checkbox"
										checked={serverPassengersList.length > 0 && serverPassengersList.length === downloadList.length}
										onChange={(e) => {
											handelCheckAll(e.target.checked);
										}}
										className="mx-1 insert-group-checkbox "
									/>
									{onlineVisa.PassportNumber}
								</th>
								<th>{onlineVisa.Name}</th>
								<th>{onlineVisa.Gender}</th>
								<th>
									<i class="fas fa-sort"></i>
									{onlineVisa.Birthday}
								</th>
								<th>{onlineVisa.Age}</th>
								<th>{onlineVisa.Relationship}</th>
								<th>{onlineVisa.NationalId}</th>
								<th>{onlineVisa.Nationality}</th>
								{status !== "view" ? <th>{onlineVisa.actions}</th> : null}
							</tr>
						</thead>
						<tbody>
							{passengersList.length > 0 && !searching ? (
								status === "edit" || status === "view" ? (
									allPassengersEdit
								) : (
									allPassengers
								)
							) : (
								<tr className="">
									<td
										colSpan={9}
										className="text-center online-visa-noResult  "
									>
										<p className="online-visa-noResult-tr">
											{searching ? "" : onlineVisa.noTravelersAdded}
											{searching && (
												<span
													className="spinner-border spinner-border-sm mx-2"
													role="status"
												/>
											)}
										</p>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>

				{status !== "view" ? (
					<footer className=" d-flex  justify-content-between  align-items-center py-4">
						<button type="button" className="btn btn-apply mt-2 px-3 py-2"
							onClick={() => history.push(inventoryPermission?`/inventory/transfer`:"/statistics")}
						>
							{onlineVisa.backTobooking}
						</button>
						{/* save current passengers */}
						<div className="d-flex align-items-center">
							{status !== "view" ? (
								<>
									<button type="button" class="btn  btn-green-300 mt-2 px-3 py-2"
										disabled={
											(transportationSearch?.vehicle_count !== 1 && serverPassengersList.length === 0)
											||
											usedVehicleCount < transportationSearch?.vehicle_count
										}
										onClick={() => {
											setSendTravelersModal(true);
										}}
									>
										{onlineVisa.sendTravelers}
									</button>
									<button type="button" class="btn bg-nxt mt-2 px-3 py-2 mx-2"
										disabled={serverPassengersList.length === passengersList.length || passengersList.length === 0}
										onClick={saveTravalers}
									>
										{productsBuilder.save}
									</button>
								</>
							) : null}
						</div>
					</footer>
				) : null}
			</div>

			<CustomModal
				modalIsOpen={ModalAddGroupMembers}
				header={onlineVisa.InsertFromGroups}
				toggle={toggleModelAddGroupMembers}
				size={userInfo?.is_connected_to_safa_visa ? "xl" : "md"}
				centered={false}
			>
				{userInfo?.is_connected_to_safa_visa ? (
					<AddPassengers
						checkedIds={checkedIds}
						setCheckedIds={setCheckedIds}
						addSelectedPassangers={addSelectedPassangers}
					/>
				) : (
					<>
						<div className="d-flex flex-column justify-content-center align-items-center  ">
							<div>
								<img src={visaToken} alt="" />
							</div>
							<p className="m-0 my-3">{onlineVisa.loginSafaVisaAccount}</p>
							<div className="text-center w-100">
								<button
									className="btn bg-nxt w-75"
									onClick={() => {
										history.push("/visa/login");
									}}
								>
									{landing.login}
								</button>
							</div>
						</div>
					</>
				)}
			</CustomModal>

			{/* split modal */}
			<CustomModal
				modalIsOpen={modalSplit}
				header={onlineVisa.split}
				toggle={toggleModelSplit}
				size={"md"}
				centered={true}
			>
				<div className='d-flex flex-column justify-content-center align-items-center w-75 m-auto py-5'>
					<NumberField
						type={"number"}
						hasLabel={true}
						readOnly={true}
						disabled={true}
						removeArrow={true}
						label={onlineVisa.count}
						placeholder={onlineVisa.enterCount}
						value={splitCount}
						name="split_count"
					/>
					<button type='button' className="btn bg-nxt m-1 w-100"
						onClick={splitTravelers}
					>
						{success.confirm}
					</button>
				</div>
			</CustomModal>

			{/* send travelers modal */}
			<CustomModal
				modalIsOpen={sendTravelersModal}
				toggle={toggleModelSendTravelers}
				header={onlineVisa.sendTravelers}
				size={"md"}
				centered={true}
			>
				<div className='d-flex flex-column justify-content-center align-items-center w-75 m-auto py-3'>
					<div className="col-12">
						<DatePickerField
							label={onlineVisa.departureDate}
							date={transportationDates.departure_date}
							onChangeDate={(departureDate) => {
								setTransportationDates({
									...transportationDates,
									departure_date: departureDate,
									return_date: moment(departureDate) >= moment(transportationDates?.return_date) ? null : transportationDates?.return_date,
								});
							}}
							isOutsideRange={(day) =>
								!day.isAfter(moment(new Date()).add(0, "d"), "day")
							}
						/>
					</div>
					<div className="col-12">
						<DatePickerField
							label={onlineVisa.returnDate}
							date={transportationDates.return_date}
							onChangeDate={(returnDate) => {
								setTransportationDates({
									...transportationDates,
									return_date: returnDate,
									departure_date: moment(returnDate) <= moment(transportationDates?.departure_date) ? null : transportationDates?.departure_date,
								});
							}}
							isOutsideRange={(day) =>
								!day.isAfter(moment(transportationDates?.departure_date).add(0, "d"), "day")
							}
							initialVisibleMonth={() => moment(transportationDates?.departure_date) || null}
						/>
					</div>
					<button type='button' className="btn bg-nxt m-1 w-100"
						disabled={!transportationDates.departure_date || !transportationDates.return_date} 
						onClick={() => sendTravelers()}
					>
						{success.confirm}
					</button>
				</div>
			</CustomModal>

			
			<CustomModal
				modalIsOpen={ModalNewMember}
				header={onlineVisa.AddNew}
				toggle={toggleModelNewMember}
				size={"md"}
				centered={true}
			>
				<AddNewPassengerModal checkedIds={checkedIds} />
				{/* <MRZ checkedIds={checkedIds} /> */}
			</CustomModal>
			{/* <CustomModal
				modalIsOpen={ModalNewMember}
				header={onlineVisa.ScanFromMRZ}
				toggle={toggleModelNewMember}
				size={"md"}
				centered={false}
			>
				<MRZ checkedIds={checkedIds} />
			</CustomModal> */}
		</>
	);
}
