import SelectField from "components/Form/SelectField/SelectField";
import { useEffect, useState } from "react";
import validate, { isFormValid } from "helpers/validate";
import Locale from "translations";
import TextField from "components/Form/TextField/TextField";
import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import { useSBSState } from "context/global";
import DateTimePickerField from "components/Form/DateTimePickerField/DateTimePickerField";
import { fetchPorts, fetchTransporters } from "services/lookups";
import { GenerateFlights, addTripStatements } from "services/operationStatement";
import { store } from "react-notifications-component";
import { useParams, useLocation } from "react-router-dom";
import moment from "moment";
import GenerateSeviceModal from '../Modals/generateServiceModal';
import { formatViewOperationStatement } from 'modules/UmrahOperations/helper/formatUmrahOperations';
import { getOperationsLookup } from 'modules/UmrahOperations/helper/operationsLookup';

export default function ArrivalDeparture({
	operationHasSent,
	OperationStatement,
	setOperationStatement,
	isDisabled,
	// setSaveTabName,
	productsOption
}) {
	const { reference, id } = useParams();
	const location = useLocation();
	const isPublic = location.pathname.includes("public");

	const statementId = isPublic ? id : reference;
	const {
		operationStatement,
		productsBuilder,
		marketPlace,
		interests,
		inventory,
	} = Locale;

	const { transportTypesLookup } = getOperationsLookup(Locale);

	const [errors, setErrors] = useState({});
	const { locale } = useSBSState();
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [ports, setPorts] = useState([]);
	const [transportersDepartureList, setTransportersDepartureList] = useState(
		[]
	);
	const [transportersList, setTransportersList] = useState([]);

	const arrival_departure = OperationStatement.trip;
	const arrivalValidationsKeys = [
		"transport_type",
		"arrival_date",
		"arrival_port_id",
	];
	const departureValidationsKeys = ["departure_date", "departure_port_id"];
	const [isFirst, setisFirst] = useState(false);
	const checkFormErrors = () => {
		let submitError = {};
		// arrival errors
		arrivalValidationsKeys.forEach((key) => {
			submitError = {
				...submitError,
				...validate(
					{
						name: key,
						value: arrival_departure[key]?.id || arrival_departure[key],
					},
					{
						required: true,
						date: key === "arrival_date",
					}
				),
			};
		});
		// departure errors
		arrival_departure?.departures?.forEach((departure, departureIndex) => {
			departureValidationsKeys.forEach((key) => {
				submitError = {
					...submitError,
					...validate(
						{ name: `${key}-${departureIndex}`, value: departure[key] },
						{
							required: true,
							date: key === "departure_date",
						}
					),
				};
			});
		});
		setErrors({ ...submitError });
	};

	const portsFetch = async (transportationTypes) => {
		if (transportationTypes) {
			let Ports = await fetchPorts(966, transportationTypes === "Air" ? 2 : 1);
			setPorts(Ports);
		}
	};

	useEffect(() => {
		if (arrival_departure?.transport_type?.name) {
			portsFetch(arrival_departure?.transport_type?.id);
		}
	}, [arrival_departure?.transport_type]);

	const saveProduct = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	// send data to backend
	useEffect(() => {
		let data =
			arrival_departure?.transport_type?.id === "Air"
				? {
					transport_type: arrival_departure?.transport_type?.id?.toString(),
					arrival_carrier_id: arrival_departure?.arrival_carrier_name?.id,
					arrival_trip_number: arrival_departure?.arrival_trip_number,
					arrival_date: moment(arrival_departure?.arrival_date).utc(true),
					arrival_port_id: arrival_departure?.arrival_port_id?.id,
					arrival_hall_id: arrival_departure?.arrival_hall_id?.id,
					departures: arrival_departure.departures.map((departure) => {
						return {
							id: departure?.id || null,
							carrier_id: departure?.departure_carrier_name?.id,
							trip_number: departure?.departure_trip_number,
							date: moment(departure?.departure_date).utc(true),
							port_id: departure?.departure_port_id?.id,
							hall_id: departure?.departure_hall_id?.id,
						};
					}),
				}
				: {
					transport_type: arrival_departure?.transport_type?.id?.toString(),
					arrival_carrier_name: arrival_departure?.arrival_carrier_name,
					arrival_trip_number: arrival_departure?.arrival_trip_number,
					arrival_date: moment(arrival_departure?.arrival_date).utc(true),
					arrival_port_id: arrival_departure?.arrival_port_id?.id,
					arrival_hall_id: arrival_departure?.arrival_hall_id?.id,
					departures: arrival_departure?.departures?.map((departure) => {
						return {
							id: departure?.id || null,
							date: moment(departure?.departure_date).utc(true),
							port_id: departure?.departure_port_id?.id,
						};
					}),
				};
		async function CreateStatementMainDetails() {
			if (isFormValid(errors)) {
				const response = await addTripStatements(statementId, data);
				if (response.status === 200 || response.status === 201) {
					store.addNotification({
						title: `${interests.messages.updateSuccessfully}`,
						message: `${interests.messages.EntriesUpdateSuccess}`,
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
							duration: 3000,
							onScreen: true,
							pauseOnHover: true,
						},
					});
				}
			}
		}

		if (isFirst) {
			CreateStatementMainDetails();
		}
	}, [isErrorLoaded]);


	const deleteDeparture = (e) => {
		let trip = { ...arrival_departure };
		trip.departures = trip.departures.filter((res, index) => index !== e);
		setOperationStatement({
			...OperationStatement,
			trip: trip,
		});
	};

	function handelDepartureValue(value, key, index, isMulti) {
		let trip = { ...arrival_departure };
		if (!isMulti) {
			trip.departures[index] = {
				...trip.departures[index],
				[key]: value,
				bus_arrival_date:
					key === "departure_date"
						? moment(value).subtract(6, "hours").toDate()
						: key === "bus_arrival_date"
							? value
							: trip.departures[index]?.bus_arrival_date,
			};

			setOperationStatement({
				...OperationStatement,
				trip: trip,
			});
		} else {
			let obj = value;

			trip.departures[index] = { ...trip.departures[index], ...obj };
			setOperationStatement({
				...OperationStatement,
				trip: trip,
			});
		}
		// if (key === "departure_date") {
		// 	setErrors({
		// 		...errors,
		// 		...validate(
		// 			{
		// 				name: `bus_arrival_date-${index}`,
		// 				value: trip.departures[index]?.bus_arrival_date,
		// 			},
		// 			{ required: true, date: true }
		// 		),
		// 	});
		// }
	}
	const getTransportersList = async (inputValue) => {
		if (inputValue.length > 2) {
			const transportersRes = await fetchTransporters(2, inputValue);
			let result = [];
			transportersRes?.forEach((item) => {
				result.push({
					...item,
					name: item.name?.[locale] || item.name,
					value: item.id,
					label: item.name?.[locale] || item.name,
				});
			});
			setTransportersList(result);
		}
	};

	const getTransportersDepartureList = async (inputValue, index) => {
		if (inputValue.length > 2) {
			const transportersRes = await fetchTransporters(2, inputValue);
			let result = [];
			transportersRes?.forEach((item) => {
				result.push({
					...item,
					name: item.name?.[locale] || item.name,
					value: item.id,
					label: item.name?.[locale] || item.name,
				});
			});
			let departureList = { [index]: result };
			setTransportersDepartureList(departureList);
		}
	};

	function addDeparture() {
		let tripClone = { ...arrival_departure };

		tripClone?.departures.push({
			departure_carrier_name: { name: "" },
			departure_trip_number: null,
			departure_date: null,
			departure_port_id: null,
			departure_hall_id: null,
			// bus_arrival_date: null,
		});
		setOperationStatement({
			...OperationStatement,
			trip: tripClone,
		});
	}

	//Generate Flight
	const [productSelected, setProductSelected] = useState();
	const [generateFlightIsOpen, setGenerateFlightIsOpen] = useState(false);
	const generateFlightToggle = () => {
		setGenerateFlightIsOpen((prev) => !prev);
	};
	const [isTripEmpty, setIsTripEmpty] = useState(false);
	useEffect(() => {
		setIsTripEmpty(
			!OperationStatement?.trip?.arrival_carrier_name &&
			!OperationStatement?.trip?.departure_hall_id &&
			!OperationStatement?.trip?.arrival_port_id
		);
	}, [OperationStatement?.trip]);

	const generationFlight = async () => {
		const data = { product_id: productSelected?.id };
		const res = await GenerateFlights(statementId, data);
		if (res?.status === 200 || res?.status === 201) {
			console.log("resssssssss", res);
			let result = await formatViewOperationStatement({
				...OperationStatement,
				trip: res?.data,
				generate_flight: true,
			}, Locale);
			setOperationStatement(result);
			store.addNotification({
				title: "Info",
				message: "Generate Flight Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					setProductSelected();
				},
			});

			generateFlightToggle();
		}
	};

	return (
		<>
			{isTripEmpty && (
				<div className="d-flex justify-content-end">
					{!isPublic && !operationHasSent ?
						<button className="btn btn-success py-2 px-4"
							onClick={generateFlightToggle}
							style={{
								fontSize: "16px",
							}}
						>
							{operationStatement.generateFlight}
						</button>
						:
						null
					}
				</div>
			)}
			<div className="col-md-12 OperationDetails">
				<div className="row">
					<div className="col-md-2">
						<div className=" full-input">
							<SelectField
								label={inventory.messages.transportType + "*"}
								value={arrival_departure?.transport_type?.name}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(
											{ name: "transport_type", value: e.value },
											{ required: true }
										),
									});
									setOperationStatement({
										...OperationStatement,
										trip: {
											departures: [
												{
													departure_carrier_name: { name: "" },
													departure_trip_number: null,
													departure_date: null,
													departure_port_id: null,
													departure_hall_id: null,
													// bus_arrival_date: null
												},
											],
											transport_type: {
												id: e.value,
												name: e.label,
											},
										},
									});
								}}
								options={transportTypesLookup}
								placeholder={productsBuilder.select}
								onBlur={() =>
									setErrors({
										...errors,
										...validate(
											{
												name: "transport_type",
												value: arrival_departure?.transport_type?.id,
											},
											{ required: true }
										),
									})
								}
								color={errors?.transport_type?.required ? "danger" : ""}
								errors={errors?.transport_type}
								disabled={operationHasSent}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<p className="city-title my-3">{operationStatement.ArrivalDetails}</p>
				</div>

				<div className="row">
					{arrival_departure?.transport_type?.id === "Air" ? (
						<>
							{" "}
							<div className="wid-20 px-1">
								<div className=" full-input">
									{/* <TextField
								placeholder={operationStatement.CarrierName}
								label={operationStatement.CarrierName}
								name="arrival_carrier_name"
								value={arrival_departure?.arrival_carrier_name}
								onChange={(e) => {
									
									setOperationStatement({
										...OperationStatement,
										trip: {
											...arrival_departure,
											arrival_carrier_name: e.target.value,
										},
									});
								}}
								color={errors.arrival_carrier_name?.required ? "danger" : ""}
								errors={errors.arrival_carrier_name}
							/> */}

									<AutoCompleteField
										placeholder={operationStatement.CarrierName}
										label={operationStatement.CarrierName}
										isSearchable={true}
										listAuto={transportersList}
										setListAuto={setTransportersList}
										getListAuto={getTransportersList}
										value={arrival_departure?.arrival_carrier_name?.name || ""}
										options={fetch.transporters}
										id="transportersDropDown"
										onChange={(e) => {
											setOperationStatement({
												...OperationStatement,
												trip: {
													...arrival_departure,
													arrival_carrier_name: { name: e },
												},
											});
										}}
										onSelectValue={(e) => {
											setOperationStatement({
												...OperationStatement,
												trip: {
													...arrival_departure,
													arrival_carrier_name: {
														id: e?.value,
														name: e?.label,
													},
												},
											});
										}}
										disabled={operationHasSent}

									/>
								</div>
							</div>
							<div className="wid-20 px-1">
								<div className="form-group full-input">
									<TextField
										placeholder={operationStatement.tripNumber}
										label={operationStatement.tripNumber}
										name="arrival_trip_number"
										value={arrival_departure?.arrival_trip_number}
										onChange={(e) => {
											setOperationStatement({
												...OperationStatement,
												trip: {
													...arrival_departure,
													arrival_trip_number: e.target.value,
												},
											});
										}}
										color={errors?.arrival_trip_number?.required ? "danger" : ""}
										errors={errors?.arrival_trip_number}
										disabled={operationHasSent}

									/>
								</div>
							</div>{" "}
						</>
					) : null}
					{/* new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()) */}
					<div className="wid-20 px-1">
						<DateTimePickerField
							label={marketPlace.arrivalDate + "*"}
							date={
								arrival_departure?.arrival_date
									? new Date(arrival_departure?.arrival_date)
									: null
							}


							placeholder="DD/MM/YYYY"
							minDate={moment(new Date())["_d"]}
							onChangeDate={(e) => {
								setErrors({
									...errors,
									...validate(
										{
											name: "arrival_date",
											value: e,
										},
										{ required: true, date: true }
									),
								});
								setOperationStatement({
									...OperationStatement,
									trip: {
										...arrival_departure,
										arrival_date: e,
									},
								});
							}}
							color={errors?.arrival_date?.required ? "danger" : ""}
							errors={errors?.arrival_date}
							disabled={operationHasSent}

						/>
					</div>

					<div className="wid-20 px-1">
						<div className="form-group full-input">
							<SelectField
								label={operationStatement.ArrivalAirport + "*"}
								value={arrival_departure?.arrival_port_id?.name}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(
											{ name: "arrival_port_id", value: e.value },
											{ required: true }
										),
									});

									setOperationStatement({
										...OperationStatement,
										trip: {
											...arrival_departure,
											arrival_port_id: {
												...e,
												id: e.value,
												name: e.label,
											},
											arrival_halls: e.halls,
										},
									});
								}}
								options={ports}
								placeholder={productsBuilder.select}
								onBlur={() =>
									setErrors({
										...errors,
										...validate(
											{
												name: "arrival_port_id",
												value: arrival_departure?.arrival_port_id?.id,
											},
											{ required: true }
										),
									})
								}
								color={errors?.arrival_port_id?.required ? "danger" : ""}
								errors={errors?.arrival_port_id}
								disabled={operationHasSent}

							/>
						</div>
					</div>

					{arrival_departure?.transport_type?.id === "Air" ? (
						<div className="wid-20 px-1">
							<div className="form-group full-input">
								<SelectField
									label={operationStatement.ArrivalAirportHall}
									value={arrival_departure?.arrival_hall_id?.name}
									onChange={(e) => {
										setOperationStatement({
											...OperationStatement,
											trip: {
												...arrival_departure,
												arrival_hall_id: {
													id: e.value,
													name: e.label,
												},
											},
										});
									}}
									// options={arrival_departure?.arrival_halls}
									options={arrival_departure?.arrival_halls ?
										[...arrival_departure?.arrival_halls, { name: productsBuilder.not_selected, id: null }]
										:
										[]
									}
									placeholder={productsBuilder.select}
									color={errors?.arrival_hall_id?.required ? "danger" : ""}
									errors={errors?.arrival_hall_id}
									disabled={operationHasSent}

								/>
							</div>
						</div>
					) : null}
				</div>

				<div className="row">
					<p className="city-title mt-3">
						{operationStatement.DepartureDetails}
					</p>
				</div>
				{/* departures */}
				{arrival_departure?.departures &&
					arrival_departure?.departures?.length > 0
					? arrival_departure?.departures.map((departure, index) => {
						return (
							<div className="row my-3" key={index}>
								{arrival_departure?.transport_type?.id === "Air" ? (
									<>
										<div className="wid-20 px-1">
											<div className=" full-input">
												<AutoCompleteField
													placeholder={operationStatement.CarrierName}
													label={operationStatement.CarrierName}
													isSearchable={true}
													listAuto={transportersDepartureList?.[index]}
													setListAuto={setTransportersDepartureList}
													getListAuto={(e) =>
														getTransportersDepartureList(e, index)
													}
													value={
														departure?.departure_carrier_name?.name || ""
													}
													options={fetch.transporters}
													id={`transportersDropDown-${index}`}
													name={`transportersDropDown-${index}`}
													onChange={(e) => {
														handelDepartureValue(
															{ name: e },
															"departure_carrier_name",
															index
														);
													}}
													onSelectValue={(e) => {
														handelDepartureValue(
															{ id: e?.value, name: e?.label },
															"departure_carrier_name",
															index
														);
													}}
													disabled={operationHasSent}

												/>
											</div>
										</div>
										<div className="wid-20 px-1">
											<div className="form-group full-input">
												<TextField
													placeholder={operationStatement.tripNumber}
													label={operationStatement.tripNumber}
													name={`departure_trip_number-${index}`}
													value={departure?.departure_trip_number}
													onChange={(e) => {
														handelDepartureValue(
															e.target.value,
															"departure_trip_number",
															index
														);
													}}
													color={
														errors?.departure_trip_number?.required
															? "danger"
															: ""
													}
													errors={errors?.departure_trip_number}
													disabled={operationHasSent}

												/>
											</div>
										</div>{" "}
									</>
								) : null}

								<div className="wid-20 px-1">
									<DateTimePickerField
										label={marketPlace.departureDate + "*"}
										date={
											departure?.departure_date
												? new Date(departure.departure_date)
												: null
										}
										name={`departure_date-${index}`}
										placeholder="DD/MM/YYYY"
										onChangeDate={(e) => {
											setErrors({
												...errors,
												...validate(
													{
														name: `departure_date-${index}`,
														value: e,
													},
													{ required: true, date: true }
												),
											});
											handelDepartureValue(e, "departure_date", index);
										}}
										isOutsideRange={(day) => !day.isBefore(moment(), "day")}
										color={
											errors?.[`departure_date-${index}`]?.required
												? "danger"
												: ""
										}
										errors={errors?.[`departure_date-${index}`]}
										minDate={new Date(arrival_departure.arrival_date)}
										disabled={operationHasSent}

									/>
								</div>

								<div className="wid-20 px-1">
									<div className="form-group full-input">
										<SelectField
											name={`departure_port_id-${index}`}
											label={operationStatement.DepartureAirport + "*"}
											value={departure?.departure_port_id?.name}
											onChange={(e) => {
												setErrors({
													...errors,
													...validate(
														{
															name: `departure_port_id-${index}`,
															value: e.value,
														},
														{ required: true }
													),
												});
												handelDepartureValue(
													{
														departure_port_id: {
															...e,
															id: e.value,
															name: e.label,
														},
														departure_halls: e.halls,
														departure_hall_id: null,
													},
													"departure_port_id",
													index,
													true
												);
											}}
											options={ports}
											placeholder={productsBuilder.select}
											onBlur={() =>
												setErrors({
													...errors,
													...validate(
														{
															name: `departure_port_id-${index}`,
															value: departure?.departure_port_id?.id,
														},
														{ required: true }
													),
												})
											}
											color={
												errors?.[`departure_port_id-${index}`]?.required
													? "danger"
													: ""
											}
											errors={errors?.[`departure_port_id-${index}`]}
											disabled={operationHasSent}

										/>
									</div>
								</div>

								{arrival_departure?.transport_type?.id === "Air" ? (
									<div className="wid-20 px-1">
										<div className="form-group full-input">
											<SelectField
												label={operationStatement.DepartureAirportHall}
												value={departure?.departure_hall_id?.name}
												onChange={(e) => {
													handelDepartureValue(
														{
															...e,
															id: e.value,
															name: e.label,
														},
														"departure_hall_id",
														index
													);
												}}
												// options={ 	departure?.departure_halls }
												options={
													departure?.departure_halls ?
														[...departure?.departure_halls, { name: productsBuilder.not_selected, id: null }]
														:
														[]
												}
												placeholder={productsBuilder.select}
												disabled={operationHasSent}

											/>
										</div>
									</div>
								) : null}

								{/* <div className="wid-20 px-1"> */}
								{/* <DateTimePickerField
									label={operationStatement.busArrivalDate + "*"}
									date={
										departure?.bus_arrival_date
											? new Date(departure?.bus_arrival_date)
											: null
									}
									placeholder="DD/MM/YYYY"
									name={`bus_arrival_date-${index}`}
									onChangeDate={(e) => {
										setErrors({
											...errors,
											...validate(
												{
													name: `bus_arrival_date-${index}`,
													value: e,
												},
												{ required: true, date: true }
											),
										});
										handelDepartureValue(e, 'bus_arrival_date', index)
									}}
									color={errors?.[`bus_arrival_date-${index}`]?.required ? "danger" : ""}
									errors={errors?.[`bus_arrival_date-${index}`]}
								/> */}
								{/* </div> */}

								{arrival_departure?.departures.length === index + 1 &&
									index !== 0 ? (
									<button
										onClick={() => deleteDeparture(index)}
										className="btn-Delete-operation px-0"
									>
										<span>
											<i class="far fa-trash-alt"></i>
										</span>
										{operationStatement.Delete}
									</button>
								) : null}
							</div>
						);
					})
					: null}

				{/* add new departure */}
				<div className="row">
					{!operationHasSent ?
						<button
							className="btn-plus-operation my-3 px-0"
							onClick={() => addDeparture()}
						>
							<span>
								<i class="fas fa-plus"></i>
							</span>
							{operationStatement.AddDeparture}

						</button>
						:
						null
					}
				</div>

				<div className="row"></div>
			</div>

			<div className="w-100 mt-3  d-flex justify-content-end">
				{!operationHasSent ? <button
					disabled={isDisabled}
					className="btn-Product-Filter bg-nxt justify-content-center"
					onClick={() => {
						//  setSaveTabName("operation-ArrivalDeparture")
						saveProduct();
						setisFirst(true);
					}}
				>
					{productsBuilder.Save}
				</button> : null}
			</div>
			<GenerateSeviceModal
				isOpen={generateFlightIsOpen}
				toggle={generateFlightToggle}
				option={productsOption}
				state={productSelected}
				setState={setProductSelected}
				generateFn={generationFlight}
				type={"Flight"}
				header={operationStatement.generateFlight}
			/>
		</>
	);
}
