import React from "react";
import bannerGlobal from "assets/images/market-place/headerGlabal.png";
import SelectField from "components/Form/SelectField/SelectField";
import Locale from "translations";
import useLookupsApi from "modules/products-builder/hooks/useLookups";
import { useParams } from 'react-router-dom';
import { ReactComponent as ResetFilterIcon } from "assets/images/market-place/refresh-circle-icon.svg";



export default function SearchBarPackages({ searchData, setSearchData, getPackages, resetSearch }) {
	const { id } = useParams();
	const { marketPlace, payment, packages } = Locale;
	const [lookups] = useLookupsApi();
	const isUmrah = id === "966";

	const firstStopLookup = [
		{ name: "Makkah", label: "Makkah", value: 3, id: 3 },
		{ name: "Madinah", label: "Madinah", value: 4, id: 4 }
	]

	return (
		<>
			{isUmrah ?
				<div className="container">
					<div className="search-bar row">
						{/* package type */}
						<div className="package-search-field">
							<SelectField
								label={marketPlace.PackageType}
								placeholder={marketPlace.select}
								onChange={(e) => {
									setSearchData({ ...searchData, packageType: e })
								}}
								value={searchData.packageType?.name}
								options={lookups.product_types}
							/>
						</div>
						{/* room types */}
						<div className="package-search-field">
							<SelectField
								multi
								label={marketPlace.messages.roomType}
								placeholder={
									searchData.roomTypes?.length > 0
										? searchData.roomTypes
										: marketPlace.select
								}
								onChange={(values, { action }) => {
									if (action === "remove-value" && !values) {
										setSearchData({ ...searchData, roomTypes: [] });
										return;
									}
									setSearchData({ ...searchData, roomTypes: values })
								}}
								options={lookups.room_types}
							/>
						</div>

						<div className="package-search-field">
							<SelectField
								label={packages.firstStop}
								placeholder={marketPlace.select}
								options={firstStopLookup}
								value={searchData.firstStop?.name}
								onChange={(e) => {
									setSearchData({ ...searchData, firstStop: e })
								}}
							/>
						</div>

						<div className='packages-filters d-flex'>
							<button className="btn search-button" type="button"
								onClick={getPackages}
							>
								{marketPlace.messages.search}
							</button>
							<button className='btn reset-btn search-button mx-2' onClick={resetSearch}>
								<ResetFilterIcon />
								<span className='mx-2'>{payment.messages.clear}</span>
							</button>
						</div>
					</div>
				</div>
				:
				<div className='search-services-wrapper global-wrapper' style={{ backgroundImage: `url(${bannerGlobal})`, minHeight: "250px" }}>
				</div>
			}


		</>
	);
}
