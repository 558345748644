import React from 'react'
import PreAuthHeaderV2 from './Header'
import PreAuthBanner from './Banner'

export default function PreAuthLayoutV2(props) {
  return (
    <div className="pre-auth-layout container">
      <PreAuthHeaderV2 />
      <div className="content mt-3 row mx-0">
        <div className="col-md-6 col-12">
          <PreAuthBanner />
        </div>
        <div className="col-md-6 col-12">
          <div className="ps-4 py-3">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}
