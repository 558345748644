import React, { useEffect, useState } from 'react'
import Locale from 'translations';
import PackageMonth from './PackageMonth';
import SelectField from "components/Form/SelectField/SelectField";
import moment from 'moment';
import { fetchCities, fetchPackagesMonth } from "services/lookups";
import { useParams } from 'react-router-dom';
import { Collapse } from "reactstrap";
import RangeSlider from 'components/Form/RangeSlider';
import { ReactComponent as ResetFilterIcon } from "assets/images/market-place/refresh-circle-icon.svg";





export default function SideFilterPackages({ filters, handleFilters, resetFilters, isUmrah, packagesPrices }) {
  const { marketPlace, payment, packages } = Locale;
  const [lookupsState, setLookupsState] = useState({ packageMonth: [], cities: [] });
  const [filtersIsOpen, setFiltersIsOpen] = useState({
    packageType: true,
    singleService: true,
  })

  const { id } = useParams();

  const itemsLookup = [
    { name: marketPlace.Hotel, id: "hotel" },
    { name: marketPlace.ExternalTransportation, id: "external_transportation" },
    { name: marketPlace.InternalTransportation, id: "internal_transportation" },
    { name: marketPlace.Visa, id: "visa" },
    { name: marketPlace.Sightseeing, id: "sightseeing" },
  ];



  const fetchLookupList = async () => {
    const res = await Promise.all([fetchCities(id), fetchPackagesMonth(id)]);
    const dates = res?.[1]?.dates
    const packageMonth = Object.keys(dates).map((month) => {
      const days = [...new Set(dates[month].map((day) => +moment(day).format("DD"))).values()]
      return { month, days }
    })
    setLookupsState({
      cities: res?.[0],
      packageMonth,
    });
  };


  useEffect(() => {
    fetchLookupList();
    return () => { };
  }, []);

  return (
    <div className='packages-filters'>
      <div className="d-flex justify-content-between align-items-center pb-4">
        <h4 className='filter-title'>{packages.packageFilter}</h4>
        <button className='btn reset-btn' onClick={resetFilters}>
          <ResetFilterIcon />
          <span className='mx-2'>{payment.messages.clear}</span>
        </button>
      </div>
      <div className="content">
        {/* Date and Location */}
        {!isUmrah ?
          <div className='date-location mb-4'>
            <h4 className='font-weight-bold'>{marketPlace.dateLocation}</h4>
            <div className='my-2'>
              <PackageMonth
                label={marketPlace.date}
                placeholder={marketPlace.SelectMonth}
                onChange={(date) =>
                  handleFilters({
                    name: "packageMonth",
                    value: date,
                  })
                }
                avilableDates={lookupsState?.packageMonth}
                value={filters?.packageMonth}
              />
            </div>

            <div className='my-2'>
              <SelectField
                label={marketPlace.city}
                placeholder={marketPlace.select}
                onChange={(e) => {
                  handleFilters({
                    name: "city",
                    value: e,
                  });
                }}
                value={filters?.city?.name}
                options={lookupsState.cities}
              />
            </div>
          </div>
          :
          null
        }


        {/* pax */}
        <div className='pax mb-4'>
          <h4 className='font-weight-bold mb-2'>{marketPlace.pax}</h4>
          {/* adults */}
          <div className='d-flex justify-content-between align-items-center'>
            <span>{marketPlace.Adults}</span>
            <div className='d-flex align-items-center'>

              <button className='pax-btn shadow-none'
                onClick={() => {
                  handleFilters({ name: "adults_pax", value: +filters.adults_pax - 1 })
                }}
                disabled={!filters?.adults_pax || +filters?.adults_pax === 1}
              >
                <i className="fas fa-minus" />
              </button>

              <span className='count'>{filters?.adults_pax}</span>

              <button className='pax-btn increase shadow-none'
                onClick={() => {
                  handleFilters({ name: "adults_pax", value: +filters.adults_pax + 1 })
                }}
              >
                <i className="fas fa-plus" />
              </button>
            </div>
          </div>

          {/* childs */}
          <div className='d-flex justify-content-between align-items-center'>
            <span>{marketPlace.Childs}</span>
            <div className='d-flex align-items-center'>
              <button className='pax-btn shadow-none'
                onClick={() => {
                  handleFilters({ name: "children_pax", value: +filters.children_pax - 1 });
                }}
                disabled={!filters?.children_pax || +filters?.children_pax === 1}
              >
                <i className="fas fa-minus" />
              </button>

              <span className='count'>{filters?.children_pax}</span>

              <button className='pax-btn increase shadow-none'
                onClick={() => {
                  handleFilters({ name: "children_pax", value: +filters.children_pax + 1 });
                }}
              >
                <i className="fas fa-plus" />
              </button>
            </div>
          </div>
        </div>

        {/* package type */}
        {!isUmrah ?
          <div className='type mb-4 flex-column align-items-baseline px-0'>
            <h4 className='font-weight-bold mb-2'>{marketPlace.PackageType}</h4>
            <div className='d-flex justify-content-between align-items-center position-relative w-100 px-4'>
              <div className='custom-control custom-checkbox px-0'>
                <input
                  className="custom-control-input"
                  id={`package-type`}
                  name="package-type"
                  type="checkbox"
                  checked={filters?.tripItems.length === itemsLookup.length}
                  onChange={() => {
                    handleFilters({ name: "tripItems", value: filters?.tripItems.length === itemsLookup?.length ? [] : itemsLookup })
                  }}
                />
                <label htmlFor="package-type" className='custom-control-label user-select-none pointer mt-2 mx-2'>{marketPlace.Package}</label>
              </div>
              <span role='button' className='mt-1 d-block'
                onClick={() =>
                  setFiltersIsOpen((prev) => ({
                    ...prev,
                    packageType: !prev.packageType,
                  }))
                }
              >
                {filtersIsOpen.packageType
                  ? <i className="fas fa-minus" />
                  : <i className="fas fa-plus" />
                }
              </span>
            </div>
            <Collapse isOpen={filtersIsOpen.packageType} className='w-100x'>
              <ul className=''>
                {itemsLookup?.map((type) => {
                  return (
                    <li key={type?.id} className='d-flex align-items-center my-2 custom-control custom-checkbox'>
                      <input
                        className="custom-control-input"
                        id={`package-item-${type?.id}`}
                        name={`package-item-${type?.id}`}
                        type="checkbox"
                        checked={filters?.tripItems?.find(item => item.id === type.id) ? true : false}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          if (!checked) {
                            handleFilters({ name: "tripItems", value: filters?.tripItems?.filter(item => item.id !== type?.id) })
                          } else {
                            handleFilters({ name: "tripItems", value: [...filters?.tripItems, type] })
                          }
                        }}
                      />
                      <label htmlFor={`package-item-${type?.id}`} className='custom-control-label user-select-none pointer mx-2 mb-0'>
                        {type?.name}
                      </label>
                    </li>
                  )
                })}
              </ul>
            </Collapse>
          </div>
          :
          null
        }

        {/* class */}
        <div className='stars mb-4'>
          <h4 className='font-weight-bold mb-2'>{marketPlace.Class}</h4>
          <ul className=''>
            {[5, 4, 3, 2, 1].map(rate => {
              return (
                <li key={rate} className='mb-2 custom-control custom-checkbox'>
                  <input
                    className="custom-control-input"
                    id={`rating-${rate}`}
                    name="rating"
                    type="checkbox"
                    value={rate}
                    checked={filters?.rating?.includes(rate) || false}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      let value;
                      if (!checked) {
                        value = filters?.rating?.filter(rateItem => rateItem !== rate);
                      } else {
                        value = [...filters?.rating, rate]
                      }
                      handleFilters({ name: "rating", value })
                    }}
                  />

                  <label htmlFor={`rating-${rate}`} className='custom-control-label user-select-none pointer mx-2 mb-0'>
                    {[1, 2, 3, 4, 5].map((_, i) => {
                      return rate <= i
                        ? <i key={i} className="far fa-star solid-star"></i>
                        : <i key={i} className="fas fa-star mx-1"></i>
                    })}
                  </label>
                </li>
              )
            })}

          </ul>
        </div>
        {/* price */}
        <div className='price-range mb-4'>
          <h4 className='font-weight-bold mb-4'>{marketPlace.Price} </h4>
          <RangeSlider
            min={Math.floor(packagesPrices?.min)}
            max={Math.floor(packagesPrices?.max)}
            minValue={filters?.price?.min ? Math.floor(filters?.price?.min) : Math.floor(packagesPrices?.min)}
            maxValue={filters?.price?.max ? Math.floor(filters?.price?.max) : Math.floor(packagesPrices?.max)}
            step="10"
            ruler={false}
            onChange={(e) => {
              if (filters?.price?.min !== e.minValue || filters?.price?.max !== e.maxValue) {
                handleFilters({ name: "price", value: { min: e.minValue, max: e.maxValue } });
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}
