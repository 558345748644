import React from "react";
import { backgrounds } from "./backgrounds";
import CheckBox from "components/chekbox";

const Library = ({
	handleUpload,
	slider,
	setSlider,
	handleSliderImages,
	toggle,
	hasSlider
}) => {
	function handleSelectSlide(imageObj, checked) {
		if (!checked) {
			setSlider((oldArray) => [...oldArray, imageObj]);
		} else {
			const sliderClone = [...slider].filter(slide => slide.id !== imageObj.id);
			setSlider(sliderClone)
		}
	}

	// const changeBackground = (uuid) => {
	// 	if (hasSlider) {
	// 		setSlider((oldArray) => [...oldArray, uuid]);
	// 		console.log({ slider });
	// 	} else handleUpload(uuid);
	// };

	const saveBackgrounds = () => {
		handleSliderImages(slider);
		toggle();
	};

	return (
		<div className="web-builder-library">
			<div className="web-builder-library-images">
				{backgrounds?.map((image, idx) => {
					const isChecked = slider.findIndex(slide => slide.id === image.id) > -1;
					if (hasSlider) {
						return (
							<div key={`${image?.id}-${idx}`} className="form-check form-check-modal">
								<CheckBox
									key={idx}
									name="permissions"
									checked={isChecked}
									value={image?.id}
									text={
										<img
											src={image?.src}
											alt="background"
											width="100%"
											className={`gallery-img ${isChecked ? "b-multiSelect-image" : ""}`}
										/>
									}
									onChange={() => handleSelectSlide(image, isChecked)}
								/>
							</div>
						)
					}
					return (
						<div key={`${image?.id}-${idx}`} className="form-check form-check-modal pointer"
							onClick={() => {
								handleUpload(image)
							}}
						>
							<img
								src={image?.src}
								alt="background"
								width="100%"
								className={`gallery-img`}
							/>
						</div>
					)
				})
				}
			</div>

			{hasSlider && (
				<div className="d-flex justify-content-end p-1 my-3">
					<button
						onClick={saveBackgrounds}
						className="btn btn-block w-25 p-1"
						style={{
							background: "#292d32",
							color: "#FFF",
							fontSize: "1rem",
						}}
						disabled={slider?.length <= 1}
					>
						Save
					</button>
				</div>
			)}
		</div>
	);
};

export default Library;
