import React from 'react'
import { useHistory } from 'react-router-dom'

const CRMTabs = ({
	IsNotPushUrl,
	tabs,
	activeTab,
	setActiveTab,
	setPrevActiveTab,
	classNames,
	resetFilters,
	hasBorder = false,
	toggleTabs
}) => {
	// ** react-router
	const { push } = useHistory()

	return (
		<ul
			className={`operation-tabs ${classNames} ${hasBorder && 'tab-borders'}`}
		>
			{tabs?.map((tab) => {
				if (tab?.permission) {
					return (
						<li
							className={`item ${activeTab === tab?.url ? 'active' : ''}  ${
								tab?.disabled ? 'disabled-li-Tabs' : ''
							}`}
							onClick={() => {
								if (!tab?.disabled && !tab?.subTabs && !hasBorder) {
									if (!IsNotPushUrl) {
										push(`/crm/${tab?.url}`)
									}
									setActiveTab(tab?.url)
									setPrevActiveTab && setPrevActiveTab(activeTab)
									resetFilters && IsNotPushUrl && resetFilters(tab?.url)
								}
								if (hasBorder) {
									toggleTabs(tab?.url)
								}
							}}
							key={tab?.url}
						>
							{tab?.icon}
							<span className={`${tab?.icon ? 'mx-1' : ''}`}>{tab?.title}</span>
						</li>
					)
				}
				return (
					<li
						className={`item ${activeTab === tab?.url ? 'active' : ''} ${
							tab?.disabled ? 'disabled-li-Tabs' : ''
						} ${tab?.hidden ? 'd-none' : ''}`}
						onClick={() => {
							if (!tab?.disabled && !tab?.subTabs && !hasBorder) {
								if (!IsNotPushUrl) {
									push(`/crm/${tab?.url}`)
								}
								setActiveTab(tab?.url)
								setPrevActiveTab && setPrevActiveTab(activeTab)
								resetFilters && IsNotPushUrl && resetFilters(tab?.url)
							}
							if (hasBorder) {
								toggleTabs(tab?.url)
							}
						}}
						key={tab?.url}
					>
						{tab?.icon}
						<span className={`${tab?.icon ? 'mx-1' : ''}`}>{tab?.title}</span>
					</li>
				)
			})}
		</ul>
	)
}

export default CRMTabs
