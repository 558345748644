import React, { useEffect, useState, useRef } from "react";
import Locale from "translations";
import airportIcon from "assets/images/market-place/transferIcon/airportIcon.svg";
import shipIcon from "assets/images/market-place/transferIcon/shipIcon.svg";
import trainIcon from "assets/images/market-place/transferIcon/trainIcon.svg";
import HotelPlaceHolder from "assets/images/new-hotels.svg";
import {
	useMarketplaceDispatch,
	useMarketplaceState,
} from "context/marketplace";
import { fetchTransferHotelsPorts } from "services/lookups";
import { useHistory } from "react-router-dom";
import validate, { isFormValid } from "helpers/validate";
import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import moment from "moment";
import { store } from "react-notifications-component";
import DateTimePickerField from "components/Form/DateTimePickerField/DateTimePickerField";
import CustomModal from "../Transportation/components/Modal";
import { formatTransfer } from "modules/market-place/TransferResponse/helper/formatTransfer";
import { fetchTransfer } from "services/marketplace";
import AutocompleteModel from "modules/market-place/TransferResponse/Modals/AutocompleteModel";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SearchIcon from "../../../icons/searchIcon";
import { useSBSState } from "context/global";
import { useDetectClickOutside } from "react-detect-click-outside";

const transferIcons = {
	Land: trainIcon,
	Air: airportIcon,
	Maritime: shipIcon,
};

export default function TransferSearchForm({ modify, closeModal }) {
	const { marketPlace, onlineVisa, inventory, messages, commons } = Locale;
	const { locale } = useSBSState();

	const [errors, setErrors] = useState({});
	const [fromToModal, setFromToModal] = useState({ state: false, type: null });

	const [tripType, setTripType] = useState("round");

	const [transportationsData, setTransportationsData] = useState({
		from: null,
		to: null,
		departureDate: null,
		returnDate: null,
		adult: 0,
		children: { id: 0, value: 0, label: "0", name: "0" },
		ages: [],
	});

	const [listAutoFrom, setListAutoFrom] = useState([]);
	const [listAutoTo, setListAutoTo] = useState([]);
	const dispatch = useMarketplaceDispatch();
	const { transferCriteria } = useMarketplaceState();
	const { status } = useParams();
	const roundType = tripType === "round";
	const [paxIsOpen, setPaxIsOpen] = useState(false);
	const maxChildAge = 8;
	const maxAdultsCount = 20;
	const [adultState, setAdultState] = useState(0);
	const [childState, setChildState] = useState(0);

	const [matches, setMatches] = useState(
		window.matchMedia("(max-width: 600px)").matches
	);

	useEffect(() => {
		window
			.matchMedia("(max-width: 600px)")
			.addEventListener("change", (e) => setMatches(e.matches));
	}, []);

	const DetectClickOutside = useDetectClickOutside({
		onTriggered: () => setPaxIsOpen(false),
	});

	const paxToggle = () => setPaxIsOpen((prev) => !prev);
	const getListAuto = async (inputValue, type) => {
		if (inputValue.length > 2) {
			// const [hotels, ports] = await Promise.all([
			// 	fetchTransferHotels({ name: inputValue ,country_id:status==='umrah'?966:""}),

			// ]);
			const lookup = await fetchTransferHotelsPorts({
				name: inputValue,
				country_id: status === "umrah" ? 966 : "",
			});

			const formatHotel =
				lookup?.data && lookup?.data.length > 0
					? lookup?.data?.map((item) => {
							if (item.type === "Hotel") {
								return {
									...item,
									id: item.id,
									name: item.name,
									value: item.id,
									label: item.name,
									type: "hotel",
									country: {
										flag: HotelPlaceHolder,
									},
								};
							} else {
								return {
									...item,
									country: {
										flag: transferIcons[item?.type],
									},
									type: "terminal",
								};
							}
					  })
					: [];

			if (type === "from") {
				setListAutoFrom([...formatHotel]);
			} else {
				setListAutoTo([...formatHotel]);
			}
		}
	};

	const toggleAutoModel = (type) => {
		setFromToModal((prev) => ({ state: !prev.state, type }));
	};

	const childsAgesLookup = [...Array(maxChildAge).keys()].map((age) => {
		return {
			id: age,
			value: age,
			label: age.toString(),
			name: age.toString(),
		};
	});

	function handleChildsCount(e) {
		setTransportationsData({
			...transportationsData,
			children: e,
			ages: [...Array(+e.value).keys()].map(() => {
				return {
					id: 0,
					value: 0,
					label: "0",
					name: "0",
				};
			}),
		});
	}

	function handleChildAge(e, ageIndex) {
		const inputValue = {
			id: e.target.value,
			label: e.target.value,
			name: e.target.value,
			value: e.target.value,
		};
		const ages = [...transportationsData?.ages];
		ages[ageIndex] = inputValue;
		setTransportationsData({ ...transportationsData, ages: ages });
	}

	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const history = useHistory();

	const checkFormErrors = () => {
		let SubmitError = {};
		const validationKeys = ["from", "to", "departureDate", "adult"];

		if (roundType) {
			validationKeys.push("returnDate");
		}
		validationKeys.forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{ name: key, value: transportationsData[key] },
					{ required: true, minNumber: key === "adult" && +adultState === 0 }
				),
			};
		});
		setErrors(() => SubmitError);
	};

	const submit = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	const getFromAndTo = (data) => {
		const destination = fromToModal.type;
		data.value.country = {
			flag:
				data?.type === "hotel"
					? HotelPlaceHolder
					: transferIcons[data.value?.transport_type],
		};
		setTransportationsData((prev) => ({
			...prev,
			// from or to
			[destination]: data.value,

			// from_type or to_type
			[`${destination}_type`]: data.type,
		}));
	};

	useEffect(() => {
		if (modify && transferCriteria) {
			transferCriteria.departureDate = new Date(transferCriteria.departureDate);
			if (transferCriteria.returnDate) {
				transferCriteria.returnDate = new Date(transferCriteria.returnDate);
			} else {
				setTripType("onWay");
			}
			setTransportationsData(transferCriteria);
		}

		return () => {};
	}, []);

	useEffect(() => {
		if (isFormValid(errors)) {
			const handleSearch = async () => {
				const formatDate = formatTransfer(transportationsData);

				const transfers = await fetchTransfer(formatDate);
				if (transfers.status === 200) {
					const data = transfers.data;

					if (transfers.data.data.length > 0) {
						dispatch({
							type: "transferSearch",
							payload: {
								transferCriteria: transportationsData,
								searchData: data,
							},
						});
						if (modify) {
							closeModal();
						} else {
							history.push(`/${status}/transfer`);
						}
					} else {
						store.addNotification({
							title: messages.noResults,
							message: messages.noSearchResults,
							type: "danger",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
						});
					}
				}
			};
			handleSearch();
		} else {
		}
	}, [isErrorLoaded]);

	let fromPrevValue = useRef(null);
	function clearFromSearchText(e) {
		fromPrevValue.current = transportationsData.from;
		const value = e.target.value;
		if (value.length > 0) {
			setTransportationsData((prev) => ({ ...prev, from: null }));
		}
	}

	let toPrevValue = useRef(null);
	function clearToSearchText(e) {
		toPrevValue.current = transportationsData.to;
		const value = e.target.value;
		if (value.length > 0) {
			setTransportationsData((prev) => ({ ...prev, to: null }));
		}
	}

	const handleChange = (e) => {
		setTransportationsData((prev) => ({
			...prev,
			[e.name]: e.value,
			[`${e.name}_type`]: e.value.type,
		}));
	};

	console.log("assadldjkasjdkasdj", errors, adultState);

	return (
		<div className="col-md-12 HotelSearch m-auto pb-1">
			<div
				className="col-md-12 m-auto box-types py-2 justify-content-start"
				style={{ gap: "12px" }}
			>
				<div className={`type ${roundType ? "active" : ""}`}>
					<input
						className="mx-1"
						type="radio"
						name="roundTrip"
						id="roundId"
						value="round"
						checked={roundType}
						onChange={(e) => {
							setTripType(e.target.value);
						}}
					/>
					<label className="text-center " htmlFor="roundId">
						{marketPlace.roundTrip}
					</label>
				</div>
				<div className={`type ${!roundType ? "active" : ""}`}>
					<input
						className="mx-1"
						type="radio"
						name="onWayOnly"
						id="onWayId"
						value="onWay"
						checked={tripType === "onWay"}
						onChange={(e) => {
							setTripType(e.target.value);
							setTransportationsData((prev) => ({
								...prev,
								returnDate: null,
							}));
						}}
					/>
					<label className="text-center " htmlFor="onWayId">
						{marketPlace.onWay}
					</label>
				</div>
			</div>

			<div className="row px-2">
				<div className="col-md-6">
					<div className="form-group">
						{matches && (
							<label className="label-m-color">{marketPlace.From}</label>
						)}
						<AutoCompleteField
							hasLabel={false}
							labelInner={true}
							openModal={() => toggleAutoModel("from")}
							flag={transportationsData.from?.country?.flag}
							imgFlag
							listAuto={listAutoFrom}
							setListAuto={setListAutoFrom}
							getListAuto={(e) => getListAuto(e, "from")}
							label={!matches && marketPlace.From}
							placeholder={marketPlace.messages.destination}
							isSearchable={true}
							//value={hotel.from?.name}
							value={transportationsData.from?.name || ""}
							onChange={(e) => {
								handleChange({ name: "from", value: { name: e } });
							}}
							onSelectValue={(e) => {
								handleChange({ name: "from", value: e });

								setErrors({
									...errors,
									...validate({ name: "from", value: e }, { required: true }),
								});
							}}
							onFocus={clearFromSearchText}
							onBlur={() =>
								setTransportationsData((prev) => ({
									...prev,
									from: fromPrevValue.current,
								}))
							}
							color={errors?.from?.required ? "danger" : ""}
							errors={errors?.from}
						/>
					</div>
				</div>
				{/* cycle type */}
				<div className="col-md-6">
					<div className="form-group">
						{matches && (
							<label className="label-m-color">{marketPlace.to}</label>
						)}
						<AutoCompleteField
							hasLabel={false}
							labelInner={true}
							openModal={() => toggleAutoModel("to")}
							listAuto={listAutoTo}
							flag={transportationsData.to?.country?.flag}
							imgFlag
							setListAuto={setListAutoTo}
							getListAuto={(e) => getListAuto(e, "to")}
							label={!matches && marketPlace.to}
							placeholder={marketPlace.messages.destination}
							isSearchable={true}
							value={transportationsData.to?.name || ""}
							onChange={(e) => {
								handleChange({ name: "to", value: { name: e } });
							}}
							onSelectValue={(e) => {
								handleChange({ name: "to", value: e });

								setErrors({
									...errors,
									...validate({ name: "to", value: e }, { required: true }),
								});
							}}
							onFocus={clearToSearchText}
							onBlur={() =>
								setTransportationsData((prev) => ({
									...prev,
									to: toPrevValue.current,
								}))
							}
							color={errors?.to?.required ? "danger" : ""}
							errors={errors?.to}
						/>
					</div>
				</div>

				{/* Date */}
				<div
					className={`${
						tripType !== "round" || modify ? "col-md-6" : "col-md-3"
					}`}
				>
					<div className="form-group">
						{matches && (
							<label className="label-m-color">
								{marketPlace.departureDate}
							</label>
						)}
						<DateTimePickerField
							hasLabel={false}
							labelInner={true}
							label={!matches && marketPlace.departureDate}
							date={transportationsData?.departureDate ?? ""}
							placeholder="DD/MM/YYYY"
							onChangeDate={(date) => {
								setTransportationsData((prev) => ({
									...prev,
									departureDate: date,
									returnDate:
										moment(date) >= moment(transportationsData?.returnDate)
											? null
											: transportationsData?.returnDate,
								}));
								setErrors({
									...errors,
									...validate(
										{
											name: "departureDate",
											value: date,
										},
										{ required: true }
									),
								});
							}}
							minDate={moment(new Date()).add(1, "d")["_d"]}
							color={errors?.departureDate?.required ? "danger" : ""}
							errors={errors?.departureDate}
							timeInputLabel={marketPlace.flightTime}
						/>
					</div>
				</div>

				{roundType && (
					<div className={`${modify ? "col-md-6" : "col-md-3"}`}>
						<div className="form-group">
							{matches && (
								<label className="label-m-color">
									{marketPlace.returnDate}
								</label>
							)}
							<DateTimePickerField
								hasLabel={false}
								labelInner={true}
								label={!matches && marketPlace.returnDate}
								date={transportationsData.returnDate}
								placeholder="DD/MM/YYYY"
								onChangeDate={(date) => {
									setTransportationsData((prev) => ({
										...prev,
										returnDate: date,
										departureDate:
											moment(date) <= moment(transportationsData?.departureDate)
												? null
												: transportationsData?.departureDate,
									}));
									setErrors({
										...errors,
										...validate(
											{ name: "returnDate", value: date },
											{ required: true }
										),
									});
								}}
								minDate={
									moment(transportationsData?.departureDate).add(1, "d")["_d"]
								}
								color={errors?.returnDate?.required ? "danger" : ""}
								errors={errors?.returnDate}
								timeInputLabel={marketPlace.flightTime}
							/>
						</div>
					</div>
				)}

				{/**Adults and children */}
				<div className="col-md-6 col-12" ref={DetectClickOutside}>
					<div className="button-collapse mt-1">
						{matches && (
							<label className="label-m-color">
								{marketPlace.messages.pax}
							</label>
						)}
						<div
							className={`title-style-container d-flex bg-light  ${
								errors?.adult?.required || errors?.adult?.minNumber
									? "control-field--danger"
									: ""
							}`}
							style={{ borderRadius: "5px", padding: matches && '1.2rem' }}
							onClick={() => paxToggle()}
						>
							<div className="w-100">
								<div className="w-100">
									<div className="global-input font-weight-bold border-0  d-flex justify-content-between p-0">
										<div className="d-flex flex-column">
											{!matches && (
												<span style={{ fontWeight: 400, color: "#000" }}>
													{marketPlace.messages.pax}
												</span>
											)}
											<span
												className="roomsNumber1"
												style={{
													lineHeight: 1,
													fontWeight: 700,
													color: "#000",
												}}
											>
												{transportationsData?.adult?.label ?? 0}{" "}
												{marketPlace.messages.adult}
												{" , "}
												{transportationsData?.children?.label}{" "}
												{marketPlace.messages.child}
											</span>
										</div>

										<div></div>
									</div>
								</div>
							</div>

							<div className="icon-items-section-services d-flex align-items-center bg-light">
								<i
									className={`fas fa-fw text-gray px-4 fa-lg ${
										paxIsOpen ? "fa-chevron-up " : "fa-chevron-down"
									}`}
									// onClick={() => paxToggle()}
								></i>
							</div>
						</div>
						{paxIsOpen ? (
							<div className="row pax-container">
								<div className="adult-pax">
									<p>
										{inventory.messages.adults}{" "}
										<span>{locale === "en" ? "18+ yrs" : "18+ سنة"}</span>
									</p>
									<div className="action">
										{/* decrease adult */}
										<button
											onClick={() => {
												setAdultState((prev) => prev - 1);
												setTransportationsData({
													...transportationsData,
													adult: {
														id: adultState - 1,
														label: adultState - 1,
														name: adultState - 1,
														value: adultState - 1,
													},

													children:
														adultState === 0
															? { id: 0, value: 0, label: "0", name: "0" }
															: transportationsData?.childs,
													ages:
														adultState === 0 ? [] : transportationsData?.ages,
												});
												setErrors({
													...errors,
													...validate(
														{
															name: "adult",
															value: {
																id: adultState - 1,
																label: adultState - 1,
																name: adultState - 1,
																value: adultState - 1,
															},
														},
														{ required: true, minNumber: adultState - 1 === 0 }
													),
												});
											}}
											disabled={adultState <= 0}
										>
											<span>-</span>
										</button>
										<p className="adultNum">{adultState}</p>
										{/* increase adult */}
										<button
											onClick={() => {
												setAdultState((prev) => prev + 1);
												setTransportationsData({
													...transportationsData,
													adult: {
														id: adultState + 1,
														label: adultState + 1,
														name: adultState + 1,
														value: adultState + 1,
													},

													children:
														adultState === 0
															? { id: 0, value: 0, label: "0", name: "0" }
															: transportationsData?.children,
													ages:
														adultState === 0 ? [] : transportationsData?.ages,
												});
												setErrors({
													...errors,
													...validate(
														{
															name: "adult",
															value: adultState + 1,
														},
														{ required: true, minNumber: adultState + 1 === 0 }
													),
												});
											}}
											disabled={maxAdultsCount === adultState + 1}
										>
											<span>+</span>
										</button>
									</div>
								</div>

								<div className="adult-pax child-pax">
									<p>
										{inventory.messages.children}{" "}
										<span>{locale === "en" ? "0-7 yrs" : "0-7 سنة"}</span>
									</p>
									<div className="action">
										<button
											onClick={() => {
												setChildState((prev) => prev - 1);
												handleChildsCount({
													id: childState - 1,
													value: childState - 1,
													label: childState - 1,
													name: childState - 1,
												});
											}}
											disabled={childState <= 0}
										>
											<span>-</span>
										</button>
										<p className="adultNum">{childState}</p>
										<button
											onClick={() => {
												setChildState((prev) => prev + 1);
												handleChildsCount({
													id: childState + 1,
													value: childState + 1,
													label: childState + 1,
													name: childState + 1,
												});
											}}
										>
											<span>+</span>
										</button>
									</div>
								</div>

								<div className="childrensAge w-100">
									{transportationsData?.ages?.length > 0 ? (
										<>
											<hr className="w-100 my-2" />
											<p className="p_children mb-2">
												{marketPlace.selectChildrenAge}
											</p>
										</>
									) : null}
								</div>
								<div className="children-age-select w-100">
									{transportationsData?.ages?.map((age, index) => {
										return (
											<div
												className="d-flex justify-content-between"
												key={index}
											>
												<p>
													{inventory.messages.children} {index + 1}
												</p>
												<select
													id="selectbox1"
													onChange={(e) => handleChildAge(e, index)}
												>
													{childsAgesLookup.map((age, ind) => (
														<option value={age.value}>
															{age.label > 0 ? age.label : "<1"}Years Old
														</option>
													))}
												</select>
											</div>
										);
									})}
								</div>
							</div>
						) : null}

						{errors?.adult?.required || errors?.adult?.minNumber ? (
							<small className="control-field__feedback control-field__feedback--danger d-block error-message">
								{inventory.messages.pax} {commons.isRequired}
							</small>
						) : null}
					</div>
				</div>
				<div className="col-md-6"></div>
				<div className="col-md-6">
					<button
						className="btn w-100 bg-nxt mb-3 mt-3 px-0 text-17"
						type="button"
						onClick={submit}
					>
						<SearchIcon /> {onlineVisa.search}
					</button>
				</div>
			</div>

			<CustomModal
				modalIsOpen={fromToModal.state}
				header={fromToModal.type === "to" ? marketPlace.To : marketPlace.From}
				toggle={() => toggleAutoModel(null)}
				size="md"
				centered={true}
			>
				<AutocompleteModel
					closeModal={() => toggleAutoModel(null)}
					onSubmit={getFromAndTo}
				/>
			</CustomModal>
		</div>
	);
}
