import { useEffect, useState } from "react";
import { fetchImages } from "services/auth";
import { fetchCompanyProfile } from "services/profile";
import Locale from "translations";
import imgIcon from "../../assets/images/customRegister/image-regular.svg";

import buildingIcon from "../../assets/images/customCompanyProfile/building.svg";
import homeIcon from "../../assets/images/customCompanyProfile/home.svg";
import materialIcon from "../../assets/images/customCompanyProfile/material-email.svg";
import metroIcon from "../../assets/images/customCompanyProfile/metro-earth.svg";
import phoneIcon from "../../assets/images/customCompanyProfile/phone.svg";
import whatsappIcon from "../../assets/images/customCompanyProfile/whatsapp.svg";
import idCardIcon from "../../assets/images/customCompanyProfile/id-card.svg";
import { ReactComponent as InfoCircleIcon } from "assets/images/customCompanyProfile/info-circle.svg";

export default function ViewCompany() {
	const { companyDetails, productsBuilder, companyProfile } = Locale;

	let companyCategoriesLookup = [
		{ id: "umrah_company", value: "umrah", name: companyProfile.umrah },
		{ id: "tourism_company", value: "tourism", name: companyProfile.tourism },
		{ id: "services_company", value: "service", name: companyProfile.service },
	];

	// let companyServicesLookup = [
	// 	{ id: "hotel", name: companyProfile.hotel },
	// 	{ id: "flight", name: companyProfile.flight },
	// 	{ id: "transportation", name: companyProfile.transportation },
	// 	{ id: "visa", name: companyProfile.visa },
	// 	{ id: "attractions", name: companyProfile.attractions },
	// 	{ id: "catering", name: companyProfile.catering },
	// 	{ id: "package", name: companyProfile.package },
	// ];

	const [companyProfileState, setCompanyProfileState] = useState({
		name: "",
		address: "",
		businessAddress: "",
		country: 0,
		city: 0,
		tempCity: 0,
		phone: "",
		secondPhone: "",
		avatar: "",
		description: "",
		countryPhoneCode: null,
		companyLogo: null,
		filePreview: null,
		countryCode: null,
		whatsappNumber: "",
		category: null,
		licenseNumber: "",
		services: [],
	});

	useEffect(() => {
		async function fetchData() {
			const company = await fetchCompanyProfile();
			setCompanyProfileState({
				...companyProfileState,
				name: company.name,
				share_public: company.share_public,
				businessAddress: company.business_email,
				phone: company.phone?.phone,
				address: company.address,
				secondPhone: company.extra_data?.phone
					? company.extra_data?.phone.phone
					: "",
				description: company.extra_data?.description,
				avatar: company.logo,
				country: {
					value: company.country?.id,
					label: company.country?.name,
				},
				countryPhoneCode: company.phone?.phone_code,
				countryCode: company.country?.code,
				city: { value: company.city?.id, label: company.city?.name },
				whatsappNumber: company?.whatsapp_number,
				category: companyCategoriesLookup.find(category => category.id === company?.company_category),
				licenseNumber: company?.licence_number,
				services: company?.services?.length> 0 ? company?.services.map(service=> companyProfile[service])?.join(" - ") : [],
			});
		}
		fetchData();
	}, []);

	useEffect(() => {
		async function fetchData() {
			if (companyProfileState.avatar) {
				const fetchImage = await fetchImages(companyProfileState.avatar);
				if (fetchImage) {
					updateLogo(fetchImage);
					setCompanyProfileState({
						...companyProfileState,
						filePreview: fetchImage,
					});
				}
			}
		}
		fetchData();
	}, [companyProfileState.avatar]);

	const updateLogo = (file) => {
		setCompanyProfileState({ ...companyProfileState, companyLogo: file });
	};
	return (
		<>
			<div className="bg-white  rounded p-0">
				<div className="d-flex justify-content-between py-2 px-4">
					{!companyProfileState.category?
						<div className="alert alert-danger d-flex justify-content-center align-items-center mb-0 mt-3 w-100 text-center h5 font-weight-bold py-3">
							<InfoCircleIcon />
							<span className="mx-2">{companyProfile.completeProfle}</span>
						</div>
						:
						null
					}
				</div>
				<div className="row m-0 p-5 align-items-center justify-content-end">
					<div className="col-md-3 d-flex justify-content-end px-5 flex-column align-items-center">
						<div className="image-uploader__outline position-relative bg-white img-center ">
							<div className="img-holder ">
								<img
									src={
										companyProfileState.filePreview
											? companyProfileState.filePreview
											: imgIcon
									}
									className="img-fluid"
									accept="image/*"
									alt=""
								/>
							</div>
						</div>
						{/* {companyProfileState?.share_public ?<div className="mt-2 ">
								<Link className="btn bg-nxt w-100 " to="/web-builder/edit">Web Builder</Link>
							</div> : null} */}
					</div>
					<div className="col-md-8 mt-3">
						<div className="d-flex">
							<img src={buildingIcon} alt="" />
							<div className="mx-3">
								<p className="m-0 sans-font ">{companyDetails.companyName}</p>
								<p className="m-0 font-weight-bold font-s20">
									{companyProfileState.name}
								</p>
							</div>
						</div>

						<div className="d-flex mt-3 ">
							<img src={materialIcon} alt="" />
							<div className="mx-3">
								<p className="m-0 sans-font">{companyDetails.businesmail}</p>
								<p className="m-0 font-weight-bold font-s20">
									{companyProfileState.businessAddress}
								</p>
							</div>
						</div>

						<div className="d-flex mt-3">
							<img src={homeIcon} alt="" />
							<div className="mx-3">
								<p className="m-0 sans-font">{companyDetails.address}</p>
								<p className="m-0 font-weight-bold font-s20">
									{companyProfileState.address}
								</p>
							</div>
						</div>

						<div className="d-flex mt-3 justify-content-between w-100 company-profile-w-100-column ">
							<div className="d-flex">
								<img src={metroIcon} alt="" />
								<div className="mx-3">
									<p className="m-0 sans-font">{companyDetails.country}</p>
									<p className="m-0 font-weight-bold font-s20">
										{companyProfileState.country?.label}
									</p>
								</div>
							</div>

							<div className="d-flex w-50">
								<img src={metroIcon} alt="" />
								<div className="mx-3">
									<p className="m-0 sans-font">{companyDetails.city}</p>
									<p className="m-0 font-weight-bold font-s20">
										{companyProfileState.city?.label}
									</p>
								</div>
							</div>
						</div>

						<div className="d-flex mt-3  justify-content-between w-100 company-profile-w-100-column">
							<div className="d-flex w-50">
								<img src={phoneIcon} alt="" width="20px" />
								<div className="mx-3">
									<p className="m-0 sans-font">{` ${companyDetails.companyPhoneNumber} 1`}</p>
									<p className="m-0 font-weight-bold font-s20">
										<span className={Locale._language === "ar" && "code-num"}>
											{companyProfileState.countryPhoneCode}
										</span>
										<span>{companyProfileState.phone}</span>
									</p>
								</div>
							</div>

							<div className="d-flex w-50">
								<img src={phoneIcon} alt="" width="20px" />
								<div className="mx-3">
									<p className="m-0 sans-font">{` ${companyDetails.companyPhoneNumber} 2`}</p>
									<p className="m-0 font-weight-bold font-s20">
										{companyProfileState.secondPhone
											? companyProfileState.secondPhone
											: productsBuilder.noResult}
									</p>
								</div>
							</div>

						</div>

						{/* whatsapp number */}
						<div className="d-flex mt-3">
							<img src={whatsappIcon} alt="" />
							<div className="mx-3">
								<p className="m-0 sans-font">
									{companyProfile.whatsAppNumber}
									{!companyProfileState.whatsappNumber && <InfoCircleIcon className="mx-2" />}
								</p>
								<p className="m-0 font-weight-bold font-s20">
									{companyProfileState.whatsappNumber || "-"}
								</p>
							</div>
						</div>

						{/* Category and Service */}
						<div className="d-flex mt-3 justify-content-between align-items-center w-100 company-profile-w-100-column ">
							<div className="d-flex">
								<img src={buildingIcon} alt="" width="22px" />
								<div className="mx-3">
									<p className="m-0 sans-font">
										{companyProfile.companyCategory}
										{!companyProfileState.category && <InfoCircleIcon className="mx-2" />}
									</p>
									<p className="m-0 font-weight-bold font-s20">
										{companyProfileState.category?.name || "-"}
									</p>
								</div>
							</div>
							{/* service */}
							<div className="d-flex w-50 text-capitalize">
								<img src={buildingIcon} alt="" width="22px" />
								<div className="mx-3">
									<p className="m-0 sans-font">
										{companyProfile.service}
										{companyProfileState.services.length === 0 ?
											<InfoCircleIcon className="mx-2" />
											:
											null
										}
									</p>
									<p className="m-0 font-weight-bold font-s20">
										{companyProfileState.services.length > 0 ?
											companyProfileState.services
											:
											"-"
										}
									</p>
								</div>
							</div>
						</div>

						{/* License number */}
						<div className="d-flex mt-3">
							<img src={idCardIcon} alt="" />
							<div className="mx-3">
								<p className="m-0 sans-font">
									{companyProfile.licenseNumber}
									{!companyProfileState.licenseNumber && <InfoCircleIcon className="mx-2" />}
								</p>
								<p className="m-0 font-weight-bold font-s20">
									{companyProfileState.licenseNumber || "-"}
								</p>
							</div>
						</div>

					</div>
				</div>
			</div>
		</>
	);
}
