import React from 'react'
// import { EditIcon } from 'modules/UmrahOperations/shared/Icons'
import { ReactComponent as CheckIcon } from 'assets/images/crm/check.svg'
import { ReactComponent as DeleteIcon } from 'assets/images/crm/delete.svg'

import moment from 'moment'
import { useSBSState } from 'context/global'
import Locale from 'translations'

const SummeryDetails = ({ crmDetails, gotoPayment, totalPrice, children, toggleActionModal }) => {
	const { locale } = useSBSState();
	const { inventory, CRM, subscriptionModule, marketPlace, packages, operationStatement } = Locale;

	return (
		<div className='summery-details mt-3'>
			<div className='summery-header'>
				<h3>{CRM.Summery}</h3>
				{/* <button className='btn item active'>
					<EditIcon color='#FFF' />
					<p className='text'>Edit</p>
				</button> */}
			</div>
			<hr style={{ background: 'rgba(221, 220, 220, 0.32)' }} />
			<div className='details'>
				<div className='details-items'>
					<p className='crm-details-text-primary text-red'>{subscriptionModule.RefNo}</p>
					<p className='crm-details-text-secondary text-red'>
						{crmDetails?.reference_num}
					</p>
				</div>

				<div className='details-items'>
					<p className='crm-details-text-primary'>{CRM.OwnerName}</p>
					<p className='crm-details-text-secondary'>
						{crmDetails?.owner?.full_name || "-"}
					</p>
				</div>

				<div className='details-items'>
					<p className='crm-details-text-primary'>{CRM.phonenumber}</p>
					<p className='crm-details-text-secondary'>
						{crmDetails?.owner?.phone || "-"}
					</p>
				</div>

				<div className='details-items'>
					<p className='crm-details-text-primary'>{CRM.Email}</p>
					<p className='crm-details-text-secondary'>
						{crmDetails?.owner?.email}
					</p>
				</div>
			</div>

			<div className='details flex-wrap-custom'>
				<div className='details-items'>
					<p className='crm-details-text-primary'>{CRM.DepartureDate}</p>
					<p className='crm-details-text-secondary'>
						{moment(crmDetails?.departure_date).format('DD MMMM Do YYYY')}
					</p>
				</div>

				<div className='details-items'>
					<p className='crm-details-text-primary'>{CRM.Nights}</p>
					<p className='crm-details-text-secondary'>{crmDetails?.nights || 0}</p>
				</div>

				<div className='details-items'>
					<p className='crm-details-text-primary'>{marketPlace.package}</p>
					<p className='crm-details-text-secondary'>{crmDetails?.trip_type || "-"}</p>
				</div>

				<div className='details-items'>
					<p className='crm-details-text-primary'>{operationStatement.pax}</p>
					<p className='crm-details-text-secondary'>{crmDetails?.pax || 0}</p>
				</div>

				<div className='details-items'>
					<p className='crm-details-text-primary'>{packages.travelAgent}</p>
					<p className='crm-details-text-secondary'>
						{crmDetails?.product?.company_name[locale] || "-"}
					</p>
				</div>

				{/* <div className='details-items'>
					<p className='crm-details-text-secondary'>Landline</p>
					<p className='crm-details-text-primary'>+2010 2387 6064</p>
				</div> */}
			</div>

			<div className='summery-header'>
				<h3>{inventory.messages.guests}</h3>
			</div>

			<hr style={{ background: 'rgba(221, 220, 220, 0.32)' }} />


			{children}



			<div className='summery-header' style={{ margin: '1rem' }}>
				<h3>{CRM.payments}</h3>
				<button className='btn item active-text' onClick={gotoPayment}>
					<p className='text'>{CRM.manage}</p>
				</button>
			</div>
			<hr style={{ background: 'rgba(221, 220, 220, 0.32)' }} />

			<div className='crm-details-payment'>
				<div className='payment-item'>
					<p className='crm-details-text-secondary'>{CRM.Total}</p>
					<p className='crm-details-text-primary'>
						{totalPrice} {crmDetails?.currency}
					</p>
				</div>
				<div className='payment-item'>
					<p className='crm-details-text-secondary text-payment-success'>
						{CRM.Paid}
					</p>
					<p className='crm-details-text-primary text-payment-success'>
						{crmDetails?.payments_total_paid || 0} {crmDetails?.currency}
					</p>
				</div>
				<div className='payment-item'>
					<p className='crm-details-text-secondary text-payment-orange'>
						{CRM.OutStanding}
					</p>
					<p className='crm-details-text-primary text-payment-orange'>
						{crmDetails?.payments_outstanding || 0} {crmDetails?.currency}
					</p>
				</div>
			</div>

			<div className='summery-header' style={{ margin: '1rem 0' }}>
				<h3>{CRM.Status}</h3>
			</div>
			<hr style={{ background: 'rgba(221, 220, 220, 0.32)' }} />

			<div className='crm-details-payment'>
				<div className='payment-item'>
					<p className='d-flex' style={{ gap: '5px' }}>
						{crmDetails?.deal_status?.payment_done ?
							<CheckIcon />
							:
							<DeleteIcon />
						}
						<span className='crm-details-text-secondary'>{CRM.payments}</span>
					</p>
					<p className='crm-details-text-primary'></p>
				</div>
				<div className='payment-item'>
					<p className='d-flex' style={{ gap: '5px' }}>
						{crmDetails?.deal_status?.passport_submitted ?
							<CheckIcon />
							:
							<DeleteIcon />
						}
						<span className='crm-details-text-secondary'>
							{CRM.passportSubmitted}
						</span>
					</p>
					<p className='crm-details-text-primary'></p>
				</div>
			</div>

			{/* accept and reject reservation */}
			{crmDetails?.reservation_type === "manual" &&
				crmDetails?.reservation_status === "pending" &&
				crmDetails?.reservation_from === "b2b" ?
				<div className="mt-3 d-flex justify-content-end">
					<button
						className="btn btn-guests me-2"
						style={{ color: "#fff", backgroundColor: "#C30101" }}
						onClick={() => {
							toggleActionModal("reject")
						}}
					>
						{inventory.messages.reject}
					</button>
					<button
						style={{ color: "#fff", backgroundColor: "#027B30" }}
						className="btn btn-guests"
						onClick={() => {
							toggleActionModal("accept")
						}}
					>
						{inventory.messages.accept}
					</button>
				</div>
				:
				null
			}
		</div>
	)
}

export default SummeryDetails
