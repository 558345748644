// import usePreviewMode from "hooks/usePreviewMode";
import { ReactComponent as TrashIcon } from "assets/images/hotelAccommodation/trash.svg";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import { SORT_ITEMS_IN_CONTAINER_ACTION } from "modules/WebBuilder-V2/interfaces/blockTypes";
import { AddElement } from "modules/WebBuilder-V2/shared/AddElement";
import AddService from "modules/WebBuilder-V2/shared/AddService";
import DragAndDropContainer from "modules/WebBuilder-V2/shared/DragAndDropContainer";
import { EditBox } from "modules/WebBuilder-V2/shared/EditBox";
import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import { useCallback, useState } from "react";
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting-224.svg";
import Locale from "translations";
import PackageCardThemeFive from './PackageCard';

const packagesBlocksType = "packagesBlocksType";

export default function OurPackagesThemeFive({ details, onFocus, focusContainer }) {
	// const { isPreview } = usePreviewMode();
	const { selectedLanguage } = useWebBuilderState();
	let { id, title, body, items } = details;
	const [openAdd, setOpenAdd] = useState(false);
	const [updateItem, setUpdateItem] = useState(null);
	const dispatch = useWebBuilderDispatch();
	const { webBuilder } = Locale;

	items = items?.filter((i) => i !== null && i !== undefined);

	const handleOpenAddModal = useCallback(() => {
		setOpenAdd((prev) => !prev);
		setUpdateItem(null);
	}, []);

	const openEditModal = (item) => {
		handleOpenAddModal();
		setUpdateItem(item);
	};



	const editItem = (item, index) => {
		return [
			<span
				key={`${item.id}-${index}`}
				onClick={() => openEditModal({ ...item, index })}
				className="pointer-event"
			>
				<SettingIcon />
			</span>,
		];
	};

	const addItemToPackages = useCallback(
		(item) => {
			updateItem
				? dispatch({
					type: "editItemInContainer",
					payload: {
						id,
						item,
						itemIndex: updateItem.index,
					},
				})
				: dispatch({
					type: "addItemToContainer",
					payload: {
						id,
						item,
					},
				});
			setUpdateItem(null);
		},
		[dispatch, id, updateItem]
	);

	const deleteBodyContent = ({ itemId, deleteType }) => {
		dispatch({
			type: "deleteBodyContent",
			payload: {
				id,
				deleteType,
				itemId,
			},
		});
	};

	const sortPackagesItems = ({ dragIndex, hoverIndex }) => {
		dispatch({
			type: "sortItemsInContainer",
			payload: {
				id,
				dragIndex,
				hoverIndex,
			},
		});
	};
	const updatePackages = ({ name, value, language }) => {
		dispatch({
			type: "updateBodyContent",
			payload: {
				name,
				value,
				id,
				language,
			},
		});
	};
	const itemsBoxActions = (itemId) => {
		return [
			<span
				key={itemId}
				onClick={() =>
					deleteBodyContent({ itemId, deleteType: "container_item" })
				}
				className="pointer-event"
			>
				<TrashIcon />
			</span>,
		];
	};

	const selectedIds = items ? items.map((i) => i?.id) : []


	return (
		<EditBox editMode={focusContainer === id} actions={[]}>
			<section className={`wb-our-packages`} id={id} onClick={() => onFocus(id)}>

				<div className="container">
					<div className="service-top-section">
						<div className="section_heading w-100">
							<ServicesContent
								header={title}
								body={body}
								changeHeader={(value) =>
									updatePackages({
										name: "title",
										value,
										language: selectedLanguage,
									})
								}
								changebody={(value) =>
									updatePackages({
										name: "body",
										value,
										language: selectedLanguage,
									})
								}
							/>
						</div>

						<AddElement
							onClick={handleOpenAddModal}
							title={webBuilder.addMorePackages}
							className="add-element-box-packages"
						/>
					</div>

					<div className="packages-cards-container">
						{items?.map((item, index) => (
							<DragAndDropContainer
								accept={packagesBlocksType}
								type={packagesBlocksType}
								id={item.id}
								index={index}
								action={SORT_ITEMS_IN_CONTAINER_ACTION}
								moveItemInContainer={(dragIndex, hoverIndex) =>
									sortPackagesItems({ dragIndex, hoverIndex })
								}
								key={`${item.product_uuid}-${index}`}
								className="our-service-item"
							>
								<EditBox
									editMode={focusContainer === id}
									actions={[
										...editItem(item, index),
										...itemsBoxActions(item.id),
									]}
								>
									<PackageCardThemeFive
										{...item}
										key={`${item.product_uuid}-${index}`}
										openEditModal={openEditModal}
									/>
								</EditBox>
							</DragAndDropContainer>
						))}
					</div>
				</div>
			</section>

			{openAdd && (
				<AddService
					handleModalState={handleOpenAddModal}
					open={openAdd}
					label={webBuilder.packages}
					header={webBuilder.package}
					onAdd={addItemToPackages}
					details={updateItem}
					selectedIds={selectedIds}
				/>
			)}
		</EditBox>
	);
}
