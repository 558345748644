// ** react 
import React, { useEffect, useState } from 'react'
// inputs fields
import SelectField from 'components/Form/SelectField/SelectField';
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
// heplers
import Locale from 'translations';
import { SearchIcon } from 'modules/CRM/Icons';
import validate, { isFormValid } from 'helpers/validate';
import { transportationsLookups } from 'services/transportation';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { cleanEmpty } from 'services/general';


const validationKeys = ["cycle_type", "start_date"];

export default function TransportUmrahSearchForm({ searchParams, handleSearchData }) {
  // *** hooks
  const { marketPlace, productElements, operationStatement } = Locale;
  const history = useHistory();

  // *** states
  const [transportConfig, setTransportConfig] = useState(null);

  const [transportForm, setTransportForm] = useState({
    cycle_type: null,
    start_date: null,
    vehicle_type: null,
    vehicle_model: null,
    vehicle_seats: null,
  });

  const [errors, setErrors] = useState({});

  const [optionsList, setOptionsList] = useState({
    vehiclesTypes: [],
    vehiclesModels: [],
    vehicleSeatsCount: []
  })

  // *** functions
  function handleFormInputs({ key, value, resetKeys = {}, required }) {
    setTransportForm((prev) => ({
      ...prev,
      [key]: value,
      ...resetKeys,
    }));

    if (required) {
      setErrors({
        ...errors,
        ...validate(
          { name: key, value },
          { required: true, }
        ),
      });
    }
  }



  function getVehiclesTypesModelsOptions({ selectedCycle, selectedVehicleType, selectedVehicleModel, isModify }) {
    let vehiclesTypes = isModify ? [] : [...optionsList.vehiclesTypes];
    let vehiclesModels = isModify ? [] : [...optionsList.vehiclesModels];
    let vehicleSeatsCount = [];

    // Helper function to handle the case when no vehicle type/model is selected
    const handleNoSelection = (cycle, index) => {
      // Avoid duplicates for vehicle types and models
      vehiclesModels = index === 0 ? [] : vehiclesModels;
      vehiclesTypes = index === 0 ? [] : vehiclesTypes;
      // adding vehicle types and vehicle models
      vehiclesTypes.push(cycle.vehicle_type);
      vehiclesModels.push(cycle.vehicle_model);
      vehicleSeatsCount.push({ id: cycle.seats_count, name: cycle.seats_count });
      // console.log("handleNoSelection");
    };

    // Helper function to handle the case when both vehicle type and model are selected
    const handleTypeAndModelSelected = (cycle) => {
      // for result page 
      if (isModify && searchParams) {
        vehiclesTypes.push(cycle?.vehicle_type);
        cycle.vehicle_type?.id === selectedVehicleType?.id && vehiclesModels.push(cycle.vehicle_model);
      }
      if (cycle.vehicle_type?.id === selectedVehicleType?.id && cycle.vehicle_model?.id === selectedVehicleModel?.id) {
        vehicleSeatsCount.push({ id: cycle.seats_count, name: cycle.seats_count });
      }
      // console.log("handleTypeAndModelSelected");
    };

    // Helper function to handle the case when only vehicle type is selected
    const handleTypeSelected = (cycle, index) => {
      if (isModify && searchParams) {
        vehiclesTypes.push(cycle?.vehicle_type);
      }
      vehiclesModels = index === 0 ? [] : vehiclesModels;
      if (cycle.vehicle_type?.id === selectedVehicleType?.id) {
        vehiclesModels.push(cycle.vehicle_model);
        vehicleSeatsCount.push({ id: cycle.seats_count, name: cycle.seats_count });
      }
      // console.log("handleTypeSelected");
    };

    // Helper function to handle the case when only vehicle model is selected
    const handleModelSelected = (cycle) => {
      if (isModify && searchParams) {
        vehiclesModels.push(cycle?.vehicle_model);
      }
      if (cycle.vehicle_model?.id === selectedVehicleModel?.id) {
        vehicleSeatsCount.push({ id: cycle.seats_count, name: cycle.seats_count });
      }
      // console.log("handleModelSelected");
    };
    // Process each residence in the selected cycle
    selectedCycle?.residences?.forEach((cycle, index) => {
      if (!selectedVehicleType && !selectedVehicleModel) {
        handleNoSelection(cycle, index);
      } else if (selectedVehicleType && selectedVehicleModel) {
        handleTypeAndModelSelected(cycle);
      } else if (selectedVehicleType) {
        handleTypeSelected(cycle, index);
      } else if (selectedVehicleModel) {
        handleModelSelected(cycle);
      }
    });

    // Update the options list with the new selections
    setOptionsList({ ...optionsList, vehiclesTypes, vehiclesModels, vehicleSeatsCount });
    return { vehiclesTypes, vehiclesModels, vehicleSeatsCount }
  }

  function checkFormErrors() {
    let submitErrors = {}
    validationKeys.forEach((key) => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          { name: key, value: transportForm[key] },
          { required: true }
        ),
      };
    });
    setErrors(() => submitErrors);
    return submitErrors;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let formHasErrors = !isFormValid(checkFormErrors());
    if (formHasErrors) return;

    let urlParams = {
      cycle_type: transportForm.cycle_type?.id,
      start_date: transportForm.start_date ? moment(transportForm.start_date).format("Y-MM-DD") : null,
      vehicle_type: transportForm.vehicle_type?.id,
      vehicle_model: transportForm.vehicle_model?.id,
      vehicle_seats: transportForm.vehicle_seats?.id,
    };
    let urlParamsString = new URLSearchParams(cleanEmpty(urlParams)).toString();

    if (!transportConfig) {
      fetchDestinations()
    }
    if (searchParams) {
      fetchDestinationsWithFilters(urlParams)
    }
    history.push(`/umrah/transport-result?${urlParamsString}`);
  }


  async function fetchDestinationsWithFilters(params) {
    const response = await transportationsLookups({ ...params });
    if (response.status === 200) {
      const data = response?.data?.data;
      let umrahTransportConfig = data?.destinations?.find(destination => destination?.country_code === 966);
      const cycleType = umrahTransportConfig?.cycle_types?.find(cycle => +cycle?.id === +params.cycle_type);
      handleSearchData(cycleType)
    }
  }

  async function fetchDestinations() {
    const response = await transportationsLookups({});
    if (response.status === 200) {
      const data = response?.data?.data;
      let umrahTransportConfig = data?.destinations?.find(destination => destination?.country_code === 966);
      setTransportConfig(umrahTransportConfig);

      const cycleType = umrahTransportConfig?.cycle_types?.find(cycle => +cycle?.id === +searchParams?.cycle_type);

      // for search form in result page (to feed the options list data and select search values)
      if (cycleType) {
        const searchVehicleType = searchParams?.vehicle_type ? { id: +searchParams?.vehicle_type } : null;
        const searcheVehicleModel = searchParams?.vehicle_model ? { id: +searchParams?.vehicle_model } : null;

        const { vehiclesTypes, vehiclesModels } = getVehiclesTypesModelsOptions({
          selectedCycle: cycleType,
          selectedVehicleType: searchVehicleType,
          selectedVehicleModel: searcheVehicleModel,
          isModify: true
        });

        setTransportForm({
          ...transportForm,
          cycle_type: cycleType,
          start_date: searchParams?.start_date ? moment(searchParams?.start_date) : null,
          vehicle_type: vehiclesTypes.find(type => type?.id === +searchParams?.vehicle_type),
          vehicle_model: vehiclesModels.find(model => model?.id === +searchParams?.vehicle_model),
          vehicle_seats: searchParams?.vehicle_seats ? { id: searchParams?.vehicle_seats, name: searchParams?.vehicle_seats } : null,
        });
      }
    }
  };



  useEffect(() => {
    if(searchParams) {
      fetchDestinationsWithFilters(searchParams)
    }
    fetchDestinations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // **************************** JSX ******************************//
  return (
    <form className="row mx-0" onSubmit={handleSubmit}>

      {/* Transportation Cycle */}
      <div className="col-md-6 col-12 my-1">
        <SelectField
          hasLabel={false}
          labelInner={true}
          label={marketPlace.transportationCycle}
          placeholder={productElements.cycleType}
          options={transportConfig?.cycle_types || []}
          value={transportForm.cycle_type?.name}
          onChange={(e) => {
            handleFormInputs({
              key: "cycle_type",
              value: e,
              resetKeys: { start_date: null, vehicle_type: null, vehicle_model: null, vehicle_seats: null },
              required: true
            })
            getVehiclesTypesModelsOptions({ selectedCycle: e })
          }}
          errors={errors?.cycle_type}
          color={errors?.cycle_type?.required ? "danger" : ""}
        />
      </div>

      {/* select start date */}
      <div className="col-md-6 col-12 my-1">
        <DatePickerField
          hasLabel={false}
          labelInner={true}
          label={operationStatement.startDate}
          id="start_date"
          name="start_date"
          placeholder="DD/MM/YYYY"
          date={transportForm.start_date}
          onChangeDate={(date) => handleFormInputs({ key: "start_date", value: date, required: true })}
          isOutsideRange={(day) => {
            const startDate = moment(transportForm.cycle_type?.residences[0]?.start_date);
            const endDate = moment(transportForm.cycle_type?.residences[0]?.end_date);
            return !day.isAfter(startDate, "day") || !day.isBefore(endDate.add(1, "day"), "day")
          }}
          errors={errors?.start_date}
          color={errors?.start_date?.required ? "danger" : ""}
          disabled={!transportForm.cycle_type}
        />
      </div>

      {/* vehicle type */}
      <div className="col-md-3 col-12 my-1">
        <SelectField
          hasLabel={false}
          labelInner={true}
          label={operationStatement.Vehicletype}
          placeholder={productElements.select}
          options={optionsList?.vehiclesTypes}
          value={transportForm.vehicle_type?.name}
          onChange={(e) => {
            handleFormInputs({
              key: "vehicle_type",
              value: e,
              resetKeys: { vehicle_model: null, vehicle_seats: null },
              required: true
            })
            getVehiclesTypesModelsOptions({ selectedCycle: transportForm.cycle_type, selectedVehicleType: e })
          }}
          errors={errors?.vehicle_type}
          color={errors?.vehicle_type?.required ? "danger" : ""}
          disabled={!transportForm.cycle_type}
        />
      </div>

      {/* Vehicle Model */}
      <div className="col-md-3 col-12 my-1">
        <SelectField
          hasLabel={false}
          labelInner={true}
          label={marketPlace.vehicleModel}
          placeholder={productElements.select}
          options={optionsList?.vehiclesModels}
          value={transportForm.vehicle_model?.name}
          onChange={(e) => {
            handleFormInputs({
              key: "vehicle_model",
              value: e,
              resetKeys: { vehicle_seats: null },
              required: false
            });
            getVehiclesTypesModelsOptions({
              selectedCycle: transportForm.cycle_type,
              selectedVehicleType: transportForm.vehicle_type,
              selectedVehicleModel: e,
            })
          }}
          errors={errors?.vehicle_model}
          color={errors?.vehicle_model?.required ? "danger" : ""}
          disabled={!transportForm.cycle_type}
        />
      </div>

      {/* Seats Count */}
      <div className="col-md-3 col-12 my-1">
        <SelectField
          hasLabel={false}
          labelInner={true}
          label={productElements.seatsCount}
          placeholder={productElements.select}
          options={optionsList?.vehicleSeatsCount}
          value={transportForm.vehicle_seats?.name}
          onChange={(e) => handleFormInputs({ key: "vehicle_seats", value: e, required: false })}
          errors={errors?.vehicle_seats}
          color={errors?.vehicle_seats?.required ? "danger" : ""}
        // disabled={!transportForm.vehicle_type || !transportForm.vehicle_model}
        />
      </div>

      {/* search  */}
      <div className="col-md-3 col-12 my-1">
        <button type="submit" className="btn w-100 bg-nxt mt-1" style={{ paddingBlock: "12px" }}>
          <SearchIcon /> {marketPlace.messages.search}
        </button>
      </div>

    </form>
  )
}
