// import image1 from '/webBuilder/1.jpg'
// import image2 from 'images/webBuilder/2.jpg'
// import image3 from 'images/webBuilder/3.jpg'
// import image4 from 'images/webBuilder/4.jpg'
// import image5 from 'images/webBuilder/5.jpg'
// import image6 from 'images/webBuilder/6.jpg'
// import image7 from 'images/webBuilder/7.jpg'

export const backgrounds = [
	{ id: 1, src: "/webBuilder/1.jpg" },
	{ id: 2, src: "/webBuilder/2.jpg" },
	{ id: 3, src: "/webBuilder/3.jpg" },
	{ id: 4, src: "/webBuilder/4.jpg" },
	{ id: 5, src: "/webBuilder/5.jpg" },
	{ id: 6, src: "/webBuilder/6.jpg" },
	{ id: 7, src: "/webBuilder/7.jpg" }
]
