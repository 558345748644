import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, Table } from "reactstrap";
import Locale from "translations";
import TransferFilter from "./Filter";
import Arrow from "assets/images/icon-arrow-horizontal.svg";
import walletImgSrc from "assets/images/inventory/walletSm.svg";
import Pagination from "components/Pagination";
import { Link, useHistory } from "react-router-dom";
import { FetchTransfersListRequests } from "services/inventory";
import moment from "moment";
import classNames from 'classnames';
import TransportationRequests from '../Transportation';


//import { FetchTransfersListRequests } from 'services/inventory';

export default function TransferRequests() {
	const history = useHistory();
	const { backOffice, inventory, marketPlace } = Locale;
	const [filters, setFilters] = useState({
		search: "",
		booking_date: "",
		transfer_date: "",
		status: "",
	});
	const [transferList, setTransferList] = useState([]);
	const [meta, setMeta] = useState();
	const [total, setTotal] = useState([]);
	const [searchStatus, setSearchStatus] = useState("");

	const statusLocale = (status) => {
		switch (status) {
			case "PENDING":
				return inventory.messages.pending;
			case "CONFIRMED":
				return inventory.messages.confirmed;
			case "CANCELED":
				return inventory.messages.Canceled;
			case "EXPIRED":
				return inventory.messages.expired;
			default:
				return status;
		}
	};

	const [activeTab, setActiveTab] = useState("1");
	const toggleTab = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};


	useEffect(() => {
		// replace it with useffect to get the transfer data from api
		async function fetchTransfers() {
			const res = await FetchTransfersListRequests({
				...filters,
				booking_date: filters.booking_date
					? moment(filters.booking_date).format("YYYY-MM-DD")
					: "",
				transfer_date: filters.transfer_date
					? moment(filters.transfer_date).format("YYYY-MM-DD")
					: "",
				status: filters.status.title,
			});
			setTransferList(res?.data?.data);
			setMeta(res?.data?.meta);
			setTotal(res?.data?.data?.length);
		}
		fetchTransfers();
	}, [searchStatus]);

	const goTo = (page) => {
		setFilters({ ...filters, page: page });
		search()
	};

	const search = () => {
		setSearchStatus(!searchStatus);
	};

	return (
		<div className="transfer-container bg-white">
			<div className="invertory-header d-flex align-items-center justify-content-between mt-2">
				<p className="font-weight-bolder title-Filter p-3 ml-2">

				</p>
				<Nav tabs>
					<NavItem>
						<NavLink
							className={`border-0  ${classNames({
								active: activeTab === "1",
							})}`}
							onClick={() => {
								toggleTab("1");
							}}
						>
							<div className="product-build-list-item">
								<span
									className={`text text-mobileNew  mx-1 ${activeTab === "1" ? "title-Filter" : "txt-dis-tabs"
										} `}
								>
									{marketPlace.messages.globalTransfer}
								</span>
							</div>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={`border-0  ${classNames({
								active: activeTab === "2",
							})}`}
							onClick={() => {
								toggleTab("2");
								// history.push("/inventory/transportations")
							}}
						>
							<div className="product-build-list-item">
								<span
									className={`text text-mobileNew mx-1 ${activeTab === "2" ? "title-Filter" : "txt-dis-tabs"
										} `}
								>
									{marketPlace.messages.umrahTransfer}
								</span>
							</div>
						</NavLink>
					</NavItem>
				</Nav>

				<div></div>
			</div>



			{activeTab === "1" ?

				<>
					<div className="product-header-list p-4 inventory-header-totals">
						<h3 className="font-weight-bold text-capitalize">
							{inventory.messages.transfersBookings} ({total})
						</h3>
					</div>
					<TransferFilter
						filters={filters}
						setFilters={setFilters}
						search={search}
					/>
					<div className="table-container">
						<Table
							striped
							className="table-update p-2 bg-white table table-striped"
							style={{ verticalAlign: "center" }}
						>
							<thead>
								<tr>
									<th>{inventory.messages.refNumber}</th>
									<th>{inventory.messages.leadName}</th>
									<th>{inventory.messages.confirmationDate}</th>
									<th>{inventory.messages.freeCancellationDate}</th>
									<th>{inventory.messages.autoCancellation}</th>
									<th>{inventory.messages.TotalPrice}</th>
									<th>{inventory.messages.source}</th>
									<th>{inventory.messages.actions}</th>
									<th>{inventory.messages.status}</th>
								</tr>
							</thead>
							<tbody>
								{/* transfer list */}
								{transferList?.length > 0 ? (
									transferList?.map((transferItem) => {
										return (
											<tr key={transferItem?.id}>
												<td>{transferItem.reference}</td>
												<td>{transferItem?.name}</td>
												<td>
													{moment(transferItem?.booking_date).format("YYYY-MM-DD")}
												</td>
												<td>
													{transferItem?.free_cancellation_date
														? moment(transferItem?.free_cancellation_date).format(
															"YYYY-MM-DD"
														)
														: "-"}
												</td>
												<td>
													{transferItem?.auto_cancellation_date
														? moment(transferItem?.auto_cancellation_date).format(
															"YYYY-MM-DD"
														)
														: "-"}
												</td>
												<td>
													{transferItem?.total_amount} {transferItem?.currency}
												</td>
												<td>{transferItem.source}</td>

												{/* actions */}
												<td>
													{transferItem?.status === "PENDING" ? (
														<Link
															to={`/inventory/transfer/reservation/${transferItem?.id}`}
															className="transfer-actions pay-now"
														>
															<img
																src={walletImgSrc}
																alt="wallet"
																className="mx-1"
															/>
															{inventory.messages.payNow}
														</Link>
													) : transferItem?.status === "CONFIRMED" &&
														transferItem?.must_confirm_pickup ? (
														<Link
															to={`/inventory/transfer/reservation/${transferItem?.id}`}
															className="transfer-actions text-success"
														>
															<i className="far fa-calendar-check h5 my-0 me-2"></i>
															{inventory.messages.confirmPickup}
														</Link>
													) : (
														"-"
													)}
												</td>
												{/* status */}
												<td>
													<p class={`status ${transferItem?.status?.toLowerCase()}`}>
														{statusLocale(transferItem?.status)}
													</p>
												</td>
												{/* go to reservation detalis */}
												<td>
													<Link
														to={`/inventory/transfer/reservation/${transferItem?.id}`}
														className="transfer-arrow"
													>
														<img src={Arrow} alt="arrow" />
													</Link>
												</td>
											</tr>
										);
									})
								) : (
									<tr>
										<td colSpan="10">
											<div className="product-build__product-no-data fullHeight">
												<i className="icx icx-eye-slash" aria-hidden="true"></i>
												<h4>{backOffice.noResult}</h4>
											</div>
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</div>
					{transferList?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}
				</>
				:
				<TransportationRequests />

			}

		</div>
	);
}



