import React, { useEffect, useState } from "react";
import hourGlassSrc from "assets/images/icons/hour-glass.png";
import { ReactComponent as Users } from "assets/images/icons/users.svg";

import Locale from "translations";
import { useSBSState } from "context/global";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import passportICon from "assets/images/PassportICon.svg";
import UAE from "assets/images/uae.png";
import SA from "assets/images/sa.png";

import { ReactComponent as DownIcon } from "assets/images/product-builder/chevron-down.svg";
import { Collapse } from "reactstrap";

export default function VisaSearchTypes({
	destinations,
	selectedDestination,
	setSelectedDestination,
	selectVisa,
	lowestPackagePrice,
}) {
	// set first package plan
	const { locale } = useSBSState();
	const [filtersIsOpen, setFiltersIsOpen] = useState(true);
	const [filter, setFilter] = useState([]);
	const [noteModal, setNoteModal] = useState({
		isOpen: false,
		note: "",
	});
	const { status } = useParams();
	const [someNationalities, setSomeNationalities] = useState({
		isOpen: false,
		nationality: [],
	});

	function toggleSomeNationalities(data) {
		setSomeNationalities((prev) => ({
			isOpen: !prev.isOpen,
			nationality: data,
		}));
	}

	const toggleNote = (note) => {
		setNoteModal((prev) => ({
			isOpen: !prev.isOpen,
			note,
		}));
	};
	const toggleFilter = () => {
		setFiltersIsOpen((prev) => !prev);
	};

	const { marketPlace } = Locale;
	const visaTypes = selectedDestination?.visa_types;

	useEffect(() => {
		if (filter && filter.length == 0) {
			let filterData = [];
			visaTypes?.map((i, index) => {
				filterData.push(i.name);
			});
			setFilter(filterData);
		}
	}, [filter]);

	const onChangeFilter = (e, checked) => {
		const value = e.target.value;

		let newFilter = [...filter];
		if (checked) {
			if (newFilter.length > 1) {
				newFilter = newFilter.filter((types) => types !== value);
			}
		} else {
			newFilter = [...newFilter, value];
		}
		setFilter(newFilter);
	};
	return (
		<>
			<div className="">
				{status !== "umrah" && (
					<div className="visa-selected-container row mx-0 mb-3 justify-content-center">
						{destinations?.map((destination) => {
							const countryImageURL = `${process.env.REACT_APP_FILEUPLOAD_URL}/fetch/${destination?.country_photo}`;
							return (
								<div
									className="col-md-2 col-12 mb-1 mb-md-0"
									key={`destination-${destination?.country_code}`}
								>
									<div
										className={`visa-destination-card borderRid visa-destination-services pointer ${
											destination?.country_code ===
											selectedDestination?.country_code
												? "active"
												: ""
										} `}
										onClick={() => {
											setSelectedDestination({
												...destination,
												country_photo: countryImageURL,
											});
											setFilter([]);
										}}
									>
										<div className="d-flex justify-content-between align-content-center align-items-center">
											<h5 className={`visa-card-header `}>
												<img
													width={"44px"}
													style={{ marginInlineEnd: "5px" }}
													src={destination?.country === "UAE" ? UAE : SA}
													alt=""
													srcset=""
												/>
												{destination?.country}
											</h5>

											<div
												className={`visa-radio-input ${
													destination?.country_code ===
													selectedDestination?.country_code
														? "active"
														: ""
												}`}
											></div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				)}
			</div>

			{visaTypes && visaTypes.length > 0 ? (
				<>
					<div className="bg-light-darker w-100 d-flex p-3 px-4 align-items-center flex-wrap">
						<div className='visa-filter-collapse-btn-container'>
							<i class="fas fa-filter text-filter me-1"></i>
							<h5 className="text-body text-filter font-weight-bold me-3">
								{marketPlace.messages.filter}
							</h5>
							<button
								className="btn visa-filter-collapse-btn"
								onClick={toggleFilter}
							>
								<DownIcon
									style={{
										...(filtersIsOpen && { transform: "rotatex(180deg)" }),
									}}
								/>
							</button>
						</div>

						<Collapse isOpen={filtersIsOpen}>
							<div className='visa-filter-collapse'>

							<div className="custom-control custom-checkbox filter-check-box mt-md-0 mt-2">
								<img src={passportICon} className="mx-1" alt="" srcset="" />
								<input
									className="custom-control-input visa-checkbox-input-yellow"
									id={`All-id`}
									type="checkbox"
									value={"All"}
									checked={
										filter.length === 0 || visaTypes.length === filter.length
									}
									onChange={(e) => setFilter([])}
								/>
								<label
									className="custom-control-label custom-control-labeler pointer visa-checkbox-label-yellow"
									htmlFor={`All-id`}
								>
									{marketPlace.AllPackages}
								</label>
							</div>

							{visaTypes?.map((i, index) => {
								const visaType = i.name;
								const checked = filter.includes(visaType);

								return (
									<div
										className="custom-control custom-checkbox filter-check-box mt-md-0 mt-2"
										key={`${visaType}-${index}`}
									>
										<img src={passportICon} className="mx-1" alt="" srcset="" />
										<input
											className="custom-control-input  visa-checkbox-input-yellow"
											id={`${visaType}-id`}
											type="checkbox"
											value={visaType}
											checked={checked}
											onChange={(e) => onChangeFilter(e, checked)}
										/>
										<label
											className="custom-control-label custom-control-labeler pointer visa-checkbox-label-yellow"
											htmlFor={`${visaType}-id`}
										>
											{visaType}
										</label>
									</div>
								);
							})}
							</div>

						</Collapse>
					</div>

					<div className="p-3 bg-white">
						{/* visa types list */}
						<div className="visa-types-container">
							{visaTypes?.map((visa, index) =>
								visa?.residences.map((residence, ind) =>
									residence?.package_plans?.map((plan, IN) => {
										const show =
											filter.length === 0 || filter.includes(visa.name);
										return show ? (
											<VisaTypeCard
												key={`visa-${visa?.id}-${index}${ind}${IN}`}
												currency={residence?.provider_currency}
												visa={visa}
												residence={residence}
												packagePlan={plan}
												index={index}
												selectVisa={selectVisa}
												destination={selectedDestination}
												toggleNote={toggleNote}
												toggleSomeNationalities={toggleSomeNationalities}
											/>
										) : null;
									})
								)
							)}
						</div>
					</div>
				</>
			) : (
				<div className="h4 w-100 text-center terms-color font-weight-bold">
					<span className="text-warning d-block h2 mt-3">
						<i class="fas fa-exclamation-triangle"></i>
					</span>
					{marketPlace.noPackagesAvailable}
				</div>
			)}

			<PackageNote
				isOpen={noteModal.isOpen}
				toggle={() => toggleNote("")}
				note={noteModal.note}
			/>
			<PackageNationalitiesModal
				isOpen={someNationalities.isOpen}
				toggle={() => toggleSomeNationalities([])}
				nationalities={someNationalities.nationality}
			/>
		</>
	);
}

export function VisaTypeCard({
	currency,
	visa,
	index,
	selectVisa,
	packagePlan,
	toggleNote,
	residence,
	toggleSomeNationalities,
}) {
	const { marketPlace, inventory } = Locale;

	return (
		<div className="visa-type-card border" key={`visa-${visa?.id}-${index}`}>
			<div className="visa-type-content">
				<h6 className="font-weight-bold orange-color">
					{packagePlan?.plan_name}
				</h6>

				{/* processing time */}
				<div className="w-100 my-1  d-flex align-items-center justify-content-center">
					<img
						src={hourGlassSrc}
						alt="hour glass icon"
						width="14px"
						height="16px"
					/>
					<span className="mx-1">{marketPlace.processingTime}</span>
					<span>{residence?.processing_time}</span>
				</div>

				{/* package plan */}
				<div className="visa-type-price">
					<div className="visa-type-pax">
						<Users />
						<span className="mx-1">{`${marketPlace.package} (${packagePlan.range_from} - ${packagePlan.range_to}) ${marketPlace.packagePax}`}</span>
					</div>
					<span className="text-white mt-2">{inventory.messages.Price}</span>
					<p className="text-white lineHeight1	">
						<span className="h5 font-weight-bold lineHeight1">
							{packagePlan?.package_provider_price}{" "}
						</span>
						<span className="lineHeight1">
							{currency} / {inventory.messages.visa}
						</span>
						{packagePlan?.notes ? (
							<i
								role="button"
								class="fas fa-info-circle orange-color ms-1"
								onClick={() => toggleNote(packagePlan?.notes)}
							></i>
						) : null}
					</p>
				</div>

				<ul className="w-100 px-2 my-2">
					<li
						className="w-100  d-flex align-items-center"
						style={{ marginBottom: "0.25em", lineHeight: 1 }}
					>
						<i class="fas fa-check fa-xs"></i>
						{residence?.all_nationalities ? (
							<span className="mx-1">{marketPlace.allNationalities}</span>
						) : residence?.nationalities.length > 1 ? (
							<p className="d-flex align-items-center align-items-center">
								<span className="mx-1">{marketPlace.someNationalities}</span>
								<i
									class="fas fa-eye orange-color"
									onClick={() =>
										toggleSomeNationalities(residence?.nationalities)
									}
									role="button"
								></i>
							</p>
						) : (
							<span className="mx-2">{`${marketPlace.nationality} (${residence?.nationalities?.[0]?.name})`}</span>
						)}
					</li>
					{packagePlan?.visa_include?.map((i) => {
						return i != "-" ? (
							<li key={i}>
								<i className="fas fa-check fa-xs "></i>
								<span className="mx-1">{i}</span>
							</li>
						) : (
							""
						);
					})}
				</ul>

				{/* visa price */}
				<div className="w-100 px-2 mt-auto">
					<button
						className="btn select-visa-btn shadow-none"
						onClick={() => {
							selectVisa({
								visaType: { id: visa?.id, name: visa?.name },
								packagePlan: packagePlan,
								residence: residence,
							});
						}}
					>
						{marketPlace.start}
					</button>
				</div>
			</div>
		</div>
	);
}

const PackageNote = ({ isOpen, toggle, note }) => {
	const { inventory } = Locale;
	return (
		<Modal isOpen={isOpen} centered>
			<ModalHeader toggle={toggle}>
				{inventory.viewReservation.Notes}
			</ModalHeader>
			<ModalBody>{note}</ModalBody>
		</Modal>
	);
};
const PackageNationalitiesModal = ({ isOpen, toggle, nationalities }) => {
	const { marketPlace } = Locale;

	return (
		<Modal isOpen={isOpen} centered size="lg">
			<ModalHeader toggle={toggle}>
				{marketPlace.availableNationalities}
			</ModalHeader>
			<ModalBody className="row m-0">
				{nationalities?.map((item, index) => {
					return (
						<p
							className="d-flex align-items-center my-2 col-4"
							key={item.country_id}
						>
							<i class="fas fa-check fa-xs"></i>
							<span className="mx-2">{item.name}</span>
						</p>
					);
				})}
			</ModalBody>
		</Modal>
	);
};
