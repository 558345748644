import React, { useEffect, useState } from "react";
import { EditIcon } from "modules/UmrahOperations/shared/Icons";
import CsRequestAddModel from "modules/CRM/Models/csRequestAdd";
import {
	useHistory,
	useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { store } from "react-notifications-component";
import RejectPackage from "modules/CRM/Models/rejectPackage";
import {
	changePackageStatus,
	editRequest,
	getProductTypeLookups,
	getServicesLookups,
} from "services/CRM";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import Locale from 'translations';

const SummeryDetails = ({ requestData, setReload }) => {
	const { id } = useParams();
	const {CRM}=Locale
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenReject, setIsOpenReject] = useState(false);
	const [reasonState, setReasonState] = useState("");

	const toggle = () => {
		setIsOpen((prev) => !prev);
	};
	const toggleReject = () => {
		setIsOpenReject((prev) => !prev);
	};
	const history = useHistory();
	const saudiFlagUUID = "d68c87b9-9a70-4032-8226-aaab6687442a";
	const [requestState, setRequestState] = useState({});
	async function changeStatus(status) {
		const data = {
			status: status.toString(),
			reason: reasonState,
		};
		const res = await changePackageStatus(id, data);

		if (res?.status === 200 || res?.status === 201) {
			store.addNotification({
				title: "info!",
				message:
					status === 2
						? "Request Accepted Successfully"
						: "Request Rejected Successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					pauseOnHover: true,
					onScreen: true,
				},
				// onRemoval: () => {
				// 	setReasonState("");
				// 	setReload(prev=> !prev);
				// 	if(status==3){
				// 		toggleReject();
				// 	}
				// },
			});
			setReasonState("");
			setReload(prev=> !prev);
			if(status==3){
				toggleReject();
			}
		}
	}
	/*********** Start Add Request ************ */
	const [lookups, setLookups] = useState({
		services: [],
		product_type: [],
		months: [
			{ name: "January", id: 1 },
			{ name: "February", id: 2 },
			{ name: "March", id: 3 },
			{ name: "April", id: 4 },
			{ name: "May", id: 5 },
			{ name: "June", id: 6 },
			{ name: "July", id: 7 },
			{ name: "August", id: 8 },
			{ name: "September", id: 9 },
			{ name: "October", id: 10 },
			{ name: "November", id: 11 },
			{ name: "December", id: 12 },
		],
	});
	const months = {
		1: "January",
		2: "February",
		3: "March",
		4: "April",
		5: "May",
		6: "June",
		7: "July",
		8: "August",
		9: "September",
		10: "October",
		11: "November",
		12: "December",
	};
	useEffect(() => {
		async function fetchLookups() {
			const [services, types] = await Promise.all([
				getServicesLookups(),
				getProductTypeLookups(),
			]);
			setLookups({
				...lookups,
				services: services?.data?.data,
				product_type: types?.data?.data,
			});
		}
		fetchLookups();
	}, []);
	useEffect(() => {
		setRequestState({
			client_name: requestData?.client_name,
			client_email: requestData?.client_email,
			phones1: {
				//	flag: saudiFlagUUID,
				label: requestData?.phones[0].code,
				value: requestData?.phones[0].code,
				num: requestData?.phones[0].num,
			},
			phones2: {
				flag: saudiFlagUUID,
				label: "+966",
				value: "+966",
				num: "",
			},
			whats_phone: {
				flag: saudiFlagUUID,
				label: "+966",
				value: "+966",
				num: "",
			},
			type: { name: requestData?.type, id: requestData?.type },
			destination: {
				name: requestData?.destination_name,
				id: requestData?.destination,
			},
			class: { name: requestData?.class, id: requestData?.class },
			service: requestData?.service?.map((item) => ({
				...item,
				label: item.name,
				value: item.id,
			})),
			month: { name: months[requestData?.duration], id: requestData?.duration },
			duration_from: requestData?.travel_date_from
				? moment(requestData?.travel_date_from)
				: undefined,
			duration_to: requestData?.travel_date_to
				? moment(requestData?.travel_date_to)
				: undefined,
			nights: requestData?.nights.toString(),
			pax: requestData?.pax,
			assigned_to: { name: "Sales Team", id: "Sales_Team" },
			cname: requestData?.cname,
			notes: requestData?.notes,
			travel_type: requestData?.duration==0?"travel_date":"travel_month",
		});
	}, [requestData]);

	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [errors, setErrors] = useState();
	const checkFormErrors = () => {
		let submitError = {};
		Object.keys(requestState).forEach((key) => {
			submitError = {
				...submitError,
				...validate(
					{
						name: key,
						value:
							key !== "phones1" ? requestState[key] : requestState[key]?.num,
					},
					{
						required:
							key === "notes" ||
							key === "whats_phone" ||
							key === "phones2" ||
							key === "travel_type" ||
							key ===
								(requestState.travel_type === "travel_date"
									? "month"
									: "duration_from") ||
							key ===
								(requestState.travel_type === "travel_date"
									? "month"
									: "duration_to")
								? false
								: true,
						email: key === "client_email",
					}
				),
			};
		});
		setErrors(() => submitError);
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	const AddEditRequest = async () => {
		let obj = {};

		if (requestState.travel_type === "travel_date") {
			obj = {
				travel_date_from: moment(requestState.duration_from).format(
					"YYYY-MM-DD"
				),
				travel_date_to: moment(requestState.duration_to).format("YYYY-MM-DD"),
				duration: "0",
			};
		} else {
			obj = { duration: requestState?.month?.value?.toString() };
		}
		let data = {
			client_name: requestState.client_name,
			client_email: requestState.client_email,
			phones: [
				{
					code:
						requestState.phones1?.value?.[0] === "+"
							? requestState.phones1?.value.slice(1)
							: requestState.phones1?.value,
					num: requestState.phones1?.num,
				},
				requestState.phones2?.num
					? {
							code:
								requestState.phones2?.value?.[0] === "+"
									? requestState.phones2?.value.slice(1)
									: requestState.phones2?.value,
							num: requestState.phones2?.num,
					  }
					: {
							code: null,
							num: null,
					  },
				requestState.whats_phone?.num
					? {
							code:
								requestState.whats_phone?.value?.[0] === "+"
									? requestState.whats_phone?.value.slice(1)
									: requestState.whats_phone?.value,
							num: requestState.whats_phone?.num,
					  }
					: {
							code: null,
							num: null,
					  },
			],
			type: requestState.type?.name,
			destination: requestState.destination?.id.toString(),
			class: requestState.class?.name.toString(),
			service: requestState.service?.map((item) => item.id),
			nights: requestState.nights,
			pax: requestState.pax,
			assigned_to: [null],
			notes: requestState.notes,
			cname: requestState.cname,

			//duration: +requestState?.month,
			...obj,
		};

		const res = await editRequest(id, data);
		if (res?.status >= 200 && res?.status < 300) {
			store.addNotification({
				title: "info!",
				message: res?.data?.message ?? "Updated Request successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 2000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			toggle();
			setReload((prev) => !prev);
		}
	};
	useEffect(() => {
		if (isFormValid(errors)) {
			AddEditRequest();
		}
	}, [isErrorLoaded]);

	/*********** End Add Request ************* */
	return (
		<div className="summery-details">
			<div className="summery-header">
				<div className="d-flex align-items-center">
					<i
						className="fas fa-chevron-left"
						role="button"
						onClick={() => history.push("/crm/cs-requests")}
					/>
					<h3 className="mx-3">{CRM.Summery}</h3>
				</div>
				{requestData?.status === 1 && (
					<button className="btn item active" onClick={() => toggle()}>
						<EditIcon color="#FFF" />
						<p className="text">{CRM.Edit}</p>
					</button>
				)}
			</div>
			<hr style={{ background: "rgba(221, 220, 220, 0.32)" }} />

			<Info requestData={requestData} />
			<InfoMore requestData={requestData} />

			<div className="cs-requests-info">
				<h6>{CRM.Notes}</h6>
				<p>{requestData?.notes}</p>
			</div>
			{requestData?.status === 3 && (
				<div className="cs-requests-info">
					<h6 className="text-danger">{CRM.RejectionReason}</h6>
					<p>{requestData?.reason}</p>
				</div>
			)}

			{requestData?.status === 1 && (
				<div className="btn-action">
					<button className="btn btn-accept" onClick={() => changeStatus(2)}>
						{CRM.Accept}
					</button>
					<button className="btn btn-reject" onClick={toggleReject}>
						{CRM.Reject}
					</button>
				</div>
			)}

			<CsRequestAddModel
				isOpen={isOpen}
				toggle={toggle}
				data={requestState}
				setData={setRequestState}
				send={checkFormErrors}
				setErrors={setErrors}
				errors={errors}
				lookups={lookups}
				title="edit"
			/>
			<RejectPackage
				isOpen={isOpenReject}
				toggle={toggleReject}
				reason={reasonState}
				setReason={setReasonState}
				rejectFn={changeStatus}
			/>
		</div>
	);
};

export default SummeryDetails;

export function Info({ requestData }) {
	const {CRM}=Locale
	return (
		<div className="cs-requests-info">
			<div className="row mx-0">
				<div className="col-md-4">
					<div className="d-flex flex-column info-item">
						<p>{CRM.ClientName}</p>
						<span>{requestData?.client_name}</span>
					</div>
				</div>
				<div className="col-md-4">
					<div className="d-flex flex-column info-item">
						<p>{CRM.ClientEmail}</p>
						<span>{requestData?.client_email}</span>
					</div>
				</div>
				<div className="col-md-4">
					<div className="d-flex flex-column info-item border-0">
						<p>{CRM.ClientPhoneNumber}</p>
						<span>{`+${requestData?.phones?.[0]?.code || ""} ${
							requestData?.phones?.[0]?.num || ""
						}`}</span>
					</div>
				</div>
				<div className="col-md-4">
					<div className="d-flex flex-column info-item border-0">
						<p>{CRM.WhatsAppNumber}</p>
						<span>
							{requestData?.phones?.length > 1
								? `+${requestData?.phones?.[1]?.code} ${requestData?.phones?.[1]?.num}`
								: "-"}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export function InfoMore({ requestData }) {
	const {CRM}=Locale
	const statusStyle = (status) => {
		switch (status) {
			case 1:
				return "new";
			case 2:
				return "accepted";
			case "pending":
				return "pending";
			case 3:
				return "rejected";

			default:
				return status;
		}
	};
	const months = {
		1: "January",
		2: "February",
		3: "March",
		4: "April",
		5: "May",
		6: "June",
		7: "July",
		8: "August",
		9: "September",
		10: "October",
		11: "November",
		12: "December",
	};
	return (
		<div className="cs-requests-info-more ">
			<div className="row mx-0">
				<div className="col-md-4">
					<div className="d-flex flex-column info-item">
						<p>{CRM.CSName}</p>
						<span>{requestData?.cname}</span>
					</div>
				</div>
				<div className="col-md-6">
					<div className="d-flex flex-column info-item">
						<p>{CRM.Assignedto}</p>
						<span>Sales Team</span>
					</div>
				</div>
				<div className={`col-md-2`}>
					<button className={`btn ${statusStyle(requestData?.status)}`}>
						{requestData?.status_label}
					</button>
				</div>
			</div>
			<div className="row mx-0">
				<div className="col-md-4">
					<div className="d-flex flex-column info-item">
						<p>{CRM.RequestType}</p>
						<span>{requestData?.type}</span>
					</div>
				</div>
				<div className="col-md-3">
					<div className="d-flex flex-column info-item">
						<p>{CRM.Destination}</p>
						<span>{requestData?.destination_name}</span>
					</div>
				</div>
				<div className="col-md-3">
					<div className="d-flex flex-column info-item">
						<p>{CRM.Services}</p>
						<span>
							{requestData?.service?.length > 0 &&
								requestData?.service.map((item) => item.name).join("-")}
						</span>
					</div>
				</div>
				<div className="col-md-2">
					<div className="d-flex flex-column info-item border-0">
						<p>{CRM.Class}</p>
						<span>{requestData?.class}</span>
					</div>
				</div>
			</div>

			<div className="row mx-0">
				<div className="col-md-4">
					<div className="d-flex flex-column info-item">
						<p>{CRM.TravelDateMonth}</p>
						<span>
							{requestData?.travel_date_from
								? `${moment(requestData?.travel_date_from).format(
										"DD/MM/YYYY"
								  )}  -  ${moment(requestData?.travel_date_to).format(
										"DD/MM/YYYY"
								  )}`
								: months[requestData?.duration]}
						</span>
					</div>
				</div>
				<div className="col-md-3">
					<div className="d-flex flex-column info-item">
						<p>{CRM.Nights}</p>
						<span>{requestData?.nights}</span>
					</div>
				</div>
				<div className="col-md-3">
					<div className="d-flex flex-column info-item border-0">
						<p>{CRM.Pax}</p>
						<span>{requestData?.pax}</span>
					</div>
				</div>
			</div>
		</div>
	);
}
