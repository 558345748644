import TextField from 'components/Form/TextField/TextField';
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import Pagination from 'components/Pagination';
import BreadCrump from '../shared/BreadCrump';
import { ReactComponent as EditIcon } from "assets/images/umrah-operations/edit-icon.svg";
import AddEditRequestModal from './AddEditRequestModal';
import { useEffect, useState } from 'react';
import { executeGuideRequest, fetchGuideRequestesList, guideRequestAttachOfficer } from 'services/operationStatement';
import useDebounce from 'hooks/useDebounce';
import moment from 'moment';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ChooseOfficialModal from '../shared/ChooseOfficialModal';
import { handleResponesNotification } from '../helper/accountingHelpers';
import { useSBSState } from 'context/global';
import { CheckMarkIcon } from '../shared/Icons';
import useOperationMovementsModals from '../OperationsCenter/useOperationMovementsModals';
import Locale from 'translations';
import ShowSubscription from 'helpers/ShowSubscription';
import ShowForPermission from 'helpers/showForPermission';

const saudiFlagUUID = "d68c87b9-9a70-4032-8226-aaab6687442a";
const initialModalData = {
  location: "",
  date: "",
  passport: "",
  phone_code: {
    flag: saudiFlagUUID,
    label: "+966",
    value: "+966",
  },
  phone: "",
  description: "",
}

export default function OperationsGuideRequests() {
  // ** hooks
  const { companyInfo } = useSBSState();
  const {
    toggleOfficerModal,
    officerModal,
  } = useOperationMovementsModals(() => { });
  // debouce hook search 
  const debouncedSearch = useDebounce((text) => getGuideRequests({ search: text }), 300);

  const isServiceCompany = companyInfo?.company_category === "services_company";
  // ** states
  const [guideRequests, setGuideRequests] = useState({
    list: [],
    meta: null
  });

  const [searchTerm, setSearchTerm] = useState(null);

  const [guideModal, setGuideModal] = useState({
    isOpen: false,
    data: initialModalData,
  });

  const [desciptionModal, setDesciptionModal] = useState({
    isOpen: false,
    description: ""
  });

  // const [officialModal, setOfficialModalIsOpen] = useState({
  //   isOpen: false,
  //   guideRequestId: null
  // });

  const [reload, setReload] = useState(false);



  // ** function
  function toggleGuideModal({ id, data, type }) {
    setGuideModal(prev => {
      return {
        isOpen: !prev.isOpen,
        data: data || initialModalData,
        id,
        type
      }
    });
  }

  // function toggleAddofficialmodal({ guideRequestId }) {
  //   setOfficialModalIsOpen(prev => {
  //     return {
  //       isOpen: !prev.isOpen,
  //       guideRequestId
  //     }
  //   });
  // }
  // toggle show full description
  function toggleDescriptionModal(description) {
    setDesciptionModal(prev => {
      return {
        isOpen: !prev.isOpen,
        description
      }
    });
  }
  // handle pagination
  function goTo(page) {
    getGuideRequests({ page })
  };

  // get guide list 
  async function getGuideRequests(params) {
    const res = await fetchGuideRequestesList(params);
    if (res?.status === 200) {
      setGuideRequests({ list: res?.data?.data, meta: res?.data?.meta });
    }
  }
  // add officer to guide request
  async function attatchOfficerToGuideRequest(officialData) {
    let data = {
      attach_type: officialData?.attach_type,
      officer_id: officialData?.attach_type === "officer" ? officialData?.officer_id : null,
      company_id: officialData?.attach_type === "company" ? officialData?.company?.id : null,
    }
    const res = await guideRequestAttachOfficer(officerModal.data.movmentId, data);
    if (res?.status === 200 || res?.status === 201) {
      let message = "Officer attached Succesfully";
      toggleOfficerModal();
      handleResponesNotification({ alertType: "success", message, title: "" });

      setReload(!reload);
    }
  }

  // execute Guide Request
  async function executeRequest(requestId) {
    const res = await executeGuideRequest(requestId);
    if (res?.status === 200) {
      handleResponesNotification({ alertType: "success", message: "Request executed succesfully", title: "" });
      setReload(!reload);
    }
  }


  // get initial guide requests without filters
  useEffect(() => {
    getGuideRequests({});
  }, [reload]);



  return (
    <ShowSubscription module="guide_request">
      <div className="guide-requests-container px-new-26 pb-3">
        <BreadCrump title={Locale.guideRequests.guideRequests} />

        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className='col-md-4 col-12 p-0'>
            <TextField
              hasLabel={false}
              type="text"
              id="agent-search"
              name="agent-search"
              placeholder={Locale.guideRequests.search}
              isImage={true}
              image={SeachIcon}
              prependImage={true}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                debouncedSearch(e.target.value)
              }}
              value={searchTerm}
            />
          </div>
          <ShowForPermission permission="Manage-Guide-Request">
            <button className="btn add-btn" onClick={() => toggleGuideModal({ id: null, data: null, type: "Add" })}>
              <i className="fas fa-plus"></i>
              {Locale.guideRequests.addNewRequest}
            </button>
          </ShowForPermission>
        </div>

        <ShowForPermission permission="View-Guide-Request">
          <div className="table-container">
            <table className="table table-update w-100 mb-0">
              <thead>
                <tr>
                  <th scope="col">{Locale.guideRequests.date}</th>
                  <th scope="col">{Locale.guideRequests.location}</th>
                  <th scope="col">{Locale.guideRequests.passport}.</th>
                  <th scope="col">{Locale.guideRequests.phone}</th>
                  <th scope="col">{Locale.guideRequests.description}</th>
                  <th scope="col">{isServiceCompany ? Locale.guideRequests.requestedby : Locale.guideRequests.requestToCompany}</th>
                  <th scope="col">{Locale.guideRequests.officialMovement}</th>
                  <ShowForPermission permission="Manage-Guide-Request">
                    <th scope="col">{Locale.guideRequests.action}</th>
                  </ShowForPermission>
                </tr>
              </thead>
              <tbody>
                {guideRequests.list.map(request => {
                  return (
                    <tr key={request?.id}>
                      <td>
                        <span>{request?.date ? moment(request?.date).format("DD-MM-YYYY") : "-"}</span>
                      </td>

                      <td>
                        <span className="text-black-900">{request?.address || "-"}</span>
                      </td>

                      <td>
                        {request?.passport_number || "-"}
                      </td>
                      <td>
                        <span className="text-black-900">{request?.phone_code} {request?.phone_number}</span>
                      </td>


                      <td width="25%">
                        <div className="d-flex flex-column align-items-start">
                          <span>
                            {request?.description?.length > 80 ? request?.description?.slice(0, 80) + "..." : request?.description}
                          </span>
                          {request?.description?.length > 80 &&
                            <button className='btn p-0 mt-1 text-primary text-capitalize text-underline'
                              onClick={() => toggleDescriptionModal(request?.description)}
                            >
                              {Locale.guideRequests.readMore}
                            </button>
                          }
                        </div>
                      </td>

                      <td>
                        {isServiceCompany ?
                          (request?.original_company_name || "-")
                          :
                          <div className="d-flex align-items-baseline mb-2">
                            {(request?.delegation_company_name || "-")}

                            {request?.delegation_company_name &&
                              <ShowForPermission permission="Manage-Guide-Request">
                                <button className="btn add-official-btn"
                                  onClick={() => toggleOfficerModal("company", request, request?.id, null)}
                                >
                                  <EditIcon width="16px" height="16px" />
                                </button>
                              </ShowForPermission>
                            }
                          </div>
                        }
                      </td>

                      <td>
                        <div className='d-flex align-items-baseline mb-2'>

                          {/* if request assigend officer */}
                          {request?.officer ?
                            <>
                              <p className='d-flex flex-column mx-1'>
                                <span>{request?.officer?.name}</span>
                                <span className='font-light  direction-initial'>
                                  {request?.officer?.phone_code}{request?.officer?.phone}
                                </span>
                              </p>

                              {(!request?.delegation_company_name || isServiceCompany) &&
                                <ShowForPermission permission="Manage-Guide-Request">
                                  <button className='btn p-0'
                                    onClick={() => toggleOfficerModal("edit", request, request?.id, null)}
                                  >
                                    <EditIcon width="16px" height="16px" />
                                  </button>
                                </ShowForPermission>
                              }

                            </>
                            : null
                          }

                          {/* if request assigend to company */}
                          {request?.delegation_company_name && !request?.officer ?
                            <div className="d-flex align-items-center">
                              <span>{Locale.guideRequests.waitingToAssignOfficer}</span>
                              {isServiceCompany &&
                                <ShowForPermission permission="Manage-Guide-Request">
                                  <button className="btn add-official-btn"
                                    onClick={() => toggleOfficerModal("edit", request, request?.id, null)}
                                  >
                                    <EditIcon width="16px" height="16px" />
                                  </button>
                                </ShowForPermission>
                              }
                            </div>
                            :
                            null
                          }
                          {/* if there is no officer and company assigend to request */}
                          {!request?.officer && !request?.delegation_company_name ?
                            <ShowForPermission permission="Manage-Guide-Request">
                              <button className="btn px-0 add-official-btn"
                                onClick={() => toggleOfficerModal("add", request?.officer, request?.id, null)}
                              >
                                {Locale.guideRequests.addOfficial}
                                <EditIcon width="16px" height="16px" className="mx-2" />
                              </button>
                            </ShowForPermission>
                            :
                            null
                          }

                        </div>
                      </td>
                      <ShowForPermission permission="Manage-Guide-Request">
                        <td>
                          <div className="table-actions">

                            {!request?.is_executed ?
                              <button className="btn unexcuted-btn"
                                onClick={() => executeRequest(request?.id)}
                              >
                                <span>{Locale.guideRequests.unExecuted}</span>
                              </button>
                              :
                              <p className="excuted-btn py-2 px-3"
                              // onClick={() => handleExecuteRawdaReservation(reservation?.id)}
                              >
                                <CheckMarkIcon color="#fff" />
                                <span>{Locale.guideRequests.executed}</span>
                              </p>
                            }

                            {/* edit request */}
                            <button className="btn shadow-none p-0"
                              onClick={() => toggleGuideModal({
                                data: {
                                  location: request?.address,
                                  date: request?.date ? moment(request?.date) : null,
                                  passport: request?.passport_number,
                                  phone_code: request?.phone_code[0] === "+" ? request?.phone_code : `+${request?.phone_code}`,
                                  phone: request?.phone_number,
                                  description: request?.description,
                                },
                                id: request?.id,
                                type: "Edit"
                              })}
                            >
                              <EditIcon />
                            </button>
                          </div>
                        </td>
                      </ShowForPermission>
                    </tr>
                  )
                })}

              </tbody>
            </table>
          </div>
        </ShowForPermission>
        {/* list Pagination */}
        <div className="mt-3">
          <Pagination
            info={guideRequests.meta}
            goTo={goTo}
          />
        </div>

        {/* description modal */}
        {desciptionModal.isOpen &&
          <Modal centered isOpen={desciptionModal.isOpen} className="operation-modal">
            <ModalHeader toggle={() => toggleDescriptionModal("")}>
              {Locale.guideRequests.description}
            </ModalHeader>
            <ModalBody>
              <p className="text-gray-500">
                {desciptionModal.description}
              </p>
            </ModalBody>
          </Modal>
        }

        {/* add edit guide request */}
        {guideModal.isOpen &&
          <AddEditRequestModal
            isOpen={guideModal.isOpen}
            guideData={guideModal}
            toggle={() => toggleGuideModal({ id: null, data: null, type: null })}
            setGuideModal={setGuideModal}
            setReload={setReload}
          />
        }

        {/* add officer */}
        {/* {officialModal.isOpen &&
        <ChooseOfficialModal
          isOpen={officialModal.isOpen}
          toggle={() => toggleAddofficialmodal({ guideRequestId: null })}
          actionFn={attatchOfficerToGuideRequest}
        />
      } */}


        {officerModal.isOpen &&
          <ChooseOfficialModal
            isOpen={officerModal.isOpen}
            toggle={toggleOfficerModal}
            actionFn={attatchOfficerToGuideRequest}
            isEdit={officerModal?.type === "edit"}
            data={officerModal?.data}
          />
        }


      </div>
    </ShowSubscription>
  )
}