import React, { useEffect, useState } from "react";
import { ReactComponent as ColorIcon } from "assets/images/webBuilder/color-filter.svg";
import { ReactComponent as FontIcon } from "assets/images/webBuilder/font.svg";
import { ReactComponent as ShapeIcon } from "assets/images/webBuilder/shapes.svg";
import { Collapse } from "reactstrap";
import SelectField from "components/Form/SelectField/SelectField";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import {
	changeBorder,
	changeColorTheme,
	changeFont,
} from "modules/WebBuilder/helpers/styles";
import { hexToRGB, rgbToHex } from 'modules/WebBuilder-V2/helpers/colorConverter';
import Locale from 'translations';

function Style() {
	const [collapseState, setCollapseState] = useState({
		color: true,
		font: true,
		shapes: true,
	});

	const dispatch = useWebBuilderDispatch();
	const { style } = useWebBuilderState();
	const [themeColor, setThemeColor] = useState("#EED9BA");
	const {webBuilder} = Locale;

	const updateStyle = ({ name, value }) => {
		dispatch({
			type: "updateStyle",
			payload: {
				name,
				value,
			},
		});
	};

	const handleCollapseToggle = (name) => {
		setCollapseState((prev) => ({
			...prev,
			[name]: !prev[name],
		}));
	};

	const changeColor = (color, isHexColor) => {
		if (isHexColor) {
			setThemeColor(color);
			color = hexToRGB(color);
		}
		changeColorTheme(color, updateStyle)
	};
	const handleBorder = (border) => changeBorder(border, updateStyle);
	const handleFont = (font) => changeFont(font, updateStyle);

	useEffect(()=>{
		setThemeColor(rgbToHex(style.color));
	}, [style.color])

	return (
		<div className="sidebar-style">
			<div className="content-header">
				<h4>{webBuilder.style}</h4>
			</div>
			<CustomCollapse
				icon={ColorIcon}
				name={webBuilder.color}
				isOpen={collapseState.color}
				toggle={() => handleCollapseToggle("color")}
			>
				<div className="style-color">
					<div className="initial-colors">
						<div className="red-color" onClick={() => changeColor("233, 107, 107", false)} />
						<div
							className="yellow-color"
							onClick={() => changeColor("248, 235, 111", false)}
						/>
						<div
							className="green-color"
							onClick={() => changeColor("127, 240, 179", false)}
						/>
						<div
							className="blue-color"
							onClick={() => changeColor("30, 133, 255", false)}
						/>
						<div
							className="purple-color"
							onClick={() => changeColor("199, 107, 242", false)}
						/>
					</div>
					<div className="color-input">
						<label htmlFor="color-input">{webBuilder.custom}:</label>
						<input
							type="color"
							id="color-input"
							value={themeColor}
							onChange={(e) => changeColor(e.target.value, true)}
						/>
					</div>
				</div>
			</CustomCollapse>
			<CustomCollapse
				icon={FontIcon}
				name={webBuilder.font}
				isOpen={collapseState.font}
				toggle={() => handleCollapseToggle("font")}
			>
				<div className="style-font">
					<SelectField
						label={`${webBuilder.chooseTypeface} :`}
						placeholder={`${webBuilder.chooseTypeface}`}
						options={[
							{ name: "cairo", id: 1 },
							{ name: "almarai", id: 2 },
							{ name: "josefin", id: 3 },
							{ name: "noto", id: 4 },
						]}
						value={style?.font}
						onChange={(e) => {
							handleFont(e.name);
						}}
					/>
				</div>
			</CustomCollapse>
			<CustomCollapse
				icon={ShapeIcon}
				name={webBuilder.shapes}
				isOpen={collapseState.shapes}
				toggle={() => handleCollapseToggle("shapes")}
			>
				<div className="style-shape">
					<fieldset>
						<label htmlFor="shape" className="shape-label text-center">
							<input
								checked={style?.rounded === "0px"}
								type="radio"
								id="shape"
								name="monster"
								value={"0px"}
								onChange={(e) => handleBorder(e.target.value)}
							/>
							<div className="shape"></div>
							{webBuilder.square}
						</label>

						<label className='text-center' htmlFor="rounded">
							<input
								checked={style?.rounded === "4px"}
								type="radio"
								id="rounded"
								name="monster"
								value={"4px"}
								onChange={(e) => handleBorder(e.target.value)}
							/>
							<div className="rounded"></div>
							{webBuilder.rounded}
						</label>
					</fieldset>
				</div>
			</CustomCollapse>
		</div>
	);
}

export default Style;

const CustomCollapse = ({ isOpen, name, toggle, icon: Icon, children }) => {
	return (
		<div>
			<div className="collapse-header">
				<div className="collapse-name">
					<Icon />
					<p>{name}</p>
				</div>
				<button className="toggle-collapse" onClick={toggle}>
					<i
						className={`fas fa-fw ${isOpen ? "fa-chevron-up " : "fa-chevron-down"
							}`}
					/>
				</button>
			</div>
			<Collapse isOpen={isOpen}>{children}</Collapse>
		</div>
	);
};
